<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { type MessageSchema } from '@/i18n'
import {
  useDishCreationEditionFormStore,
} from '@/stores/dishesStore'
import { useFetch } from '@/composables/useFetch'
import { onMounted, ref, watch, watchEffect } from 'vue'
import { useRouter } from 'vue-router'
import { useToast } from 'primevue/usetoast'
import DayMenuView from './DayMenuView.vue'
import { useEnvStore } from '@/stores/envStore'
import { useAuth } from '@/composables/useAuth'
import { usePlanningStore } from '@/stores/planningStore'
import Button from 'primevue/button'
import ButtonShare from '@/assets/icons/Buttons/ButtonShare.vue'

const props = defineProps<{
  weekRange: {
    start: Date;
    end: Date;
  };
}>()

const { t } = useI18n<{ message: MessageSchema }>({ useScope: 'global' })
const user = ref(useAuth().user)
const router = useRouter()
const toast = useToast()
const { fetchData, isFetching } = useFetch()
const planningStore = ref(usePlanningStore());

const weekDays = ref<Date[]>([]);


function getWeekDays(startDate: Date, endDate: Date): Date[] {
  const days: Date[] = []; // Explicitly define the array as a Date[] array
  let current = new Date(startDate);

  while (current <= endDate) {
    days.push(new Date(current)); // This line now knows that it's pushing a Date
    current.setDate(current.getDate() + 1);
  }

  return days;
}

weekDays.value = getWeekDays(props.weekRange.start, props.weekRange.end);

watch(() => props.weekRange, (newRange) => {
  weekDays.value = getWeekDays(newRange.start, newRange.end);
}, { deep: true });


// async function fetchPlannedMenus() {
//   const restaurantId = user.value?.restaurants[0]?.id || null
//   const startDate = props.weekRange.start.toISOString().split('T')[0]
//   const endDate = props.weekRange.end.toISOString().split('T')[0]
//   await planningStore.value.fetchMenusByDateRange(startDate, endDate, restaurantId);
// }

// function getPlannedMenusForDay(day: Date) {
//   const formattedDay = day.toISOString().split('T')[0];
//   return planningStore.value.getMenusByDate(formattedDay);
// }

// onMounted(() => {
//   fetchPlannedMenus()
// })
</script>

<template>
  <main>
    <!-- Date Range: {{ props.weekRange.start.toLocaleDateString() }} - {{ props.weekRange.end.toLocaleDateString() }} -->
     <div class="block w-full text-right pr-3" v-if="!planningStore.clipboardDishToPlan">
       <Button rounded link :pt="{ root: { class: '!p-1.5 !w-8 !h-8' } }" :pt-options="{ mergeProps: true }"
         @click="console.log('share')">
         <template #icon>
           <ButtonShare />
         </template>
       </Button>
     </div>
    <div class="flex flex-col gap-2 overflow-hidden">
      <div v-for="(day, index) in weekDays" :key="index" class="">
        <!-- Render the DayMenuView for each day -->
        <DayMenuView :date="day" />
      </div>
    </div>
  </main>
</template>

<style scoped></style>
