import SearchView from '@/views/SearchView.vue'

const searchRoutes = [
  {
    path: '/search',
    name: 'search',
    meta: {
      headerConfig: {
        headerLabel: 'search',
        hide: false,
        showBack: false,
        showProfile: true,
      },
      footerConfig: {
        hide: false,
      },
      securityConfig: {
        requireAuthentication: true,
        rolesAllowed: ['common', 'admin'],
      },
      containerMargins: true,
    },
    component: SearchView,
  },
]

export default searchRoutes
