<script setup lang="ts">
import CreationFilter from '@/components/Menus/Filters/CreationFilter.vue'
import { useI18n } from 'vue-i18n'
import { type MessageSchema } from '@/i18n'
import SelectWithIcon from '@/components/BaseComponents/SelectWithIcon.vue'
import SpringIcon from '@/assets/icons/MenuFilters/SpringIcon.vue'
import SummerIcon from '@/assets/icons/MenuFilters/SummerIcon.vue'
import AutomnIcon from '@/assets/icons/MenuFilters/AutomnIcon.vue'
import WinterIcon from '@/assets/icons/MenuFilters/WinterIcon.vue'
import { useDishesStore } from '@/stores/dishesStore'
import { ref } from 'vue'

const { t } = useI18n<{ message: MessageSchema }>({ useScope: 'global' })

const dishesStore = ref(useDishesStore());
</script>

<template>
  <CreationFilter>
    <template #title>{{ t('creationFilters.season') }}</template>
    <template #content>
      <div class="grid grid-cols-2 gap-4">
        <SelectWithIcon :selected="dishesStore.generationParams.dish_season.includes('spring')" @handle-select="
            (selected) =>
              selected
                ? dishesStore.generationParams.dish_season.push('spring')
                : (dishesStore.generationParams.dish_season = dishesStore.generationParams.dish_season.filter((i) => i !== 'spring'))
          ">
          <template #icon>
            <SpringIcon />
          </template>
          <template #label>{{ t('creationFilters.seasonList.spring') }}</template>
        </SelectWithIcon>

        <SelectWithIcon :selected="dishesStore.generationParams.dish_season.includes('summer')" @handle-select="
            (selected) =>
              selected
                ? dishesStore.generationParams.dish_season.push('summer')
                : (dishesStore.generationParams.dish_season = dishesStore.generationParams.dish_season.filter((i) => i !== 'summer'))
          ">
          <template #icon>
            <SummerIcon />
          </template>
          <template #label>{{ t('creationFilters.seasonList.summer') }}</template>
        </SelectWithIcon>

        <SelectWithIcon :selected="dishesStore.generationParams.dish_season.includes('autumn')" @handle-select="
            (selected) =>
              selected
                ? dishesStore.generationParams.dish_season.push('autumn')
                : (dishesStore.generationParams.dish_season = dishesStore.generationParams.dish_season.filter((i) => i !== 'autumn'))
          ">
          <template #icon>
            <AutomnIcon />
          </template>
          <template #label>{{ t('creationFilters.seasonList.autumn') }}</template>
        </SelectWithIcon>

        <SelectWithIcon :selected="dishesStore.generationParams.dish_season.includes('winter')" @handle-select="
            (selected) =>
              selected
                ? dishesStore.generationParams.dish_season.push('winter')
                : (dishesStore.generationParams.dish_season = dishesStore.generationParams.dish_season.filter((i) => i !== 'winter'))
          ">
          <template #icon>
            <WinterIcon />
          </template>
          <template #label>{{ t('creationFilters.seasonList.winter') }}</template>
        </SelectWithIcon>
      </div>
    </template>
  </CreationFilter>
</template>
