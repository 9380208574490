<script setup lang="ts">
import { useDishesStore } from '@/stores/dishesStore'
import { computed, onMounted, onUnmounted, ref, watch, type ComputedRef, type Ref } from 'vue'
import type { Dish } from '@/types/dish'
import { useI18n } from 'vue-i18n'
import type { MessageSchema } from '@/i18n'
import { useFetch } from '@/composables/useFetch'
import { useEnvStore } from '@/stores/envStore'
import { useToast } from 'primevue/usetoast'
import { useRoute, useRouter } from 'vue-router'
import { usePlanningStore } from '@/stores/planningStore'
import PlannedMenuLine from '@/components/Planning/PlannedMenuLine.vue'
import Button from 'primevue/button'
import ButtonShare from '@/assets/icons/Buttons/ButtonShare.vue'
import InputText from 'primevue/inputtext'
import { debounce } from '@/utils/functions'
import html2canvas from 'html2canvas-pro'
import { useAuth } from '@/composables/useAuth'
import Dialog from 'primevue/dialog'
import { openSync } from 'fs'
import ShareCard from '@/assets/icons/Planning/ShareCard.vue'
import DishImage from '@/components/Menus/DishImage.vue'
import type { MenuCourse } from '@/types/planning'
import Menu from 'primevue/menu'
import ButtonMenu from '@/assets/icons/Buttons/ButtonMenu.vue'
import { useConfirm } from 'primevue/useconfirm'
import ConfirmDialog from 'primevue/confirmdialog'

const { t, locale } = useI18n<{ message: MessageSchema }>({ useScope: 'global' })

const route = useRoute()
const router = useRouter()
const toast = useToast()
const envStore = useEnvStore()
const dishesStore = useDishesStore()
const dish = ref<Dish | null>(dishesStore.selectedDish)
dish.value = dishesStore.selectedDish
const isError = ref(false)
const { fetchData } = useFetch()
const confirmDialog = useConfirm();

const planningStore = usePlanningStore()
const { user } = useAuth()
const shareModalVisible = ref(false);
const isImageModalVisible = ref(false);
const dishImageRef: Ref<InstanceType<typeof DishImage> | null> = ref(null)
const popupMenu = ref()

const popUpMenuItems = ref([
  {
    items: [
      {
        label: t('planning.plannedMenu.delete.buttonLabel'),
        icon: 'pi pi-trash',
        command: () =>
          confirmDeletePlannedMenu(),
      },
      // {
      //   label: t('menuDishView.topMenu.duplicate'),
      //   icon: 'pi pi-clone',
      //   command: () =>
      //   console.log("Duplicate"),
      //     // duplicateDish(props.dish.id).then((duplicationResponse) =>
      //     //   emit('handleDuplication', duplicationResponse)
      //     // ),
      // },
      // {
      //   label: t('menuDishView.topMenu.share'),
      //   icon: 'pi pi-share-alt',
      // },
    ],
  },
])



const plannedMenuTitle = ref(planningStore.selectedPlannedMenu?.name || '')

watch(
  () => planningStore.selectedPlannedMenu?.name,
  (newName) => {
    plannedMenuTitle.value = newName || ''
  }
)
const temporaryMenuCourses = ref<MenuCourse[]>([]);

// When clipboardDishToPlan is not null, prepare to add the dish to the temporary list
watch(() => planningStore.clipboardDishToPlan, (newDish) => {
  if (newDish) {
    // Add the dish to the first available course (e.g., entree if it's empty)
    const emptyCourse = temporaryMenuCourses.value.find(course => !course.dish);
    if (emptyCourse) {
      emptyCourse.dish = newDish; // Assign the dish to the empty course
    } else {
      // Or push it into a new course
      temporaryMenuCourses.value.push({
        id: -1, // Temporary ID
        name: newDish.name,
        order: 2, // Example: main dish
        dish: newDish,
        planned_menu_id: planningStore.selectedPlannedMenu?.id || -1
      });
    }
  }
});

function saveChanges() {
  // Loop over the temporary courses and save each one
  temporaryMenuCourses.value.forEach(course => {
    if (course.dish) {
      planningStore.addOrUpdateMenuCourse(course, router);
    }
  });
  // Clear the clipboard after saving
  planningStore.clipboardDishToPlan = null;
  toast.add({ severity: 'success', summary: 'Succès', detail: 'Les plats ont été ajoutés au menu' });
}

function resetChanges() {
  temporaryMenuCourses.value = [...(planningStore.selectedPlannedMenu?.menu_courses || [])];
}

const updatePlannedMenuTitle = async () => {
  if (planningStore.selectedPlannedMenu) {
    planningStore.selectedPlannedMenu.name = plannedMenuTitle.value
    await planningStore.updatePlannedMenu(planningStore.selectedPlannedMenu)
  }
}

// Create a debounced version of the updatePlannedMenuTitle function
const debouncedUpdatePlannedMenuTitle = debounce(updatePlannedMenuTitle, 500)


// mounted
onMounted(async () => {
  // console.log("mounted PlannedMenuView");
  if (!planningStore.selectedPlannedMenu || (planningStore.selectedPlannedMenu.id.toString() !== route.params.id && !planningStore.clipboardDishToPlan)) {
    // console.log("no selectedPlannedMenu, get the plannedMenu from route");
    planningStore.selectPlannedMenu(parseInt(route.params.id.toString()))
    //   const { data, error } = await fetchData(`${useEnvStore().apiUrl}/dishes/${dishId.value}`)
    //   if (error) {
    //     console.error(error)
    //   } else {
    //     dish.value = data as Dish
    //     dishesStore.selectedDish = dish.value
    //     console.log(data)
    //     numberOfPeople.value = dish.value.servings
    //   }
    // } else {
    //   dish.value = dishesStore.selectedDish
    //   numberOfPeople.value = dish.value.servings
    // }
  }
})

onUnmounted(() => {
  // console.log("unMount Planned Menu, set selectedPlannedMenu to null")
  planningStore.selectedPlannedMenu = null;
})

const formattedDate = computed(() => {
  if (!planningStore.selectedPlannedMenu?.date) return '';

  const date = new Date(planningStore.selectedPlannedMenu.date);
  const options: Intl.DateTimeFormatOptions = {
    weekday: 'long', // 'long' format for the day
    month: '2-digit', // two-digit month
    day: '2-digit'  // two-digit day
  };

  // Formatting the date according to locale
  let dateString = date.toLocaleDateString(locale.value, options);

  // Capitalizing the first letter of the weekday and adjusting format
  dateString = dateString.charAt(0).toUpperCase() + dateString.slice(1);
  dateString = dateString.replace(',', ''); // Remove any commas

  // Remove the period if it exists at the end
  if (dateString.endsWith('.')) {
    dateString = dateString.slice(0, -1);
  }

  return dateString;
});

// const shareMenu = async () => {
//   try {
//     const { data, error } = await fetchData(
//       `${useEnvStore().apiUrl}/planning/planned-menus/${route.params.id}/share`);

//     if (error) throw error;

//     console.log(data);
//     const blob = await data.blob();
//     const imageUrl = URL.createObjectURL(blob);

//     // Optionally display the image in a modal or download it
//     const link = document.createElement('a');
//     link.href = imageUrl;
//     link.download = `planned_menu_${route.params.id}.jpg`;
//     link.click();
//   } catch (error) {
//     console.error('Error sharing planned menu:', error);
//     toast.add({ severity: 'error', summary: 'Error', detail: 'Failed to share menu' });
//   }
// };

const sortedAndFilledCourses: ComputedRef<(MenuCourse | null)[]> = computed(() => {
  const courses = planningStore.selectedPlannedMenu?.menu_courses || [];
  const sortedCourses = [...courses].sort((a, b) => a.order - b.order);

  // Ensure all three course types are present
  const courseTypes = [1, 2, 3]; // Corresponds to Entrée, Plat principal, Dessert
  return courseTypes.map(type => {
    const found = sortedCourses.find(course => course.order === type);
    return found || null;
  });
});

function openShareModal() {
  shareModalVisible.value = true;
}

const mainDish = computed(() => {
  const dish = planningStore.selectedPlannedMenu?.menu_courses.find(course => course.order === 2)?.dish;
  if (!dish) return null;

  // Check if any image URLs are available
  const imageUrl = dish.image_url_gastro || dish.image_url_bistro || dish.image_url_user;
  return {
    ...dish,
    imageUrl: imageUrl || ''
  };
});

const shareMenu = () => {
  const element = document.getElementById('share-item');
  if (!element) {
    console.error('Element not found');
    return;
  }

  html2canvas(element, {
    scale: 1,  // Adjusts the resolution of the captured image
    logging: true, // Enables logging for debugging purposes
    useCORS: true // Helps manage external images if they're not being rendered
  }).then((canvas) => {
    const link = document.createElement('a');
    link.download = 'menu_image.png';
    link.href = canvas.toDataURL('image/png');
    link.click();
  }).catch(error => {
    console.error('Error capturing the canvas:', error);
  });
};

function addOrUpdateMenuCourse(menuCourse: MenuCourse) {
  if (!planningStore.selectedPlannedMenu) return;
  console.log(`Add menuCourse : `, menuCourse);
  menuCourse.planned_menu_id = planningStore.selectedPlannedMenu.id;
  planningStore.addOrUpdateMenuCourse(menuCourse, router);
}

function getOrderedIndex(index: number): 1 | 2 | 3 {
  return ((index - 1) % 3) + 1 as 1 | 2 | 3;
}

function togglePopUpMenu(event: MouseEvent) {
  popupMenu.value.toggle(event)
}

const confirmDeletePlannedMenu = () => {
  confirmDialog.require({
    message: t('planning.plannedMenu.delete.message'),
    header: t('planning.plannedMenu.delete.title'),
    icon: 'pi pi-exclamation-triangle',
    rejectClass: 'p-button-secondary p-button-outlined',
    rejectLabel: t('planning.plannedMenu.delete.cancel'),
    acceptLabel: t('planning.plannedMenu.delete.confirm'),
    accept: () => {
      if (planningStore.selectedPlannedMenu)
        planningStore.deletePlannedMenu(planningStore.selectedPlannedMenu.id, router);
    },
    reject: () => { }
  });
};

</script>

<template>
  <main>
    <Dialog v-if="mainDish" v-model:visible="isImageModalVisible" modal style="max-width: 90%" class="sm:max-w-3xl">
      <DishImage v-if="mainDish" :dishId="mainDish?.id" :type="mainDish?.selected_image" :alt="mainDish?.name"
        ref="dishImageRef" size="full" />
    </Dialog>
    <Menu ref="popupMenu" :model="popUpMenuItems" :popup="true" :pt="{ submenuHeader: { class: '!p-0' } }"
      :pt-options="{ mergeProps: true }" />
    <div class="flex flex-col gap-6">
      <div class="block w-full text-right pr-3" v-if="!planningStore.clipboardDishToPlan">
        <Button rounded link :pt="{ root: { class: '!p-1.5 !w-8 !h-8' } }" :pt-options="{ mergeProps: true }"
          @click="openShareModal">
          <template #icon>
            <ButtonShare />
          </template>
        </Button>
        <Button link rounded :pt="{ root: { class: '!p-1.5 !w-8 !h-8 rotate-90' } }" :pt-options="{ mergeProps: true }"
          @click="togglePopUpMenu">
          <template #icon>
            <ButtonMenu />
          </template>
        </Button>
      </div>
      <!-- <div>
      Header with info
    </div> -->
      <div v-if="mainDish && mainDish.imageUrl" class="flex justify-center my-5">
        <DishImage v-if="mainDish" :dishId="mainDish?.id" :type="mainDish?.selected_image" :alt="mainDish?.name"
          ref="dishImageRef" @click="isImageModalVisible = true" class="cursor-pointer" />
      </div>
      <!-- <Button link rounded :pt="{ root: { class: '!p-1.5 !w-8 !h-8 rotate-90' } }" :pt-options="{ mergeProps: true }"
        @click="togglePopUpMenu">
        <template #icon>
          <ButtonMenu />
        </template>
      </Button>
      <Menu ref="popupMenu" id="overlay_menu" :model="items" :popup="true" :pt="{ submenuHeader: { class: '!p-0' } }"
        :pt-options="{ mergeProps: true }" /> -->
      <div>
        <p class="text-center text-lg">{{ formattedDate }}</p>
      </div>

      <!-- <p class="text-center text-lg">{{ planningStore.selectedPlannedMenu?.name }}</p> -->
      <div class="flex flex-col gap-4">
        <div class="grid grid-cols-12 gap-2 items-center">
          <div class="col-span-2 min-w-4">
            <p>
              Title
            </p>
          </div>
          <div class="col-span-9 min-w-52 flex justify-center">

            <InputText id="plannedMenuTitle" v-model="plannedMenuTitle" @input="debouncedUpdatePlannedMenuTitle"
              class="w-full" />
          </div>
        </div>
      </div>
      <div v-for="(menuCourse, order) in sortedAndFilledCourses" :key="menuCourse?.id">
        <PlannedMenuLine :order="getOrderedIndex(order + 1)" :menu-course="menuCourse"
          @add-or-update-menu-course="addOrUpdateMenuCourse" @delete-menu-course="planningStore.deleteMenuCourse" />
        <!-- <div v-else class="text-center flex justify-center p-6" >
          <button @click="console.log('add course')"
            class="p-2 bg-accent text-white rounded-full aspect-square w-10 flex justify-center items-center">
            
            <i class="pi pi-plus" style="font-size: 1rem"></i>
          </button>
        </div> -->
      </div>

    </div>

    <Dialog v-if="sortedAndFilledCourses" v-model:visible="shareModalVisible" modal style="max-width: 90%"
      class="sm:max-w-3xl">
      <ShareCard>
        <div class="bg-white">
          <div class="bg-[#F09F26] pl-12 pr-6" :class="mainDish && mainDish.imageUrl ? 'py-4' : 'py-4'">
            <h1 class="text-white font-bold">{{ plannedMenuTitle }}</h1>
            <p class="text-sm text-white">{{ formattedDate }}</p>
          </div>
          <div class="mx-20 mt-4 mb-4">
            <div v-for="(menuCourse, index) in sortedAndFilledCourses" :key="menuCourse?.id"
              :class="mainDish && mainDish.imageUrl ? 'py-2 text-xs' : 'py-4'" class="px-4">
              <div class="mb-2">
                <div class="text-center font-bold">{{ menuCourse?.dish?.name ?? menuCourse?.name ?? "***" }}</div>
                <div v-if="index < sortedAndFilledCourses.length - 1" class="h-0.5 w-6 bg-gray-300 my-2 mx-auto"></div>
              </div>
            </div>
            <div v-if="mainDish && mainDish.imageUrl" class="flex justify-center">
              <DishImage v-if="mainDish" :dishId="mainDish?.id" :type="mainDish?.selected_image" :alt="mainDish?.name"
                ref="dishImageRef" @click="isImageModalVisible = true" class="cursor-pointer" />
            </div>
            <div class="p-4 text-center text-sm font-semibold">
              {{ user?.restaurants[0]?.name }}
              <!-- <div>+41 123 45 67</div>
          <div>restaurant-le-soleil.ch</div> -->
            </div>
          </div>
        </div>
      </ShareCard>
    </Dialog>
  </main>
</template>
