<script setup lang="ts">
import { useRouter } from 'vue-router';
const router = useRouter()


const props = defineProps<{
  icon: any
  text: string
  navigate: string
}>()

function redirectUser () {
  const [path, queryString] = props.navigate.split('?');
  const queryParams = new URLSearchParams(queryString);
  const type = queryParams.get('type');
  router.push({
    path: path,
    query: type ? { type: type } : undefined // Only add query parameters if 'type' exists
  });
  // console.log('Navigate to:', props.navigate); // Add this line to check the value
  // router.push({ path: props.navigate });
}
</script>

<template>
  <div class="flex flex-col gap-3 items-center text-center align-bottom w-24 justify-between hover:cursor-pointer" @click="redirectUser()">
    <component :is="icon" />
    <h3 class="text-xs">{{ text }}</h3>
  </div>
</template>
