<template>
  <div class="icon-container">
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.4 0C15.312 0 13.308 1.0594 12 2.73352C10.692 1.0594 8.688 0 6.6 0C2.904 0 0 3.16512 0 7.19346C0 12.1373 4.08 16.1657 10.26 22.2866L12 24L13.74 22.2736C19.92 16.1657 24 12.1373 24 7.19346C24 3.16512 21.096 0 17.4 0ZM12.12 20.3379L12 20.4687L11.88 20.3379C6.168 14.7008 2.4 10.9733 2.4 7.19346C2.4 4.57766 4.2 2.6158 6.6 2.6158C8.448 2.6158 10.248 3.91063 10.884 5.70245H13.128C13.752 3.91063 15.552 2.6158 17.4 2.6158C19.8 2.6158 21.6 4.57766 21.6 7.19346C21.6 10.9733 17.832 14.7008 12.12 20.3379Z"
        fill="#173447" />
    </svg>

  </div>
</template>
<style scoped>
.icon-container {
  width: 45px;
  height: 45px;
  display: inline-flex;
  padding: 10px;
  align-items: center;
  gap: 10px;
  border-radius: 44px;
  border: 2px solid var(--couleurs-bleus-noir-bleu-prussian-blue, #173447);
  box-shadow: 1px 1px 0px 0px #ED8D4C;
}
</style>