<script setup lang="ts">
import CreationFilter from '@/components/Menus/Filters/CreationFilter.vue'
import { useI18n } from 'vue-i18n'
import { type MessageSchema } from '@/i18n'
import SelectWithoutIcon from '@/components/BaseComponents/SelectWithoutIcon.vue'
import { ref } from 'vue'
import { useDishesStore } from '@/stores/dishesStore'

const { t } = useI18n<{ message: MessageSchema }>({ useScope: 'global' })

const dishesStore = ref(useDishesStore());

const dietTypeList = ref([
  'vegetarian',
  'vegan',
  'withoutLactose',
  'withoutGluten',
  'pescetarian',
  'fitness',
])

</script>

<template>
  <CreationFilter>
    <template #title>{{ t('creationFilters.dietType') }}</template>
    <template #content>
      <div class="flex flex-wrap justify-center w-full gap-2">
        <SelectWithoutIcon v-for="dietType in dietTypeList" :key="dietType"
          :selected="dishesStore.generationParams.dish_diet.includes(dietType)" @handle-select="(selected) =>
              selected
                ? dishesStore.generationParams.dish_diet.push(dietType)
                : (dishesStore.generationParams.dish_diet = dishesStore.generationParams.dish_diet.filter((i) => i !== dietType))
            ">
          <template #label>{{ t(`creationFilters.dietTypeList.${dietType}`) }}</template>
        </SelectWithoutIcon>
      </div>
    </template>
  </CreationFilter>
</template>
