<template>
  <svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M22.4934 11.0645C22.5932 9.88766 22.2319 8.72203 21.4897 7.80462C20.7475 6.88722 19.6736 6.30305 18.4996 6.15914C18.3915 5.45653 18.1525 4.77277 17.7967 4.15649C17.4225 3.50834 16.9269 2.94442 16.3329 2.47986C15.7388 2.01531 15.0624 1.69036 14.3417 1.49726C13.621 1.30415 12.8622 1.2535 12.1261 1.35265C11.3815 1.44956 10.6704 1.69014 10.02 2.07273C9.40372 2.42854 8.85492 2.90123 8.40997 3.45563C7.32132 2.99328 6.10753 2.96444 4.99771 3.38561C3.88789 3.80677 3.00055 4.63783 2.49854 5.70687C1.99653 6.77591 1.92968 7.99749 2.31284 9.11509C2.69599 10.2327 3.49069 11.1552 4.54012 11.6969L3.87098 14.1941L17.0529 17.7262L17.722 15.2289C18.9017 15.2845 20.0595 14.8852 20.9418 14.1067C21.8324 13.3304 22.3853 12.2391 22.485 11.0622L22.4934 11.0645Z"
      fill="currentColor"
    />
    <path
      d="M16.5515 19.6307L3.36963 16.0986L2.71006 18.5602C2.56712 19.0936 2.8837 19.642 3.41717 19.7849L14.6672 22.7993C15.2007 22.9423 15.749 22.6257 15.8919 22.0922L16.5515 19.6307Z"
      fill="currentColor"
    />
  </svg>
</template>
