<template>
  <div class="icon-container">
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.8582 0C6.70491 0 1.71538 5.37333 1.71538 12H0L2.81729 17.1867L2.90396 17.3733L6.00049 12H4.19158C4.19158 6.84 8.06681 2.66667 12.8582 2.66667C17.6497 2.66667 21.5249 6.84 21.5249 12C21.5249 17.16 17.6497 21.3333 12.8582 21.3333C10.4687 21.3333 8.30205 20.28 6.74205 18.5867L4.98396 20.48C7.00205 22.6533 9.77538 24 12.8582 24C19.0116 24 24.0011 18.6267 24.0011 12C24.0011 5.37333 19.0116 0 12.8582 0ZM11.6201 6.66667V13.3333L16.8821 16.6933L17.8354 14.9867L13.4773 12.2V6.66667H11.6201Z"
        fill="#173447" />
    </svg>

  </div>
</template>
<style scoped>
.icon-container {
  width: 45px;
  height: 45px;
  display: inline-flex;
  padding: 10px;
  align-items: center;
  gap: 10px;
  border-radius: 44px;
  border: 2px solid var(--couleurs-bleus-noir-bleu-prussian-blue, #173447);
  box-shadow: 1px 1px 0px 0px #ED8D4C;
}
</style>