<template>
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 7.33333H14.9528V10H1V7.33333ZM1 4.66667H14.9528V2H1V4.66667ZM1 15.3333H9.87908V12.6667H1V15.3333ZM20.0393 11.16L20.9399 10.2133C21.4346 9.69333 22.2337 9.69333 22.7284 10.2133L23.629 11.16C24.1237 11.68 24.1237 12.52 23.629 13.04L22.7284 13.9867L20.0393 11.16ZM19.1387 12.1067L12.416 19.1733V22H15.1051L21.8278 14.9333L19.1387 12.1067Z"
      fill="currentColor"
    />
  </svg>
</template>
