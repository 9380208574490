<script setup lang="ts">
import CreationFilter from '@/components/Menus/Filters/CreationFilter.vue'
import { useI18n } from 'vue-i18n'
import { type MessageSchema } from '@/i18n'
import Textarea from 'primevue/textarea'
import { ref } from 'vue'
import { useDishesStore } from '@/stores/dishesStore'

const { t } = useI18n<{ message: MessageSchema }>({ useScope: 'global' })

const dishesStore = ref(useDishesStore());

</script>

<template>
  <CreationFilter>
    <template #title>{{ t('creationFilters.menuTitle') }}</template>
    <template #content>
      <Textarea v-model="dishesStore.generationParams.custom_title" class="w-full" rows="2"
        :placeholder="t('creationFilters.menuTitlePlaceholder')" />
    </template>
  </CreationFilter>
</template>
