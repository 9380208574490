<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { type MessageSchema } from '@/i18n'
import Button from 'primevue/button'
import { ref } from 'vue'
import InlineMessage from '@/components/InlineMessage.vue'
import { useAuth } from '@/composables/useAuth.js'
import Password from 'primevue/password'
import InputText from 'primevue/inputtext'
import Divider from 'primevue/divider'
import InlineLink from '@/components/InlineLink.vue'

defineProps<{ token: string }>()

const email = ref('')
const password = ref('')
const passwordConfirmation = ref('')

const { resetPassword, errors, isFetching } = useAuth()
errors.value = {}

const { t } = useI18n<{ message: MessageSchema }>({ useScope: 'global' })

function hasError(fieldName: string) {
  return !!errors.value[fieldName] && errors.value[fieldName].length > 0
}
</script>

<template>
  <main>
    <form @submit.prevent="resetPassword(email, password, passwordConfirmation, token)">
      <div class="flex flex-col gap-4">
        <div class="flex flex-col gap-2">
          <label for="email">{{ t('auth.user.form.label.mail') }}</label>
          <div>
            <InputText
              type="email"
              id="email"
              class="w-full"
              v-model="email"
              :placeholder="t('auth.user.form.placeholder.mail')"
              :invalid="hasError('email')"
            />
          </div>
          <InlineMessage severity="error" v-if="hasError('email')">
            {{ errors.email[0] }}
          </InlineMessage>
        </div>
        <div class="flex flex-col gap-2">
          <label for="password">{{ t('auth.user.form.label.password') }}</label>
          <div>
            <Password
              id="password"
              v-model="password"
              :promptLabel="t('auth.user.form.placeholder.password')"
              :weakLabel="t('auth.user.form.label.password_weak')"
              :mediumLabel="t('auth.user.form.label.password_medium')"
              :strongLabel="t('auth.user.form.label.password_strong')"
              :invalid="hasError('password')"
              :placeholder="t('auth.user.form.placeholder.password')"
              toggleMask
              class="w-full"
            />
          </div>
          <InlineMessage severity="error" v-if="hasError('password')">
            {{ errors.password[0] }}
          </InlineMessage>
        </div>

        <div class="flex flex-col gap-2">
          <label for="passwordConfirmation">
            {{ t('auth.user.form.label.password_confirmation') }}
          </label>
          <div>
            <Password
              id="passwordConfirmation"
              v-model="passwordConfirmation"
              :promptLabel="t('auth.user.form.placeholder.password')"
              :weakLabel="t('auth.user.form.label.password_weak')"
              :mediumLabel="t('auth.user.form.label.password_medium')"
              :strongLabel="t('auth.user.form.label.password_strong')"
              :invalid="hasError('password')"
              :placeholder="t('auth.user.form.placeholder.password_confirmation')"
              toggleMask
              class="w-full"
            />
          </div>
          <InlineMessage severity="error" v-if="hasError('password')">
            {{ errors.password[0] }}
          </InlineMessage>
        </div>

        <Button
          :loading="isFetching"
          :disabled="isFetching"
          :label="t('auth.forgotPassword.reset')"
          class="w-full mt-8"
          size="large"
          type="submit"
        />
      </div>
    </form>
    <Divider />
    <p>Retour au <InlineLink destination="/login">Login</InlineLink></p>
  </main>
</template>
