<template>
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.999023 23L16.399 7.6M16.399 7.6H18.599C19.766 7.6 20.8851 7.13643 21.7103 6.31127C22.5355 5.48611 22.999 4.36695 22.999 3.2V1H20.799C19.6321 1 18.5129 1.46357 17.6878 2.28873C16.8626 3.11389 16.399 4.23305 16.399 5.4V7.6ZM2.61602 12.583L4.29902 10.9L5.98202 12.583C6.70076 13.3046 7.10431 14.2815 7.10431 15.3C7.10431 16.3185 6.70076 17.2954 5.98202 18.017L4.29902 19.7L2.61602 18.017C1.89728 17.2954 1.49374 16.3185 1.49374 15.3C1.49374 14.2815 1.89728 13.3046 2.61602 12.583ZM7.01602 8.183L8.69902 6.5L10.382 8.183C11.1008 8.90457 11.5043 9.88154 11.5043 10.9C11.5043 11.9185 11.1008 12.8954 10.382 13.617L8.69902 15.3L7.01602 13.617C6.29728 12.8954 5.89374 11.9185 5.89374 10.9C5.89374 9.88154 6.29728 8.90457 7.01602 8.183ZM11.416 3.783L13.099 2.1L14.782 3.783C15.5008 4.50458 15.9043 5.48154 15.9043 6.5C15.9043 7.51846 15.5008 8.49543 14.782 9.217L13.099 10.9L11.416 9.217C10.6973 8.49543 10.2937 7.51846 10.2937 6.5C10.2937 5.48154 10.6973 4.50458 11.416 3.783Z"
      stroke="currentColor"
      stroke-width="1.40758"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M11.4163 18.0164L13.0993 19.6994L11.4163 21.3824C10.6947 22.1012 9.71778 22.5047 8.69932 22.5047C7.68086 22.5047 6.70389 22.1012 5.98232 21.3824L4.29932 19.6994L5.98232 18.0164C6.70389 17.2977 7.68086 16.8941 8.69932 16.8941C9.71778 16.8941 10.6947 17.2977 11.4163 18.0164ZM15.8163 13.6164L17.4993 15.2994L15.8163 16.9824C15.0947 17.7012 14.1178 18.1047 13.0993 18.1047C12.0809 18.1047 11.1039 17.7012 10.3823 16.9824L8.69932 15.2994L10.3823 13.6164C11.1039 12.8977 12.0809 12.4941 13.0993 12.4941C14.1178 12.4941 15.0947 12.8977 15.8163 13.6164ZM20.2163 9.21643L21.8993 10.8994L20.2163 12.5824C19.4947 13.3012 18.5178 13.7047 17.4993 13.7047C16.4809 13.7047 15.5039 13.3012 14.7823 12.5824L13.0993 10.8994L14.7823 9.21643C15.5039 8.49769 16.4809 8.09414 17.4993 8.09414C18.5178 8.09414 19.4947 8.49769 20.2163 9.21643Z"
      stroke="currentColor"
      stroke-width="1.40758"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
