<script setup lang="ts">
import CreationFilter from '@/components/Menus/Filters/CreationFilter.vue'
import { useI18n } from 'vue-i18n'
import { type MessageSchema } from '@/i18n'
import SelectWithIcon from '@/components/BaseComponents/SelectWithIcon.vue'
import HotIcon from '@/assets/icons/MenuFilters/HotIcon.vue'
import ColdIcon from '@/assets/icons/MenuFilters/ColdIcon.vue'
import { ref, watchEffect, type Ref } from 'vue'
import { useDishesStore } from '@/stores/dishesStore'

const { t } = useI18n<{ message: MessageSchema }>({ useScope: 'global' })
const dishesStore = ref(useDishesStore());

</script>

<template>
  <CreationFilter>
    <template #title>{{ t('creationFilters.hotCold.label') }}</template>
    <template #content>
      <div class="grid grid-cols-2 gap-4">
        <SelectWithIcon
          :selected="dishesStore.generationParams.hot_cold.includes('hot')"
          @handle-select="
            (selected) =>
              selected
                ? dishesStore.generationParams.hot_cold.push('hot')
                : (dishesStore.generationParams.hot_cold = dishesStore.generationParams.hot_cold.filter((i) => i !== 'hot'))
          "
        >
          <template #icon> <HotIcon /> </template>
          <template #label>{{ t('creationFilters.hotCold.hot') }}</template>
        </SelectWithIcon>
        <SelectWithIcon
          :selected="dishesStore.generationParams.hot_cold.includes('cold')"
          @handle-select="
            (selected) =>
              selected
                ? dishesStore.generationParams.hot_cold.push('cold')
                : (dishesStore.generationParams.hot_cold = dishesStore.generationParams.hot_cold.filter((i) => i !== 'cold'))
          "
        >
          <template #icon> <ColdIcon /> </template>
          <template #label>{{ t('creationFilters.hotCold.cold') }}</template>
        </SelectWithIcon>
      </div>
    </template>
  </CreationFilter>
</template>
