<template>
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.4535 13.2C18.3532 13.2 19.1449 12.708 19.5527 11.964L23.8471 4.176C24.291 3.384 23.7152 2.4 22.8035 2.4H5.05012L3.92254 0H0V2.4H2.39911L6.71751 11.508L5.09811 14.436C4.22243 16.044 5.374 18 7.19733 18H21.592V15.6H7.19733L8.51684 13.2H17.4535ZM6.1897 4.8H20.7643L17.4535 10.8H9.03265L6.1897 4.8ZM7.19733 19.2C5.87782 19.2 4.81021 20.28 4.81021 21.6C4.81021 22.92 5.87782 24 7.19733 24C8.51684 24 9.59644 22.92 9.59644 21.6C9.59644 20.28 8.51684 19.2 7.19733 19.2ZM19.1929 19.2C17.8734 19.2 16.8058 20.28 16.8058 21.6C16.8058 22.92 17.8734 24 19.1929 24C20.5124 24 21.592 22.92 21.592 21.6C21.592 20.28 20.5124 19.2 19.1929 19.2Z"
      fill="currentColor"
    />
  </svg>
</template>
