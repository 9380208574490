<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { type MessageSchema } from '@/i18n'
import Button from 'primevue/button'
import type { UserRegisterRequest } from '@/types/api'
import InputText from 'primevue/inputtext'
import Password from 'primevue/password'
import Stepper from 'primevue/stepper'
import { onMounted, ref, type Ref } from 'vue'
import InlineLink from '@/components/InlineLink.vue'
import InlineMessage from '@/components/InlineMessage.vue'
import Divider from 'primevue/divider'
import { useAuth } from '@/composables/useAuth.js'
import StepperPanel from 'primevue/stepperpanel'
import { useFetch } from '@/composables/useFetch.js'
import { useEnvStore } from '@/stores/envStore.js'
import type { Attribute } from '@/types/restaurants.js'
import RadioButton from 'primevue/radiobutton'

const { register, errors, isFetching } = useAuth()
const { fetchData } = useFetch()

errors.value = {}

const password = ref('')
const passwordConfirmation = ref('')

const { t } = useI18n<{ message: MessageSchema }>({ useScope: 'global' })

const restaurationTypes: Ref<Attribute[]> = ref([])

const userForm: Ref<UserRegisterRequest> = ref({
  email: '',
  password: '',
  password_confirmation: '',
  first_name: '',
  last_name: '',
  restaurant_name: '',
  restauration_type_id: 0,
})

function hasError(fieldName: string) {
  return !!errors.value[fieldName] && errors.value[fieldName].length > 0
}

onMounted(async () => {
  const { data } = await fetchData(useEnvStore().apiUrl + '/attributes/restauration-types')
  if (data) {
    restaurationTypes.value = data as Attribute[]
    userForm.value.restauration_type_id = restaurationTypes.value[0].id
  }
})
</script>

<template>
  <main>
    <!-- <form @submit.prevent="register(userForm, password, passwordConfirmation)"> -->
    <Stepper orientation="vertical">
      <StepperPanel header="Information personnelles">
        <template #content="{ nextCallback }">
          <div class="flex flex-col gap-4">
            <div class="flex flex-col gap-2">
              <label for="email">{{ t('auth.user.form.label.mail') }}</label>
              <div>
                <InputText
                  type="email"
                  id="email"
                  class="w-full"
                  v-model="userForm.email"
                  :placeholder="t('auth.user.form.placeholder.mail')"
                  :invalid="hasError('email')"
                />
              </div>
              <InlineMessage severity="error" v-if="hasError('email')">
                {{ errors.email[0] }}
              </InlineMessage>
            </div>
            <div class="flex flex-col gap-2">
              <label for="firstName">{{ t('auth.user.form.label.first_name') }}</label>
              <div>
                <InputText
                  type="text"
                  id="firstName"
                  v-model="userForm.first_name"
                  class="w-full"
                  :placeholder="t('auth.user.form.placeholder.first_name')"
                  :invalid="hasError('first_name')"
                />
              </div>
              <InlineMessage severity="error" v-if="hasError('first_name')">
                {{ errors.first_name[0] }}
              </InlineMessage>
            </div>
            <div class="flex flex-col gap-2">
              <label for="lastName">{{ t('auth.user.form.label.last_name') }}</label>
              <div>
                <InputText
                  type="text"
                  id="lastName"
                  v-model="userForm.last_name"
                  class="w-full"
                  :placeholder="t('auth.user.form.placeholder.last_name')"
                  :invalid="hasError('last_name')"
                />
              </div>
              <InlineMessage severity="error" v-if="hasError('last_name')">
                {{ errors.last_name[0] }}
              </InlineMessage>
            </div>

            <div class="flex flex-col gap-2">
              <label for="password">{{ t('auth.user.form.label.password') }}</label>
              <div>
                <Password
                  id="password"
                  v-model="password"
                  :promptLabel="t('auth.user.form.placeholder.password')"
                  :weakLabel="t('auth.user.form.label.password_weak')"
                  :mediumLabel="t('auth.user.form.label.password_medium')"
                  :strongLabel="t('auth.user.form.label.password_strong')"
                  :invalid="hasError('password')"
                  :placeholder="t('auth.user.form.placeholder.password')"
                  toggleMask
                  class="w-full"
                />
              </div>
              <InlineMessage severity="error" v-if="hasError('password')">
                {{ errors.password[0] }}
              </InlineMessage>
            </div>

            <div class="flex flex-col gap-2">
              <label for="passwordConfirmation">
                {{ t('auth.user.form.label.password_confirmation') }}
              </label>
              <div>
                <Password
                  id="passwordConfirmation"
                  v-model="passwordConfirmation"
                  :promptLabel="t('auth.user.form.placeholder.password')"
                  :weakLabel="t('auth.user.form.label.password_weak')"
                  :mediumLabel="t('auth.user.form.label.password_medium')"
                  :strongLabel="t('auth.user.form.label.password_strong')"
                  :invalid="hasError('password')"
                  :placeholder="t('auth.user.form.placeholder.password_confirmation')"
                  toggleMask
                  class="w-full"
                />
              </div>
              <InlineMessage severity="error" v-if="hasError('password')">
                {{ errors.password[0] }}
              </InlineMessage>
            </div>
            <div class="flex flex-row justify-end gap-2">
              <Button
                :label="t('common.next')"
                icon="pi pi-arrow-right"
                iconPos="right"
                class="w-32"
                @click="nextCallback"
              />
            </div>
          </div>
        </template>
      </StepperPanel>
      <StepperPanel header="Information du restaurant">
        <template #content>
          <div class="flex flex-col gap-4">
            <div class="flex flex-col gap-2">
              <label for="restaurantName">{{ t('auth.user.form.label.restaurantName') }}</label>
              <div>
                <InputText
                  type="email"
                  id="restaurantName"
                  class="w-full"
                  v-model="userForm.restaurant_name"
                  :placeholder="t('auth.user.form.placeholder.restaurantName')"
                  :invalid="hasError('restaurant_name')"
                />
              </div>
              <InlineMessage severity="error" v-if="hasError('restaurant_name')">
                {{ errors.restaurant_name[0] }}
              </InlineMessage>
            </div>
            <div class="flex flex-col gap-2">
              <label for="restaurantName">
                {{ t('profile.profile.restaurantTab.restaurantionType.description') }}
              </label>
              <div class="flex flex-col gap-2">
                <div
                  class="flex flex-row items-center"
                  v-for="attribute in restaurationTypes"
                  :key="attribute.id"
                >
                  <RadioButton
                    v-model="userForm.restauration_type_id"
                    :inputId="'radio-' + attribute.name"
                    :name="attribute.name"
                    :value="attribute.id"
                  />
                  <label :for="'radio-' + attribute.name" class="ml-2">
                    {{ t(`profile.profile.restaurantTab.restaurantionType.${attribute.name}`) }}
                  </label>
                </div>
              </div>
              <InlineMessage severity="error" v-if="hasError('restauration_type_id')">
                {{ errors.restauration_type_id[0] }}
              </InlineMessage>
            </div>
          </div>
          <Button
            :label="t('auth.registration.register')"
            :disabled="isFetching"
            :loading="isFetching"
            class="w-full mt-8"
            size="large"
            type="submit"
            @click="register(userForm, password, passwordConfirmation)"
          />
        </template>
      </StepperPanel>
    </Stepper>
    <!-- </form> -->
    <Divider />
    <p>Vous avez déjà un compte ? <InlineLink destination="/login">Se connecter</InlineLink></p>
  </main>
</template>
