<template>
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.28364 4.70137L4.32 2.73973L2.78182 4.28493L4.73455 6.24657L6.28364 4.70137ZM0 10.9041H3.27273V13.0959H0V10.9041ZM10.9091 0H13.0909V3.23288H10.9091V0ZM19.68 2.73425L21.216 4.27616L19.2633 6.23781L17.7284 4.69479L19.68 2.73425ZM17.7164 19.2986L19.6691 21.2712L21.2073 19.726L19.2436 17.7644L17.7164 19.2986ZM20.7273 10.9041H24V13.0959H20.7273V10.9041ZM12 5.42466C8.38909 5.42466 5.45455 8.3726 5.45455 12C5.45455 15.6274 8.38909 18.5753 12 18.5753C15.6109 18.5753 18.5455 15.6274 18.5455 12C18.5455 8.3726 15.6109 5.42466 12 5.42466ZM12 16.3836C9.58909 16.3836 7.63636 14.4219 7.63636 12C7.63636 9.57808 9.58909 7.61644 12 7.61644C14.4109 7.61644 16.3636 9.57808 16.3636 12C16.3636 14.4219 14.4109 16.3836 12 16.3836ZM10.9091 20.7671H13.0909V24H10.9091V20.7671ZM2.78182 19.7151L4.32 21.2603L6.27273 19.2877L4.73455 17.7425L2.78182 19.7151Z"
      fill="currentColor"
    />
  </svg>
</template>
