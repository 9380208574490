<template>
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M24.0039 10.8H18.9999L22.8879 6.912L21.1959 5.208L15.6039 10.8H13.2039V8.4L18.7959 2.808L17.0919 1.116L13.2039 5.004V0H10.8039V5.004L6.91591 1.116L5.21191 2.808L10.8039 8.4V10.8H8.40391L2.81191 5.208L1.11991 6.912L5.00791 10.8H0.00390625V13.2H5.00791L1.11991 17.088L2.81191 18.792L8.40391 13.2H10.8039V15.6L5.21191 21.192L6.91591 22.884L10.8039 18.996V24H13.2039V18.996L17.0919 22.884L18.7959 21.192L13.2039 15.6V13.2H15.6039L21.1959 18.792L22.8879 17.088L18.9999 13.2H24.0039V10.8Z"
      fill="currentColor"
    />
  </svg>
</template>
