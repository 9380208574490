// src/axios.js
import axios from 'axios'

axios.defaults.withCredentials = true
axios.defaults.withXSRFToken = true

const instance = axios.create({
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
    'Content-Type': 'application/json',
    'Accept': 'application/json',
  },
})

instance.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    if ([403].includes(error.response.status)) {
      window.location.assign('/')
    } else {
      return Promise.reject(error)
    }
  }
)

export default instance
