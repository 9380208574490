<template>
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.38914 20.6102C2.33003 19.5509 1.5004 18.3268 0.900241 16.938C0.30008 15.5491 0 14.1132 0 12.6301C0 11.1471 0.282428 9.68176 0.847285 8.23404C1.41214 6.78633 2.33003 5.42689 3.60096 4.15573C4.42471 3.33182 5.44263 2.62562 6.65472 2.03712C7.86681 1.44862 9.30249 0.983703 10.9618 0.642372C12.621 0.301041 14.5156 0.0950656 16.6456 0.0244454C18.7756 -0.0461747 21.1586 0.0362155 23.7946 0.271616C23.9829 2.76686 24.0417 5.06202 23.9711 7.15708C23.9005 9.25215 23.7063 11.1412 23.3886 12.8244C23.0709 14.5075 22.6237 15.9787 22.0471 17.2381C21.4704 18.4975 20.7585 19.5509 19.9112 20.3984C18.6638 21.646 17.3399 22.5582 15.9396 23.1349C14.5392 23.7116 13.1094 24 11.6502 24C10.1204 24 8.62584 23.6999 7.16662 23.0996C5.70741 22.4993 4.44825 21.6695 3.38914 20.6102ZM7.34314 20.0453C8.02568 20.4454 8.72586 20.7338 9.4437 20.9104C10.1615 21.0869 10.897 21.1752 11.6502 21.1752C12.7328 21.1752 13.8037 20.9574 14.8628 20.522C15.9219 20.0865 16.9339 19.3861 17.8989 18.421C18.3225 17.9973 18.7521 17.4029 19.1875 16.6378C19.6229 15.8728 19.9995 14.8723 20.3172 13.6365C20.6349 12.4006 20.8762 10.9058 21.0409 9.15211C21.2057 7.39837 21.2292 5.30919 21.1115 2.88456C19.9583 2.83748 18.6579 2.81983 17.2105 2.8316C15.763 2.84337 14.3215 2.95518 12.8858 3.16704C11.4501 3.3789 10.0851 3.72024 8.79059 4.19104C7.49612 4.66184 6.43702 5.30919 5.61327 6.13309C4.55416 7.19239 3.82455 8.23993 3.42445 9.27569C3.02434 10.3115 2.82428 11.3119 2.82428 12.277C2.82428 13.6659 3.08906 14.8841 3.61861 15.9316C4.14817 16.9792 4.613 17.7148 5.01311 18.1385C6.00161 16.2553 7.30784 14.4486 8.9318 12.7184C10.5558 10.9882 12.4504 9.56994 14.6157 8.46356C12.9211 9.94658 11.4442 11.6238 10.1851 13.4952C8.92592 15.3667 7.9786 17.55 7.34314 20.0453Z"
      fill="currentColor"
    />
  </svg>
</template>
