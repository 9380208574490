import type { ToastServiceMethods } from 'primevue/toastservice'
import { useFetch } from './useFetch'
import { useEnvStore } from '@/stores/envStore'
import i18n from '@/i18n'
import type { User } from '@/types/api'

const { fetchData } = useFetch()
const { t } = i18n.global

export function useProfileProfile(toast: ToastServiceMethods) {
  async function updateUserProperty(
    propertyName: string,
    value: string | number
  ): Promise<User | null> {
    const url = useEnvStore().apiUrl + '/users'
    const { error, data } = await fetchData(url, 'PATCH', { [propertyName]: value })

    if (error) {
      console.log(error)
      toast.add({
        severity: 'error',
        summary: t('common.error'),
        detail: t('profile.profile.restaurantTab.updateError'),
        life: 3000,
      })
      return null
    }
    toast.add({
      severity: 'success',
      summary: t('common.success'),
      detail: t('profile.profile.restaurantTab.updateSuccess'),
      life: 3000,
    })

    return data as User
  }

  return { updateUserProperty }
}
