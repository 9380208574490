import DishCreationFormView from '@/views/Profile/DishCreationFormView.vue'
import FavoritesView from '@/views/Profile/FavoritesView.vue'
import GenerationHistoryView from '@/views/Profile/GenerationHistoryView.vue'
import ProfileHomeView from '@/views/Profile/ProfileHomeView.vue'
import MyScansView from '@/views/Profile/MyScansView.vue'
import MyProfileView from '@/views/Profile/MyProfileView.vue'

const profileRoutes = [
  {
    path: '/profile',
    name: 'profile',
    meta: {
      headerConfig: {
        headerLabel: 'profile',
        hide: false,
        showBack: true,
        showProfile: false,
      },
      footerConfig: {
        hide: true,
      },
      securityConfig: {
        requireAuthentication: true,
        rolesAllowed: ['common', 'admin'],
      },
      containerMargins: true,
    },
    component: ProfileHomeView,
  },
  {
    path: '/profile/favorites',
    name: 'favorites',
    meta: {
      headerConfig: {
        headerLabel: 'myFavorites',
        hide: false,
        showBack: true,
        showProfile: false,
      },
      footerConfig: {
        hide: true,
      },
      securityConfig: {
        requireAuthentication: true,
        rolesAllowed: ['common', 'admin'],
      },
      containerMargins: true,
    },
    component: FavoritesView,
  },
  {
    path: '/profile/add-edit-recipe',
    name: 'dish-creation-edition-form',
    meta: {
      headerConfig: {
        headerLabel: 'profile',
        hide: false,
        showBack: true,
        showProfile: false,
      },
      footerConfig: {
        hide: true,
      },
      securityConfig: {
        requireAuthentication: true,
        rolesAllowed: ['common', 'admin'],
      },
      containerMargins: true,
    },
    props: (route) => ({ dishEdition: route.query.edit === 'true' ? true : false }),
    component: DishCreationFormView,
  },
  {
    path: '/profile/generation-history',
    name: 'generation-history',
    meta: {
      headerConfig: {
        headerLabel: 'history',
        hide: false,
        showBack: true,
        showProfile: false,
      },
      footerConfig: {
        hide: true,
      },
      securityConfig: {
        requireAuthentication: true,
        rolesAllowed: ['common', 'admin'],
      },
      containerMargins: true,
    },
    component: GenerationHistoryView,
  },
  {
    path: '/profile/my-scans',
    name: 'my-scans',
    meta: {
      headerConfig: {
        headerLabel: 'myScans',
        hide: false,
        showBack: true,
        showProfile: false,
      },
      footerConfig: {
        hide: true,
      },
      securityConfig: {
        requireAuthentication: true,
        rolesAllowed: ['common', 'admin'],
      },
    },
    component: MyScansView,
  },
  {
    path: '/profile/my-profile',
    name: 'my-profile',
    meta: {
      headerConfig: {
        headerLabel: 'myProfile',
        hide: false,
        showBack: true,
        showProfile: false,
      },
      footerConfig: {
        hide: true,
      },
      securityConfig: {
        requireAuthentication: true,
        rolesAllowed: ['common', 'admin'],
      },
    },
    component: MyProfileView,
  },
]

export default profileRoutes
