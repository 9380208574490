import { useEnvStore } from '@/stores/envStore'
import { useFetch } from './useFetch'
import i18n from '@/i18n'
import type { Dish } from '@/types/dish'

const { fetchData } = useFetch()

const { t } = i18n.global

export const useDishes = () => {
  async function deleteDish(dishId: number): Promise<boolean> {
    const url = useEnvStore().apiUrl + '/dishes/' + dishId + '/delete'
    const { error } = await fetchData(url, 'DELETE')
    if (error) {
      console.log(error)
      return false
    }
    return true
  }

  async function duplicateDish(dishId: number): Promise<null | Dish> {
    const url = useEnvStore().apiUrl + '/dishes/' + dishId + '/duplicate'
    const { data, error } = await fetchData(url, 'POST', { name_suffix: ` (${t('common.copy')})` })
    if (error) {
      console.log(error)
      return null
    }
    return data as Dish
  }

  async function toggleFavorite(dishId: number): Promise<null | boolean> {
    const url = useEnvStore().apiUrl + '/dishes/' + dishId + '/favorite'
    const { data, error } = await fetchData(url, 'PATCH')
    if (error) {
      console.log(error)
      return null
    }
    return data.is_favorite
  }

  return { deleteDish, duplicateDish, toggleFavorite }
}
