import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

export function useCategories() {
  const { t } = useI18n();

  const categories = computed(() => {
    const sortedCategories = [
      { name: t('orders.ingredientsCategories.meat'), value: 'meat' },
      { name: t('orders.ingredientsCategories.fish'), value: 'fish' },
      { name: t('orders.ingredientsCategories.fruits_veggies'), value: 'fruits_veggies' },
      { name: t('orders.ingredientsCategories.dried_economat'), value: 'dried_economat' },
      { name: t('orders.ingredientsCategories.dairy'), value: 'dairy' },
      { name: t('orders.ingredientsCategories.frozen'), value: 'frozen' },
      { name: t('orders.ingredientsCategories.alcohol'), value: 'alcohol' },
      { name: t('orders.ingredientsCategories.beverage'), value: 'beverage' },
      { name: t('orders.ingredientsCategories.other'), value: 'other' },
    ];

    return sortedCategories;
  });


  return {
    categories
  };
}