<script setup lang="ts">
import { ref, onMounted, onUnmounted, watch, computed } from 'vue'
import ProgressBar from 'primevue/progressbar'
import { useI18n } from 'vue-i18n'
import type { MessageSchema } from '@/i18n'

const { t } = useI18n<{ message: MessageSchema }>({ useScope: 'global' })

// Props pour spécifier la durée totale en secondes
const props = defineProps({
  durationInSeconds: {
    type: Number,
    required: true,
    default: 20,
  },
  showProgress: {
    type: Boolean,
    default: false,
  },
})

const progress = ref<number>(0)
const mode = ref<'determinate' | 'indeterminate' | undefined>('determinate')
let intervalId: ReturnType<typeof setInterval>

const totalUpdates = props.durationInSeconds * 2 // Mettre à jour toutes les 0.5 secondes
const remainingTime = ref(props.durationInSeconds)
const updatesLeft = ref(totalUpdates)

const updateProgress = () => {
  if (progress.value < 100) {
    const maxIncrement = ((100 - progress.value) / updatesLeft.value) * 2
    const randomIncrement = Math.random() * maxIncrement
    progress.value = Math.min(100, progress.value + randomIncrement)
    updatesLeft.value -= 1
  }
}

onMounted(() => {
  intervalId = setInterval(() => {
    updateProgress()
    remainingTime.value -= 0.5
    if (remainingTime.value <= 0) {
      clearInterval(intervalId)
      progress.value = 100
    }
  }, 500)
  // Observer la progression pour changer le mode
})

watch(progress, (newVal) => {
  if (newVal >= 100) {
    clearInterval(intervalId)
    mode.value = 'indeterminate'
    progress.value = 100
  }
})

onUnmounted(() => {
  clearInterval(intervalId)
})

const progressRounded = computed(() => Math.round(progress.value))
</script>

<template>
  <div class="w-full">
    <p class="text-center" v-if="showProgress">Progression : {{ progressRounded }}%</p>
    <!-- <p class="my-2 text-center" v-else>{{ t('common.loading') }}</p> -->
    <ProgressBar :value="progress" :mode="mode" :showValue="false" />
  </div>
</template>
