<template>
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.4 1C15.312 1 13.308 1.97112 12 3.50572C10.692 1.97112 8.688 1 6.6 1C2.904 1 0 3.90136 0 7.594C0 12.1259 4.08 15.8185 10.26 21.4294L12 23L13.74 21.4174C19.92 15.8185 24 12.1259 24 7.594C24 3.90136 21.096 1 17.4 1Z"
      fill="currentColor"
    />
  </svg>
</template>
