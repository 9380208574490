<template>
  <div class="icon-container">
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.45455 23C4.85455 23 4.34109 22.7848 3.91418 22.3543C3.48727 21.9238 3.27345 21.4057 3.27273 20.8V17.5H5.45455V20.8H18.5455V17.5H20.7273V20.8C20.7273 21.405 20.5138 21.9231 20.0869 22.3543C19.66 22.7855 19.1462 23.0007 18.5455 23H5.45455ZM3.27273 10.9V3.2C3.27273 2.595 3.48655 2.07727 3.91418 1.6468C4.34182 1.21633 4.85527 1.00073 5.45455 1H14.1818L20.7273 7.6V10.9H18.5455V8.7H13.0909V3.2H5.45455V10.9H3.27273ZM0 15.3V13.1H24V15.3H0Z"
        fill="#173447" />
    </svg>

  </div>
</template>
<style scoped>
.icon-container {
  width: 45px;
  height: 45px;
  display: inline-flex;
  padding: 10px;
  align-items: center;
  gap: 10px;
  border-radius: 44px;
  border: 2px solid var(--couleurs-bleus-noir-bleu-prussian-blue, #173447);
  box-shadow: 1px 1px 0px 0px #ED8D4C;
}
</style>