<template>
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.55556 10C2.15 10 1 11.125 1 12.5C1 13.875 2.15 15 3.55556 15C4.96111 15 6.11111 13.875 6.11111 12.5C6.11111 11.125 4.96111 10 3.55556 10ZM21.4444 10C20.0389 10 18.8889 11.125 18.8889 12.5C18.8889 13.875 20.0389 15 21.4444 15C22.85 15 24 13.875 24 12.5C24 11.125 22.85 10 21.4444 10ZM12.5 10C11.0944 10 9.94444 11.125 9.94444 12.5C9.94444 13.875 11.0944 15 12.5 15C13.9056 15 15.0556 13.875 15.0556 12.5C15.0556 11.125 13.9056 10 12.5 10Z"
      fill="currentColor"
    />
  </svg>
</template>
