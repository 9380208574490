import TabMeats from "@/assets/icons/Orders/TabMeats.vue";
import { levenshteinDistance } from "./functions";
import { convertUnit } from "./prices";
import TabFishes from "@/assets/icons/Orders/TabFishes.vue";
import TabVegetables from "@/assets/icons/Orders/TabVegetables.vue";
import TabDry from "@/assets/icons/Orders/TabDry.vue";
import TabDairy from "@/assets/icons/Orders/TabDairy.vue";
import TabFrozen from "@/assets/icons/Orders/TabFrozen.vue";
import TabAlcohols from "@/assets/icons/Orders/TabAlcohols.vue";
import TabDrinks from "@/assets/icons/Orders/TabDrinks.vue";
import type { Ingredient } from "@/types/dish";
import TabOthers from "@/assets/icons/Orders/TabOthers.vue";
interface IngredientGroup {
  group: string;
  ingredients: Ingredient[] | null;
}

export function aggregateGroupInfo (group) {
  let totalQuantity = 0;
  let baseUnit;

  // Determine a common base unit based on the first ingredient
  if (group.ingredients[0].unit === 'kg' || group.ingredients[0].unit === 'g') {
    baseUnit = 'g';
  } else if (group.ingredients[0].unit === 'l' || group.ingredients[0].unit === 'ml' || group.ingredients[0].unit === 'dl') {
    baseUnit = 'ml';
  } else {
    baseUnit = group.ingredients[0].unit; // For non-convertible units
  }

  let isUnitConsistent = true;
  const mixedUnits = new Set();
  for (const ingredient of group.ingredients) {
    mixedUnits.add(ingredient.unit);

    let convertedQuantity = parseFloat(ingredient.quantity);
    if (ingredient.unit === 'kg') {
      convertedQuantity = convertUnit(convertedQuantity, ingredient.unit, 'g');
    } else if (ingredient.unit === 'cl' || ingredient.unit === 'dl' || ingredient.unit === 'l') {
      convertedQuantity = convertUnit(convertedQuantity, ingredient.unit, 'ml');
    }

    totalQuantity += convertedQuantity;

    // Check if current unit matches the base unit (after conversion)
    if ((baseUnit === 'g' && !['kg', 'g'].includes(ingredient.unit)) ||
      (baseUnit === 'ml' && !['l', 'ml', 'dl'].includes(ingredient.unit))
      || (!['kg', 'g', 'ml', 'l', 'dl'].includes(baseUnit) && baseUnit != ingredient.unit)) {
      isUnitConsistent = false;
    }
  }
  if (mixedUnits.size > 1 && !isUnitConsistent) {
    return {
      totalQuantity: null,
      unit: 'mix'
    };
  }

  if (baseUnit === 'g' && totalQuantity >= 1000) {
    return {
      totalQuantity: totalQuantity / 1000,
      unit: 'kg'
    };
  } else if (baseUnit === 'ml' && totalQuantity >= 1000) {
    return {
      totalQuantity: totalQuantity / 1000,
      unit: 'l'
    };
  }

  return {
    totalQuantity,
    unit: baseUnit
  };
};

export function areWordsSimilar(word1: string, word2: string, threshold: number = 0.75): boolean {
  const normalized1 = normalizeString(word1);
  const normalized2 = normalizeString(word2);
  const distance = levenshteinDistance(normalized1, normalized2);
  const longest = Math.max(normalized1.length, normalized2.length);
  const similarity = 1 - distance / longest;

  console.log("Normalized names:", normalized1, normalized2);
  console.log("Distance:", distance);
  console.log("Similarity:", similarity, "Threshold:", threshold);

  return similarity >= threshold;
}

const normalizeString = (str) => {
  const replacements: Record<string, string> = {
    // French
    'œ': 'oe',
    'æ': 'ae',
    'ç': 'c',
    // Spain
    'ñ': 'n',
    '¡': 'i',
    // German
    'ß': 'ss',
    // Nordic characters
    'ø': 'o',
    'å': 'a',
  };
  const normalized = str.split('').map(char => replacements[char] || char).join('');
  return normalized.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
};


export function groupIngredientsInCategory (ingredientsByCategory, orderListStore) {
  return Object.keys(ingredientsByCategory).map(category => {
    const allIngredients: Ingredient[] = orderListStore.getIngredientsByCategory(category).value;

    const groupedByName: Record<string, IngredientGroup> = {};

    allIngredients.forEach(ingredient => {
      const normalized = normalizeString(ingredient.name);

      let foundGroup: IngredientGroup = { group: '', ingredients: null };

      // Recherche d'un groupe existant avec une similarité suffisante
      Object.entries(groupedByName).some(([key, group]) => {
        if (areWordsSimilar(group.group, ingredient.name)) {
          foundGroup = group;
          return true;
        }
        return false;
      });

      if (foundGroup && foundGroup.ingredients) {
        foundGroup.ingredients.push(ingredient);
      } else {
        groupedByName[normalized] = {
          group: ingredient.name,
          ingredients: [ingredient]
        };
      }
    });

    // Process groups to finalize structure
    const ingredientGroups = Object.values(groupedByName).map(group => ({
      group: group.group,
      ingredients: group.ingredients
    }));

    return {
      name: category,
      ingredientGroups,
      icon: getIconForCategory(category)
    };
  });
}

// export function groupIngredientsInCategory(ingredientsByCategory: Record<string, Ingredient[]>, orderListStore: any): IngredientTreeNode[] {
//   return Object.keys(ingredientsByCategory).map(category => {
//     const allIngredients = orderListStore.getIngredientsByCategory(category).value;
//     const groupedByName: Record<string, TreeNode> = {};

//     allIngredients.forEach((ingredient: Ingredient) => {
//       const normalized = normalizeString(ingredient.name);
//       if (!groupedByName[normalized]) {
//         groupedByName[normalized] = {
//           key: ingredient.id.toString(),
//           data: { name: ingredient.name },
//           children: []
//         };
//       }
//       if (groupedByName[normalized].children) {
//         groupedByName[normalized].children.push({
//           key: `${ingredient.id}-${ingredient.name}`,
//           data: {
//             name: ingredient.name,
//             quantity: ingredient.quantity,
//             price: ingredient.price,
//             unit: ingredient.unit
//           }
//         });
//       }
//     });

//     const ingredientGroups: TreeNode[] = Object.values(groupedByName).map(group => ({
//       key: group.key,
//       data: { name: group.data.name },
//       children: group.children
//     }));

//     return {
//       key: category,
//       data: { name: category },
//       children: ingredientGroups,
//       icon: getIconForCategory(category)
//     };
//   });
// }
function getIconForCategory(category) {
  switch (category) {
    case 'meat': return TabMeats;
    case 'fish': return TabFishes;
    case 'fruits_veggies': return TabVegetables;
    case 'dried_economat': return TabDry;
    case 'dairy': return TabDairy;
    case 'frozen': return TabFrozen;
    case 'alcohol': return TabAlcohols;
    case 'beverage': return TabDrinks;
    case 'other': return TabOthers;
    default: return null; // Add a default icon or null if none
  }
}