<template>
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.0839 9.6144V10.2784L12.7412 10.3727C16.9642 10.9787 20.2655 14.4336 20.6192 18.7294H2.22528C2.57894 14.4336 5.88032 10.9787 10.1033 10.3727L10.7606 10.2784V9.6144V8.78718V8.02108H9.99446H9.33279V7.41161H13.5117V8.02108H12.85H12.0839V8.78718V9.6144Z"
      stroke="currentColor"
      stroke-width="1.5322"
    />
    <path
      d="M17.1338 7.15394C17.7164 7.15394 18.2751 6.89815 18.6871 6.44284C19.099 5.98752 19.3304 5.36999 19.3304 4.72607H20.3443C20.3443 5.36999 20.5757 5.98752 20.9876 6.44284C21.3996 6.89815 21.9583 7.15394 22.5409 7.15394V8.2745C21.9583 8.2745 21.3996 8.53029 20.9876 8.98561C20.5757 9.44092 20.3443 10.0585 20.3443 10.7024H19.3304C19.3304 10.0585 19.099 9.44092 18.6871 8.98561C18.2751 8.53029 17.7164 8.2745 17.1338 8.2745V7.15394Z"
      fill="currentColor"
    />
    <path
      d="M21.417 4.31176C21.6953 4.31176 21.9622 4.17356 22.159 3.92756C22.3558 3.68155 22.4664 3.3479 22.4664 3H22.9507C22.9507 3.3479 23.0613 3.68155 23.2581 3.92756C23.4549 4.17356 23.7218 4.31176 24.0002 4.31176V4.9172C23.7218 4.9172 23.4549 5.0554 23.2581 5.3014C23.0613 5.54741 22.9507 5.88106 22.9507 6.22896H22.4664C22.4664 5.88106 22.3558 5.54741 22.159 5.3014C21.9622 5.0554 21.6953 4.9172 21.417 4.9172V4.31176Z"
      fill="currentColor"
    />
  </svg>
</template>
