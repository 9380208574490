<script setup lang="ts">
import ChevronLeftIcon from '@/assets/icons/Planning/ChevronLeftIcon.vue';
import ChevronRightIcon from '@/assets/icons/Planning/ChevronRightIcon.vue';
import { useAuth } from '@/composables/useAuth';
import { useCategories } from '@/composables/useCategories';
import { useFetch } from '@/composables/useFetch';
import type { MessageSchema } from '@/i18n';
import { useDishesStore } from '@/stores/dishesStore';
import { useEnvStore } from '@/stores/envStore';
import { usePlanningStore } from '@/stores/planningStore';
import { type Ingredient } from '@/types/dish';
import { debounce } from '@/utils/functions';
import PlannedMenuView from '@/views/Planning/PlannedMenuView.vue';
import WeekMenuView from '@/views/Planning/WeekMenuView.vue';
import Button from 'primevue/button';
import Dropdown from 'primevue/dropdown';
import InputNumber from 'primevue/inputnumber';
import InputText from 'primevue/inputtext';
import Sidebar from 'primevue/sidebar';
import { computed, onMounted, onUnmounted, onUpdated, ref, watch, watchEffect, type ModelRef, type Ref } from 'vue';
import { useI18n } from 'vue-i18n';

const { t, te } = useI18n<{ message: MessageSchema }>({ useScope: 'global' })

// const { categories } = useCategories();
const showSideBar: ModelRef<boolean | undefined> = defineModel('visible', { required: true })
const sideBarIngredient: ModelRef<Ingredient | undefined> = defineModel('ingredient');
// const ingredientCategory = { name: t('orders.ingredientsCategories.other'), value: 'other' }
// const dishesStore = useDishesStore();
// const ingredientUnits = ref(dishesStore.units);
// const { isFetching, fetchData } = useFetch()

const user = ref(useAuth().user)
const planningStore = usePlanningStore();

const edition = ref(false);

// const emit = defineEmits({
//   'ingredient-edited': null,
//   'delete-ingredient': (index: number) => true,
//   'edit-ingredient': null,
//   'add-ingredient': null
// })
// const ingredient = ref(props.ingredient);

// const props = withDefaults(
//   defineProps<{
//     // ingredients: Ingredient[]
//     showPrice?: boolean
//   }>(),
//   {
//     showPrice: false,
//   }
// )
onUpdated(() => {
  edition.value = sideBarIngredient.value?.id === -1 ? false : true;
})

onMounted(async () => {
  // if (!ingredientUnits.value.length) {
  //   const { data, error } = await fetchData(
  //     useEnvStore().apiUrl + `/units/default`,
  //     'GET'
  //   )
  //   if (error) {
  //     console.log('error', error)
  //   } else {
  //     ingredientUnits.value = data;
  //   }
  // }
})



const currentDate = ref(new Date());
const currentWeekNumber = ref(getWeekNumber(currentDate.value));
const weekRange = ref(getWeekRange(currentDate.value));

function getWeekNumber(date: Date): number {
  const firstDayOfYear = new Date(date.getFullYear(), 0, 1);
  const pastDaysOfYear = (date.getTime() - firstDayOfYear.getTime()) / 86400000;
  return Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7);
}

function getWeekRange(date: Date): { start: Date, end: Date } {
  const start = new Date(date);
  const dayOfWeek = start.getDay();
  const diffToMonday = dayOfWeek === 0 ? -6 : 1 - dayOfWeek; // If Sunday, go to Monday of the same week
  start.setDate(start.getDate() + diffToMonday);

  const end = new Date(start);
  end.setDate(start.getDate() + 6); // Get the following Sunday

  return { start, end };
}

function goToPreviousWeek() {
  currentDate.value.setDate(currentDate.value.getDate() - 7);
  currentWeekNumber.value = getWeekNumber(currentDate.value);
  weekRange.value = getWeekRange(currentDate.value);
}

function goToNextWeek() {
  currentDate.value.setDate(currentDate.value.getDate() + 7);
  currentWeekNumber.value = getWeekNumber(currentDate.value);
  weekRange.value = getWeekRange(currentDate.value);
}

// Formatting dates
function formatDate(date: Date): string {
  const day = String(date.getDate()).padStart(2, '0'); // Ensures 2-digit day
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Ensures 2-digit month
  const year = String(date.getFullYear()).slice(-2); // Gets last 2 digits of the year
  return `${day}.${month}.${year}`;
}

function unselectPlannedMenu() {
  planningStore.selectedPlannedMenu = null;
}

watch(weekRange, async (newRange) => {
  const restaurantId = user.value?.restaurants[0]?.id || null
  await planningStore.fetchMenusIfNeeded(newRange.start.toISOString().split('T')[0], newRange.end.toISOString().split('T')[0], restaurantId);
}, { deep: true, immediate: true });

watch(showSideBar, (newValue) => {
  if (!newValue) {
    console.log("close sidebar");
    planningStore.clipboardDishToPlan = null;
  }
})


// const sideBarIngredientIndex = ref(0)
// console.log("Side bar ingredient : ", sideBarIngredient.value)

// watchEffect(() => {
//   console.log("SIDEBAR INGREIDNET", sideBarIngredient.value?.category)
// })


// // watch(() => sideBarIngredient.value?.quantity, (newQuantity, oldQuantity) => {
// //   if (sideBarIngredient.value && oldQuantity !== undefined && newQuantity !== undefined && sideBarIngredient.value.price) {
// //     const oldPrice = sideBarIngredient.value.price;
// //     const proportion = newQuantity / oldQuantity;
// //     sideBarIngredient.value.price = oldPrice * proportion;
// //   }
// // });
// // console.log("Side basr")
// // console.log(sideBarIngredient.value)
// // watch(sideBarIngredientIndex, (currentIngredient) => {
// //   if (currentIngredient) {
// //     sideBarIngredient.value = currentIngredient;
// //   }
// // });

// function deleteIngredient() {
//   // console.log("sidebar ingredient")
//   // console.log(sideBarIngredient.value)
//   // console.log("sideBar index", sideBarIngredient.value)
//   if (sideBarIngredient.value && typeof sideBarIngredientIndex.value === 'number') {
//     emit('delete-ingredient', sideBarIngredientIndex.value);
//   }
//   resetSideBarIngredient();
//   showSideBar.value = false;
// }

// function addIngredient() {
//   emit('add-ingredient')
//   resetSideBarIngredient();
//   showSideBar.value = false;
// }

// function editIngredient() {
//   emit('edit-ingredient')
//   resetSideBarIngredient();
//   showSideBar.value = false;
// }

// function resetSideBarIngredient() {
//   sideBarIngredient.value = {
//     id: -1,
//     category: categories.value[0].value,
//     name: '',
//     quantity: 0,
//     unit: '',
//     price: 0
//   }
// }

// watch(() => sideBarIngredient.value, (newSideBarIngredient: Ingredient | undefined) => {
//   console.log("DBOUT")
//   if (!sideBarIngredient.value || !newSideBarIngredient) return
//   if (!newSideBarIngredient.name) {
//     sideBarIngredient.value.name = '';
//   }
//   if (newSideBarIngredient.quantity === undefined || newSideBarIngredient.quantity === null) {
//     sideBarIngredient.value.quantity = 0;
//   }
//   if (!newSideBarIngredient.unit === undefined || newSideBarIngredient.unit === null) {
//     sideBarIngredient.value.unit = '';
//   }
//   if (!newSideBarIngredient.category) {
//     sideBarIngredient.value.category = 'other'
//   }
//   if (newSideBarIngredient.price === undefined || newSideBarIngredient.price === null) {
//     sideBarIngredient.value.price = 0;
//   }
// }, { deep: true });


// const isNameInvalid = computed(() => sideBarIngredient.value?.name === undefined || sideBarIngredient.value?.name === null || sideBarIngredient.value?.name === '');
// const isQuantityInvalid = computed(() => sideBarIngredient.value?.quantity === undefined || sideBarIngredient.value?.quantity === null);
// const isUnitInvalid = computed(() => sideBarIngredient.value?.unit === undefined || sideBarIngredient.value?.unit === null);
// const isCategoryInvalid = computed(() => sideBarIngredient.value?.category === undefined || sideBarIngredient.value?.category === null);


// const isFormInvalid = computed(() => isNameInvalid.value || isQuantityInvalid.value || isUnitInvalid.value || isCategoryInvalid.value);
</script>
<template>
  <Sidebar v-model:visible="showSideBar" :header="t('menuDishView.addtoWeekMenu')" position="bottom" style="height: 95%"
    :block-scroll="true">

    <div v-if="!planningStore.selectedPlannedMenu">

      <div class="flex justify-center items-center my-4">
        <!-- Previous week button -->

        <button @click="goToPreviousWeek" class="rounded-l-lg">
          <ChevronLeftIcon class="w-12 h-12 text-primary-500" />
        </button>

        <!-- Display the week number and date range -->
        <div class="px-4 py-2 text-center">
          <div>Semaine {{ currentWeekNumber }}</div>
          <div class="text-sm">{{ formatDate(weekRange.start) }} - {{ formatDate(weekRange.end) }}</div>
        </div>
        <button @click="goToNextWeek" class="rounded-r-lg">
          <ChevronRightIcon class="w-12 h-12 text-primary-500" />
        </button>
      </div>

      <WeekMenuView :weekRange></WeekMenuView>
    </div>
    <div v-else>
      <div class="z-10 flex items-center justify-start grow">
        <Button rounded link icon="pi pi-chevron-left" class="text-xl" :pt="{ root: { class: ' !w-8 !h-8' } }"
          :pt-options="{ mergeProps: true }" @click="unselectPlannedMenu" />
      </div>
      <PlannedMenuView />
    </div>
    <!-- <div class="flex flex-col gap-4">
      <div class="flex flex-col">
        <label>{{ t('profileFavorites.creationForm.name') }}</label>
        <InputText v-model="sideBarIngredient.name" :placeholder="t('profileFavorites.creationForm.namePlaceholder')"
          :invalid="isNameInvalid" />
      </div>
      <div class="grid grid-cols-2 gap-4">
        <div class="flex flex-col">
          <label>{{ t('profileFavorites.creationForm.quantityLong') }}</label>
          <InputNumber v-model="sideBarIngredient.quantity"
            :placeholder="t('profileFavorites.creationForm.quantityPlaceholder')" :min-fraction-digits="2"
            :invalid="isQuantityInvalid" />
        </div>
        <div class="flex flex-col">
          <label>{{ t('profileFavorites.creationForm.unit') }}</label>
         

          <Dropdown v-model="sideBarIngredient.unit" :options="ingredientUnits" optionLabel="symbol"
            optionValue="symbol" :placeholder="t('profileFavorites.creationForm.unitPlaceholder')"
            class="w-full md:w-[14rem]" :invalid="isUnitInvalid">
            <template #value="slotProps">
              <div class="flex items-center" v-if="slotProps.value">
                <div>{{ te(`ingredients.unit.symbol.${slotProps.value}`)
                  ? t(`ingredients.unit.symbol.${slotProps.value}`) : slotProps.value }}</div>
              </div>
              <span v-else>
                {{ slotProps.placeholder }}
              </span>
            </template>
            <template #option="slotProps">
              <div class="flex items-center">
                <div>{{ te(`ingredients.unit.symbol.${slotProps.option.symbol}`)
                  ? t(`ingredients.unit.symbol.${slotProps.option.symbol}`) : slotProps.option.symbol }}</div>
              </div>
            </template>
          </Dropdown>
        </div>
        <div class="flex flex-col" v-if="props.showPrice">
          <label>{{ t('profileFavorites.creationForm.price') }}</label>
          <InputNumber v-model="sideBarIngredient.price"
            :placeholder="t('profileFavorites.creationForm.pricePlaceholder')" :min-fraction-digits="2" />
        </div>
      </div>
      <Dropdown v-model="sideBarIngredient.category" :options="categories" optionLabel="name" optionValue="value"
        :placeholder="t('ingredients.category.label')" class="w-full md:w-[14rem]" :invalid="isCategoryInvalid" />
      <Button v-if="edition" :label="t('profileFavorites.creationForm.deleteIngredientButton')"
        @click="deleteIngredient()" severity="danger" text />
      <Button :label="edition ? t('profileFavorites.creationForm.edit') : t('profileFavorites.creationForm.add')"
        @click="edition ? editIngredient() : addIngredient()" :disabled="isFormInvalid" />
    </div> -->
  </Sidebar>
</template>