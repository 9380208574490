<script setup lang="ts">
import CreationFilter from '@/components/Menus/Filters/CreationFilter.vue'
import { useI18n } from 'vue-i18n'
import { type MessageSchema } from '@/i18n'
import SelectWithoutIcon from '@/components/BaseComponents/SelectWithoutIcon.vue'
import { ref, watch, watchEffect } from 'vue'
import { useDishesStore } from '@/stores/dishesStore'

const { t } = useI18n<{ message: MessageSchema }>({ useScope: 'global' })

const dishesStore = ref(useDishesStore());

const regionList = ref([
  'asian',
  'orientale',
  'mediterranean',
  'nordic',
  'african',
  'caucasian',
  'occidental ',
  'latin-American',
  'caribbean'
])

function handleRegionSelect(region, selected) {
  if (selected && dishesStore.value.inspirationSource != 'country') {
    console.log("update generationParams")
    dishesStore.value.genParamsInspirationRegion.push(region);
    dishesStore.value.inspirationSource = 'region';
  } else {
    dishesStore.value.genParamsInspirationRegion = dishesStore.value.genParamsInspirationRegion.filter(i => i !== region);
    if (!dishesStore.value.genParamsInspirationRegion.length) {
      dishesStore.value.inspirationSource = 'none';
    }
  }
}

</script>

<template>
  <CreationFilter>
    <template #title>{{ t('creationFilters.region') }}</template>
    <template #content>
      <div class="flex flex-wrap justify-center w-full gap-2" :class="{ 'opacity-60': dishesStore.inspirationSource == 'country' }">
        <SelectWithoutIcon v-for="region in regionList" :key="region"
          :disabled="dishesStore.inspirationSource !== 'region' && dishesStore.inspirationSource !== 'none'"
          :selected="dishesStore.genParamsInspirationRegion.includes(region)"
          @handle-select="(selected) => dishesStore.inspirationSource != 'country' ? handleRegionSelect(region, selected) : null">
          <template #label>{{ t(`creationFilters.regionList.${region}`) }}</template>
        </SelectWithoutIcon>
      </div>
    </template>
  </CreationFilter>
</template>
