import type { Ingredient } from '@/types/dish'
import { adjustUnitsForQuantity, calculateIngredientPrice } from './prices'

const unitMapping = {
  kg: ['kilogram', 'kilograms', 'kg'],
  l: ['liter', 'liters', 'litre', 'litres'],
  pcs: ['piece', 'pieces', 'pc', 'pces'],
  tbsp: ['tablespoon', 'tablespoons'],
  tsp: ['teaspoon', 'teaspoons'],
  ml: ['milliliter', 'milliliters', 'millilitre', 'millilitres'],
  g: ['gram', 'grams'],
  dl: ['deciliter', 'deciliters', 'decilitre', 'decilitres', 'décilitre', 'décilitres'],
  cup: ['cups', 'cup'],
}

function standardizeUnitName(inputUnit: string): string {
  const lowerInputUnit = inputUnit.trim().toLowerCase()
  for (const standardUnit in unitMapping) {
    if (unitMapping[standardUnit].includes(lowerInputUnit) || standardUnit === lowerInputUnit) {
      return standardUnit
    }
  }
  // If no matching standard unit is found, return the original input unit
  return lowerInputUnit
}

function normalizeQuantityAndUnit(quantity: number, unit: string): [number, string] {
  let standardUnit = standardizeUnitName(unit)
  let quantityInStandardUnit = quantity
  switch (standardUnit) {
    case 'kg':
      quantityInStandardUnit = quantity * 1000
      standardUnit = 'g'
      break
    case 'l':
      quantityInStandardUnit = quantity * 1000
      standardUnit = 'ml'
      break
    case 'dl':
      quantityInStandardUnit = quantity * 100
      standardUnit = 'ml'
      break
    case 'cl':
      quantityInStandardUnit = quantity * 10
      standardUnit = 'ml'
      break
    default:
      // No conversion needed
      break
  }
  return [quantityInStandardUnit, standardUnit]
}

function transformAndSortIngredients(
  ingredients: Ingredient[],
  numberOfPeople: number
): Ingredient[] {
  const transformedIngredients = ingredients.map((ingredient) => {
    let converted = { unit: ingredient.unit || '', quantity: ingredient.quantity || 0 }
    converted = adjustUnitsForQuantity(converted.quantity, converted.unit, numberOfPeople)
    // console.log("transform And sort ingredient. Price: ", ingredient.price)
    return {
      ...ingredient,
      quantity: converted.quantity,
      unit: converted.unit,
      price: calculateIngredientPrice(ingredient.price, numberOfPeople),
    }
  })

  // Trier par prix décroissant
  transformedIngredients.sort((a, b) => b.price - a.price)

  return transformedIngredients
}

export { normalizeQuantityAndUnit, standardizeUnitName, transformAndSortIngredients }
