<template>
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.3676 2.78649V21.2135H2.94054V2.78649H21.3676ZM21.3676 0.154053H2.94054C1.4927 0.154053 0.308105 1.33865 0.308105 2.78649V21.2135C0.308105 22.6614 1.4927 23.8459 2.94054 23.8459H21.3676C22.8154 23.8459 24 22.6614 24 21.2135V2.78649C24 1.33865 22.8154 0.154053 21.3676 0.154053ZM14.9708 11.8157L11.0221 16.9095L8.2054 13.5005L4.25675 18.5811H20.0514L14.9708 11.8157Z"
      fill="currentColor"
    />
  </svg>
</template>
