import { defineStore } from 'pinia'
import { ref, type Ref } from 'vue'

// export const useDishesStore = defineStore('dishes', {
export const useEnvStore = defineStore('env', () => {
  const apiUrl: Ref<string> = ref(
    import.meta.env.VITE_MODE === 'local'
      ? 'http://localhost:8000/api'
      : import.meta.env.VITE_API_URL
  )
  const environment = ref(
    import.meta.env.VITE_MODE ? import.meta.env.VITE_MODE : import.meta.env.MODE
  )
  const lastUpdate = ref(import.meta.env.VITE_APP_LAST_UPDATE)
  const appVersion = ref(import.meta.env.VITE_APP_VERSION)
  const stabilityApiUrl = ref(import.meta.env.VITE_STABILITY_API_URL)
  const stabilityApiKey = ref(import.meta.env.VITE_STABILITY_API_KEY)

  return { apiUrl, environment, lastUpdate, appVersion, stabilityApiKey, stabilityApiUrl }
})
