<script setup lang="ts">
import CreationFilter from '@/components/Menus/Filters/CreationFilter.vue'
import Slider from 'primevue/slider'
import { useI18n } from 'vue-i18n'
// import { type MessageSchema } from '@/i18n'
import { ref, watchEffect } from 'vue'
import type { MessageSchema } from '@/i18n'

const emit = defineEmits<{
  (e: 'updateAiTemperature', temperature: number): void
}>()

const { t } = useI18n<{ message: MessageSchema }>({ useScope: 'global' })

const temperature = ref(0.6)
const min = ref(0);
const max = ref(2);

watchEffect(() => {
  emit('updateAiTemperature', temperature.value)
})
</script>

<template>
  <CreationFilter>
    <template #title>Niveau créativité IA (température)</template>
    <template #content>
      <div class="card flex justify-center">
        <div class="w-14rem">
          <p class="text-center">{{ temperature }}</p>
          <Slider v-model="temperature" :min :max :step="0.1"/>
        </div>
      </div>
    </template>
  </CreationFilter>
</template>
