<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { type MessageSchema } from '@/i18n'
import { computed, ref } from 'vue';
import { useDishesStore } from '@/stores/dishesStore';
import Skeleton from 'primevue/skeleton';

const { t } = useI18n<{ message: MessageSchema }>({ useScope: 'global' })

const props = withDefaults(
  defineProps<{
    title?: string | undefined
  }>(),
  {
    title: undefined,
  }
)

const dishesStore = ref(useDishesStore());

const emit = defineEmits<{
  (e: 'generateDishTitle', type: string): void
}>()

const generationParams = ref(useDishesStore().generationParams);

function generateDish() {
  if (!generationParams.value.dish_title && typeof props.title !== 'string') return;
  generationParams.value.dish_title = props.title
  const title = props.title || '';
  if (title == '') return;
  emit('generateDishTitle', title)
  console.log(generationParams.value);
}

</script>

<template>
  <div v-if="title" class="w-full px-6 py-3 my-5 rounded-lg bg-primary-100 flex items-center justify-between gap-2"
    @click="generateDish()">
    <p class="text-sm">{{ title || 'Hello world' }}</p>

    <i class="pi pi-angle-right text-lg"></i>
  </div>
  <Skeleton v-else height="42px" class="bg-primary-300 dark:bg-primary-500 w-full my-5" />
  <!-- <GenerationResultCard v-for="dish in data.response" :key="dish.id" :dish @click="dishClicked(dish)" /> -->

</template>

<style scoped>
  div:hover {
    cursor: pointer;
  }
</style>