<template>
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 32 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.1606 18.9351L2.5745 11.472L-0.00878906 13.9955L10.1606 24L31.9912 2.52349L29.4261 0L10.1606 18.9351Z"
      fill="currentColor"
    />
  </svg>
</template>
