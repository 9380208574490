<template>
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 13H2.66667V10.3333H0V13ZM0 18.3333H2.66667V15.6667H0V18.3333ZM0 7.66667H2.66667V5H0V7.66667ZM5.33333 13H24V10.3333H5.33333V13ZM5.33333 18.3333H24V15.6667H5.33333V18.3333ZM5.33333 5V7.66667H24V5H5.33333ZM0 13H2.66667V10.3333H0V13ZM0 18.3333H2.66667V15.6667H0V18.3333ZM0 7.66667H2.66667V5H0V7.66667ZM5.33333 13H24V10.3333H5.33333V13ZM5.33333 18.3333H24V15.6667H5.33333V18.3333ZM5.33333 5V7.66667H24V5H5.33333Z"
      fill="currentColor"
    />
  </svg>
</template>
