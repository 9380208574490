<script setup lang="ts">
import CreationFilter from '@/components/Menus/Filters/CreationFilter.vue'
import { useI18n } from 'vue-i18n'
import { type MessageSchema } from '@/i18n'
import SelectButton from 'primevue/selectbutton'
import { ref, type Ref } from 'vue'
import { useDishesStore } from '@/stores/dishesStore'
import { watch } from 'vue'
import StarterIcon from '@/assets/icons/MenuFilters/StarterIcon.vue'
import MainDishIcon from '@/assets/icons/MenuFilters/MainDishIcon.vue'
import DessertIcon from '@/assets/icons/MenuFilters/DessertIcon.vue'
import SelectWithIcon from '@/components/BaseComponents/SelectWithIcon.vue'

interface DishTypeOption {
  name: string;
  value: 'starter' | 'main' | 'dessert';
  icon?: any
}

const { t } = useI18n<{ message: MessageSchema }>({ useScope: 'global' })
const dishesStore = ref(useDishesStore());

function getDefaultDishType(): DishTypeOption {
  const defaultDishType = dishesStore.value.generationParams.dish_type || 'main';
  return {
    name: t(`creationFilters.dishType.${defaultDishType}`),
    value: defaultDishType as 'starter' | 'main' | 'dessert'
  };
}

const selectedDishType: Ref<DishTypeOption> = ref(getDefaultDishType());

const toggleSelection = (option: DishTypeOption) => {
    selectedDishType.value = option;
    dishesStore.value.generationParams.dish_type = option.value;
};

const options: DishTypeOption[] = [
  { name: t('creationFilters.dishType.starter'), value: 'starter', icon: StarterIcon },
  { name: t('creationFilters.dishType.main'), value: 'main', icon: MainDishIcon },
  { name: t('creationFilters.dishType.dessert'), value: 'dessert', icon: DessertIcon},
];


watch(selectedDishType, (newValue) => {
  dishesStore.value.generationParams.dish_type = newValue.value;
}, { immediate: true });
</script>

<template>
  <CreationFilter>
    <template #title>{{ t('creationFilters.dishType.label') }}</template>
    <template #content>
      <div class="grid grid-cols-3 gap-4">
        <div v-for="option in options" :key="option.value">
          <SelectWithIcon :selected="selectedDishType?.value === option.value" label-h="2"
            @handle-select="() => toggleSelection(option)">
            <template #icon>
              <component :is="option.icon" />
            </template>
            <template #label>{{ option.name }}</template>
          </SelectWithIcon>
        </div>
      </div>
    </template>
  </CreationFilter>
</template>
