<template>
  <svg width="42" height="26" viewBox="0 0 42 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="path-1-outside-1_4970_6669" maskUnits="userSpaceOnUse" x="-0.411865" y="0" width="42" height="26"
      fill="black">
      <rect fill="none" x="-0.411865" width="42" height="26" />
      <path fill-rule="evenodd" clip-rule="evenodd"
        d="M31.3632 1.04844C31.531 0.99451 31.71 0.98543 31.8825 1.02211C32.0549 1.05879 32.2147 1.13995 32.346 1.25752L40.6638 8.71069C40.7969 8.82987 40.896 8.98223 40.951 9.15222C41.006 9.32221 41.0149 9.50375 40.9769 9.67832L38.6002 20.5697C38.5608 20.7508 38.4722 20.9176 38.3443 21.0517C38.2164 21.1859 38.054 21.2822 37.875 21.3303L27.1806 24.1968C27.0105 24.2424 26.8314 24.2427 26.6611 24.1976C26.4909 24.1526 26.3353 24.0639 26.2099 23.9402L21.6041 19.395C21.4134 19.2067 21.3053 18.9503 21.3036 18.6823C21.3019 18.4142 21.4067 18.1565 21.595 17.9658C21.7833 17.775 22.0397 17.6669 22.3077 17.6652C22.5758 17.6635 22.8335 17.7684 23.0242 17.9567L27.216 22.0959L36.7579 19.5384L38.878 9.82174L31.4299 3.14935L21.7778 6.25323L20.7496 9.39449C20.7084 9.52057 20.6427 9.63728 20.5563 9.73798C20.47 9.83867 20.3646 9.92137 20.2463 9.98135C20.128 10.0413 19.999 10.0774 19.8667 10.0875C19.7345 10.0977 19.6015 10.0817 19.4754 10.0404C19.3494 9.99917 19.2326 9.93349 19.1319 9.84713C19.0313 9.76077 18.9486 9.65542 18.8886 9.5371C18.8286 9.41879 18.7925 9.28981 18.7824 9.15755C18.7722 9.02528 18.7883 8.89232 18.8295 8.76624L20.0173 5.13511C20.0672 4.98357 20.1522 4.84598 20.2654 4.73362C20.3786 4.62126 20.5169 4.53732 20.6688 4.48868L31.3632 1.04844Z" />
      <path fill-rule="evenodd" clip-rule="evenodd"
        d="M29.6088 11.1258C29.87 11.1853 30.0969 11.3461 30.2395 11.5728C30.3821 11.7995 30.4289 12.0736 30.3694 12.3348L27.8443 23.4454C27.7827 23.7044 27.6214 23.9286 27.3954 24.0693C27.1694 24.2099 26.8969 24.2557 26.6374 24.1966C26.3778 24.1375 26.1521 23.9783 26.0092 23.7536C25.8664 23.529 25.8181 23.257 25.8747 22.9969L28.3998 11.8864C28.4593 11.6252 28.6201 11.3983 28.8468 11.2557C29.0735 11.1131 29.3477 11.0663 29.6088 11.1258Z" />
      <path fill-rule="evenodd" clip-rule="evenodd"
        d="M20.5343 4.89176C20.7076 4.68758 20.9548 4.56054 21.2216 4.53856C21.4885 4.51659 21.7532 4.60149 21.9575 4.7746L29.2955 10.9965L36.1891 9.11579C36.4476 9.04534 36.7235 9.08047 36.9561 9.21344C37.1887 9.34641 37.359 9.56634 37.4294 9.82485C37.4999 10.0834 37.4648 10.3593 37.3318 10.5919C37.1988 10.8245 36.9789 10.9947 36.7204 11.0652L29.3137 13.0853C29.1554 13.1285 28.989 13.1325 28.8289 13.0969C28.6687 13.0614 28.5197 12.9874 28.3945 12.8813L20.6515 6.31593C20.5502 6.23014 20.4669 6.12524 20.4062 6.00723C20.3455 5.88921 20.3087 5.76039 20.2978 5.62813C20.2869 5.49587 20.3022 5.36276 20.3428 5.23641C20.3834 5.11006 20.4485 4.99295 20.5343 4.89176ZM7.4077 6.33007C7.53501 6.20834 7.69195 6.12202 7.86293 6.07967C8.03391 6.03732 8.21299 6.04043 8.3824 6.08867L16.7355 8.4734C16.9054 8.52175 17.0596 8.61379 17.1827 8.74033C17.3059 8.86687 17.3937 9.02349 17.4375 9.19458L19.7576 18.2568C19.8039 18.4373 19.7993 18.6271 19.7444 18.8052C19.6895 18.9832 19.5864 19.1427 19.4465 19.2658L12.9509 24.9887C12.8239 25.1008 12.6707 25.1792 12.5055 25.2166C12.3403 25.254 12.1684 25.2493 12.0055 25.2029L3.65132 22.8171C3.48411 22.7693 3.33209 22.679 3.20999 22.5551C3.08789 22.4313 2.99985 22.278 2.95439 22.1101L0.633293 13.5247C0.585962 13.3493 0.586782 13.1645 0.635664 12.9896C0.684546 12.8147 0.779682 12.6562 0.911057 12.5308L7.4077 6.33007ZM8.38442 8.19059L2.73925 13.5772L4.75329 21.0314L12.0277 23.108L17.6466 18.1588L15.6235 10.2572L8.38442 8.19059Z" />
      <path fill-rule="evenodd" clip-rule="evenodd"
        d="M14.4427 10.9923C14.632 11.1817 14.7384 11.4385 14.7384 11.7064C14.7384 11.9742 14.632 12.2311 14.4427 12.4205L10.4025 16.4607C10.276 16.587 10.1183 16.6774 9.9455 16.7228C9.77267 16.7683 9.59088 16.767 9.41868 16.7192L1.33827 14.4749C1.20923 14.4406 1.08828 14.3809 0.982429 14.2995C0.876574 14.2181 0.787914 14.1165 0.721577 14.0006C0.655239 13.8847 0.612542 13.7568 0.595956 13.6243C0.57937 13.4918 0.589224 13.3573 0.624947 13.2287C0.660671 13.1 0.721556 12.9797 0.804078 12.8747C0.886601 12.7697 0.989123 12.6821 1.10571 12.617C1.22231 12.5519 1.35065 12.5106 1.48332 12.4954C1.616 12.4802 1.75036 12.4915 1.87865 12.5285L9.39141 14.6153L13.0145 10.9923C13.2039 10.8029 13.4607 10.6965 13.7286 10.6965C13.9964 10.6965 14.2533 10.8029 14.4427 10.9923Z" />
      <path fill-rule="evenodd" clip-rule="evenodd"
        d="M9.44399 14.7671C9.7037 14.7023 9.97849 14.7433 10.208 14.8809C10.4376 15.0186 10.6031 15.2418 10.6682 15.5014L12.6883 23.5818C12.7512 23.8409 12.7092 24.1144 12.5713 24.3426C12.4334 24.5708 12.2109 24.7352 11.9522 24.8C11.6936 24.8648 11.4198 24.8247 11.1906 24.6885C10.9614 24.5522 10.7954 24.3309 10.7288 24.0727L8.70868 15.9923C8.67647 15.8636 8.66993 15.7297 8.68946 15.5985C8.70898 15.4672 8.75418 15.3411 8.82247 15.2273C8.89076 15.1135 8.98079 15.0143 9.08744 14.9354C9.19408 14.8564 9.31524 14.7992 9.44399 14.7671Z" />
    </mask>
    <path fill-rule="evenodd" clip-rule="evenodd"
      d="M31.3632 1.04844C31.531 0.99451 31.71 0.98543 31.8825 1.02211C32.0549 1.05879 32.2147 1.13995 32.346 1.25752L40.6638 8.71069C40.7969 8.82987 40.896 8.98223 40.951 9.15222C41.006 9.32221 41.0149 9.50375 40.9769 9.67832L38.6002 20.5697C38.5608 20.7508 38.4722 20.9176 38.3443 21.0517C38.2164 21.1859 38.054 21.2822 37.875 21.3303L27.1806 24.1968C27.0105 24.2424 26.8314 24.2427 26.6611 24.1976C26.4909 24.1526 26.3353 24.0639 26.2099 23.9402L21.6041 19.395C21.4134 19.2067 21.3053 18.9503 21.3036 18.6823C21.3019 18.4142 21.4067 18.1565 21.595 17.9658C21.7833 17.775 22.0397 17.6669 22.3077 17.6652C22.5758 17.6635 22.8335 17.7684 23.0242 17.9567L27.216 22.0959L36.7579 19.5384L38.878 9.82174L31.4299 3.14935L21.7778 6.25323L20.7496 9.39449C20.7084 9.52057 20.6427 9.63728 20.5563 9.73798C20.47 9.83867 20.3646 9.92137 20.2463 9.98135C20.128 10.0413 19.999 10.0774 19.8667 10.0875C19.7345 10.0977 19.6015 10.0817 19.4754 10.0404C19.3494 9.99917 19.2326 9.93349 19.1319 9.84713C19.0313 9.76077 18.9486 9.65542 18.8886 9.5371C18.8286 9.41879 18.7925 9.28981 18.7824 9.15755C18.7722 9.02528 18.7883 8.89232 18.8295 8.76624L20.0173 5.13511C20.0672 4.98357 20.1522 4.84598 20.2654 4.73362C20.3786 4.62126 20.5169 4.53732 20.6688 4.48868L31.3632 1.04844Z"
      fill="currentColor" />
    <path fill-rule="evenodd" clip-rule="evenodd"
      d="M29.6088 11.1258C29.87 11.1853 30.0969 11.3461 30.2395 11.5728C30.3821 11.7995 30.4289 12.0736 30.3694 12.3348L27.8443 23.4454C27.7827 23.7044 27.6214 23.9286 27.3954 24.0693C27.1694 24.2099 26.8969 24.2557 26.6374 24.1966C26.3778 24.1375 26.1521 23.9783 26.0092 23.7536C25.8664 23.529 25.8181 23.257 25.8747 22.9969L28.3998 11.8864C28.4593 11.6252 28.6201 11.3983 28.8468 11.2557C29.0735 11.1131 29.3477 11.0663 29.6088 11.1258Z"
      fill="currentColor" />
    <path fill-rule="evenodd" clip-rule="evenodd"
      d="M20.5343 4.89176C20.7076 4.68758 20.9548 4.56054 21.2216 4.53856C21.4885 4.51659 21.7532 4.60149 21.9575 4.7746L29.2955 10.9965L36.1891 9.11579C36.4476 9.04534 36.7235 9.08047 36.9561 9.21344C37.1887 9.34641 37.359 9.56634 37.4294 9.82485C37.4999 10.0834 37.4648 10.3593 37.3318 10.5919C37.1988 10.8245 36.9789 10.9947 36.7204 11.0652L29.3137 13.0853C29.1554 13.1285 28.989 13.1325 28.8289 13.0969C28.6687 13.0614 28.5197 12.9874 28.3945 12.8813L20.6515 6.31593C20.5502 6.23014 20.4669 6.12524 20.4062 6.00723C20.3455 5.88921 20.3087 5.76039 20.2978 5.62813C20.2869 5.49587 20.3022 5.36276 20.3428 5.23641C20.3834 5.11006 20.4485 4.99295 20.5343 4.89176ZM7.4077 6.33007C7.53501 6.20834 7.69195 6.12202 7.86293 6.07967C8.03391 6.03732 8.21299 6.04043 8.3824 6.08867L16.7355 8.4734C16.9054 8.52175 17.0596 8.61379 17.1827 8.74033C17.3059 8.86687 17.3937 9.02349 17.4375 9.19458L19.7576 18.2568C19.8039 18.4373 19.7993 18.6271 19.7444 18.8052C19.6895 18.9832 19.5864 19.1427 19.4465 19.2658L12.9509 24.9887C12.8239 25.1008 12.6707 25.1792 12.5055 25.2166C12.3403 25.254 12.1684 25.2493 12.0055 25.2029L3.65132 22.8171C3.48411 22.7693 3.33209 22.679 3.20999 22.5551C3.08789 22.4313 2.99985 22.278 2.95439 22.1101L0.633293 13.5247C0.585962 13.3493 0.586782 13.1645 0.635664 12.9896C0.684546 12.8147 0.779682 12.6562 0.911057 12.5308L7.4077 6.33007ZM8.38442 8.19059L2.73925 13.5772L4.75329 21.0314L12.0277 23.108L17.6466 18.1588L15.6235 10.2572L8.38442 8.19059Z"
      fill="currentColor" />
    <path fill-rule="evenodd" clip-rule="evenodd"
      d="M14.4427 10.9923C14.632 11.1817 14.7384 11.4385 14.7384 11.7064C14.7384 11.9742 14.632 12.2311 14.4427 12.4205L10.4025 16.4607C10.276 16.587 10.1183 16.6774 9.9455 16.7228C9.77267 16.7683 9.59088 16.767 9.41868 16.7192L1.33827 14.4749C1.20923 14.4406 1.08828 14.3809 0.982429 14.2995C0.876574 14.2181 0.787914 14.1165 0.721577 14.0006C0.655239 13.8847 0.612542 13.7568 0.595956 13.6243C0.57937 13.4918 0.589224 13.3573 0.624947 13.2287C0.660671 13.1 0.721556 12.9797 0.804078 12.8747C0.886601 12.7697 0.989123 12.6821 1.10571 12.617C1.22231 12.5519 1.35065 12.5106 1.48332 12.4954C1.616 12.4802 1.75036 12.4915 1.87865 12.5285L9.39141 14.6153L13.0145 10.9923C13.2039 10.8029 13.4607 10.6965 13.7286 10.6965C13.9964 10.6965 14.2533 10.8029 14.4427 10.9923Z"
      fill="currentColor" />
    <path fill-rule="evenodd" clip-rule="evenodd"
      d="M9.44399 14.7671C9.7037 14.7023 9.97849 14.7433 10.208 14.8809C10.4376 15.0186 10.6031 15.2418 10.6682 15.5014L12.6883 23.5818C12.7512 23.8409 12.7092 24.1144 12.5713 24.3426C12.4334 24.5708 12.2109 24.7352 11.9522 24.8C11.6936 24.8648 11.4198 24.8247 11.1906 24.6885C10.9614 24.5522 10.7954 24.3309 10.7288 24.0727L8.70868 15.9923C8.67647 15.8636 8.66993 15.7297 8.68946 15.5985C8.70898 15.4672 8.75418 15.3411 8.82247 15.2273C8.89076 15.1135 8.98079 15.0143 9.08744 14.9354C9.19408 14.8564 9.31524 14.7992 9.44399 14.7671Z"
      fill="currentColor" />
    <path fill-rule="evenodd" clip-rule="evenodd"
      d="M31.3632 1.04844C31.531 0.99451 31.71 0.98543 31.8825 1.02211C32.0549 1.05879 32.2147 1.13995 32.346 1.25752L40.6638 8.71069C40.7969 8.82987 40.896 8.98223 40.951 9.15222C41.006 9.32221 41.0149 9.50375 40.9769 9.67832L38.6002 20.5697C38.5608 20.7508 38.4722 20.9176 38.3443 21.0517C38.2164 21.1859 38.054 21.2822 37.875 21.3303L27.1806 24.1968C27.0105 24.2424 26.8314 24.2427 26.6611 24.1976C26.4909 24.1526 26.3353 24.0639 26.2099 23.9402L21.6041 19.395C21.4134 19.2067 21.3053 18.9503 21.3036 18.6823C21.3019 18.4142 21.4067 18.1565 21.595 17.9658C21.7833 17.775 22.0397 17.6669 22.3077 17.6652C22.5758 17.6635 22.8335 17.7684 23.0242 17.9567L27.216 22.0959L36.7579 19.5384L38.878 9.82174L31.4299 3.14935L21.7778 6.25323L20.7496 9.39449C20.7084 9.52057 20.6427 9.63728 20.5563 9.73798C20.47 9.83867 20.3646 9.92137 20.2463 9.98135C20.128 10.0413 19.999 10.0774 19.8667 10.0875C19.7345 10.0977 19.6015 10.0817 19.4754 10.0404C19.3494 9.99917 19.2326 9.93349 19.1319 9.84713C19.0313 9.76077 18.9486 9.65542 18.8886 9.5371C18.8286 9.41879 18.7925 9.28981 18.7824 9.15755C18.7722 9.02528 18.7883 8.89232 18.8295 8.76624L20.0173 5.13511C20.0672 4.98357 20.1522 4.84598 20.2654 4.73362C20.3786 4.62126 20.5169 4.53732 20.6688 4.48868L31.3632 1.04844Z"
      stroke="currentColor" stroke-width="0.808043" mask="url(#path-1-outside-1_4970_6669)" />
    <path fill-rule="evenodd" clip-rule="evenodd"
      d="M29.6088 11.1258C29.87 11.1853 30.0969 11.3461 30.2395 11.5728C30.3821 11.7995 30.4289 12.0736 30.3694 12.3348L27.8443 23.4454C27.7827 23.7044 27.6214 23.9286 27.3954 24.0693C27.1694 24.2099 26.8969 24.2557 26.6374 24.1966C26.3778 24.1375 26.1521 23.9783 26.0092 23.7536C25.8664 23.529 25.8181 23.257 25.8747 22.9969L28.3998 11.8864C28.4593 11.6252 28.6201 11.3983 28.8468 11.2557C29.0735 11.1131 29.3477 11.0663 29.6088 11.1258Z"
      stroke="currentColor" stroke-width="0.808043" mask="url(#path-1-outside-1_4970_6669)" />
    <path fill-rule="evenodd" clip-rule="evenodd"
      d="M20.5343 4.89176C20.7076 4.68758 20.9548 4.56054 21.2216 4.53856C21.4885 4.51659 21.7532 4.60149 21.9575 4.7746L29.2955 10.9965L36.1891 9.11579C36.4476 9.04534 36.7235 9.08047 36.9561 9.21344C37.1887 9.34641 37.359 9.56634 37.4294 9.82485C37.4999 10.0834 37.4648 10.3593 37.3318 10.5919C37.1988 10.8245 36.9789 10.9947 36.7204 11.0652L29.3137 13.0853C29.1554 13.1285 28.989 13.1325 28.8289 13.0969C28.6687 13.0614 28.5197 12.9874 28.3945 12.8813L20.6515 6.31593C20.5502 6.23014 20.4669 6.12524 20.4062 6.00723C20.3455 5.88921 20.3087 5.76039 20.2978 5.62813C20.2869 5.49587 20.3022 5.36276 20.3428 5.23641C20.3834 5.11006 20.4485 4.99295 20.5343 4.89176ZM7.4077 6.33007C7.53501 6.20834 7.69195 6.12202 7.86293 6.07967C8.03391 6.03732 8.21299 6.04043 8.3824 6.08867L16.7355 8.4734C16.9054 8.52175 17.0596 8.61379 17.1827 8.74033C17.3059 8.86687 17.3937 9.02349 17.4375 9.19458L19.7576 18.2568C19.8039 18.4373 19.7993 18.6271 19.7444 18.8052C19.6895 18.9832 19.5864 19.1427 19.4465 19.2658L12.9509 24.9887C12.8239 25.1008 12.6707 25.1792 12.5055 25.2166C12.3403 25.254 12.1684 25.2493 12.0055 25.2029L3.65132 22.8171C3.48411 22.7693 3.33209 22.679 3.20999 22.5551C3.08789 22.4313 2.99985 22.278 2.95439 22.1101L0.633293 13.5247C0.585962 13.3493 0.586782 13.1645 0.635664 12.9896C0.684546 12.8147 0.779682 12.6562 0.911057 12.5308L7.4077 6.33007ZM8.38442 8.19059L2.73925 13.5772L4.75329 21.0314L12.0277 23.108L17.6466 18.1588L15.6235 10.2572L8.38442 8.19059Z"
      stroke="currentColor" stroke-width="0.808043" mask="url(#path-1-outside-1_4970_6669)" />
    <path fill-rule="evenodd" clip-rule="evenodd"
      d="M14.4427 10.9923C14.632 11.1817 14.7384 11.4385 14.7384 11.7064C14.7384 11.9742 14.632 12.2311 14.4427 12.4205L10.4025 16.4607C10.276 16.587 10.1183 16.6774 9.9455 16.7228C9.77267 16.7683 9.59088 16.767 9.41868 16.7192L1.33827 14.4749C1.20923 14.4406 1.08828 14.3809 0.982429 14.2995C0.876574 14.2181 0.787914 14.1165 0.721577 14.0006C0.655239 13.8847 0.612542 13.7568 0.595956 13.6243C0.57937 13.4918 0.589224 13.3573 0.624947 13.2287C0.660671 13.1 0.721556 12.9797 0.804078 12.8747C0.886601 12.7697 0.989123 12.6821 1.10571 12.617C1.22231 12.5519 1.35065 12.5106 1.48332 12.4954C1.616 12.4802 1.75036 12.4915 1.87865 12.5285L9.39141 14.6153L13.0145 10.9923C13.2039 10.8029 13.4607 10.6965 13.7286 10.6965C13.9964 10.6965 14.2533 10.8029 14.4427 10.9923Z"
      stroke="currentColor" stroke-width="0.808043" mask="url(#path-1-outside-1_4970_6669)" />
    <path fill-rule="evenodd" clip-rule="evenodd"
      d="M9.44399 14.7671C9.7037 14.7023 9.97849 14.7433 10.208 14.8809C10.4376 15.0186 10.6031 15.2418 10.6682 15.5014L12.6883 23.5818C12.7512 23.8409 12.7092 24.1144 12.5713 24.3426C12.4334 24.5708 12.2109 24.7352 11.9522 24.8C11.6936 24.8648 11.4198 24.8247 11.1906 24.6885C10.9614 24.5522 10.7954 24.3309 10.7288 24.0727L8.70868 15.9923C8.67647 15.8636 8.66993 15.7297 8.68946 15.5985C8.70898 15.4672 8.75418 15.3411 8.82247 15.2273C8.89076 15.1135 8.98079 15.0143 9.08744 14.9354C9.19408 14.8564 9.31524 14.7992 9.44399 14.7671Z"
      stroke="currentColor" stroke-width="0.808043" mask="url(#path-1-outside-1_4970_6669)" />
  </svg>


</template>
