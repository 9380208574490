<template>
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.42115 14.968L16.58 18.226M5.27094 11.7966C4.30258 11.3007 3.56472 10.448 3.213 9.41845C2.86128 8.38891 2.9232 7.263 3.3857 6.27824C3.8482 5.29348 4.67512 4.52686 5.69201 4.1401C6.70891 3.75335 7.83627 3.77669 8.83629 4.2052C9.24793 3.69284 9.75029 3.26054 10.3183 2.92988C10.9153 2.58419 11.5747 2.35967 12.2586 2.26918C12.9425 2.1787 13.6376 2.22404 14.304 2.4026C14.9704 2.58115 15.595 2.88942 16.142 3.30974C16.6891 3.73007 17.1479 4.25419 17.4921 4.85209C17.8186 5.42245 18.0375 6.04801 18.1378 6.69755C19.2181 6.82645 20.2061 7.36992 20.8934 8.21331C21.5807 9.0567 21.9135 10.1341 21.8217 11.2181C21.7298 12.3022 21.2205 13.3082 20.4011 14.024C19.5818 14.7397 18.5164 15.1092 17.4298 15.0546L15.4941 22.279L3.33516 19.021L5.27094 11.7966Z"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
