<template>
  <svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19.7547 7.67843C19.7965 7.45956 19.7734 7.23433 19.6879 7.02658C19.6024 6.81882 19.4577 6.63626 19.2691 6.49819L16.6309 4.52023C16.4359 4.37129 16.1997 4.27563 15.9489 4.24402C15.6982 4.2124 15.4428 4.24611 15.2117 4.34132C13.5787 5.02519 9.74392 6.7659 7.75307 8.79528L7.74926 8.79718L7.74511 8.79604L7.74096 8.7949L7.73715 8.7968L1.94341 15.3841C1.47032 15.922 1.24804 16.6114 1.32546 17.3006C1.40289 17.9898 1.77368 18.6224 2.35627 19.0592L6.74958 22.3531C7.33217 22.7899 8.07884 22.9951 8.82531 22.9236C9.57179 22.8521 10.2569 22.5098 10.73 21.9719L16.5247 15.3835L16.5256 15.3806L16.524 15.378L16.523 15.3768L16.5225 15.3754L16.5227 15.3738L16.5234 15.3725C18.5452 12.8665 19.4492 9.21117 19.7547 7.67843Z"
      stroke="currentColor"
      stroke-width="1.94248"
    />
    <path
      d="M22.4612 5.52906L16.9696 1.41173C16.6663 1.18434 16.2208 1.22701 15.9745 1.50703L15.0826 2.52109C14.8363 2.80112 14.8825 3.21246 15.1858 3.43986L20.6774 7.55719C20.9807 7.78458 21.4262 7.74192 21.6725 7.46189L22.5644 6.44783C22.8107 6.1678 22.7645 5.75646 22.4612 5.52906Z"
      stroke="currentColor"
      stroke-width="1.94248"
    />
    <path
      d="M9.49121 10.9503L4.58579 16.5277"
      stroke="currentColor"
      stroke-width="1.94248"
      stroke-linecap="round"
    />
  </svg>
</template>
