import axios from 'axios'
import FormData from 'form-data'
import { useEnvStore } from '@/stores/envStore'
import { useFetch } from '@/composables/useFetch'

export async function generateImage(dishId: number) {
  const apiUrl = useEnvStore().apiUrl

  const stabilityApiUrl = useEnvStore().stabilityApiUrl
  const stabilityApiKey = useEnvStore().stabilityApiKey

  try {
    axios.defaults.withCredentials = false
    const promptResponse = await axios.get(`${apiUrl}/generate/images/${dishId}/prompt`)
    const prompts = promptResponse.data.message.response
    const formDataGastro = toFormData(prompts.gastro, 'jpeg')
    const formDataBistro = toFormData(prompts.bistro, 'jpeg')

    const [imageGastro, imageBistro] = await Promise.all([
      axios.post(`${stabilityApiUrl}`, formDataGastro, {
        headers: { Authorization: `Bearer ${stabilityApiKey}`, Accept: 'image/*' },
        responseType: 'arraybuffer',
      }),
      axios.post(`${stabilityApiUrl}`, formDataBistro, {
        headers: { Authorization: `Bearer ${stabilityApiKey}`, Accept: 'image/*' },
        responseType: 'arraybuffer',
      }),
    ])

    axios.defaults.withCredentials = false
    // Handle the response, save or process images

    const fileNameGastro = generateFileName(dishId, 'gastro', 'jpeg')
    const fileNameBistro = generateFileName(dishId, 'bistro', 'jpeg')
    const backendFormData = new FormData()
    backendFormData.append(
      'imageGastro',
      new Blob([imageGastro.data], { type: 'image/jpeg' }),
      fileNameGastro
    )
    backendFormData.append(
      'imageBistro',
      new Blob([imageBistro.data], { type: 'image/jpeg' }),
      fileNameBistro
    )
    // backendFormData.append('type', dishType);
    // fs.writeFileSync(fileName, Buffer.from(response.data));

    const { fetchData } = useFetch()
    const { data, error } = await fetchData(
      `${useEnvStore().apiUrl}/dishes/${dishId}/images`,
      'POST',
      backendFormData
    )

    if (error) {
      throw new Error(`Failed to send image to backend: ${error.message}`)
    } else {
      console.log('Image sent to backend successfully:', data)
      return { data, error: null }
    }
  } catch (error) {
    console.error('Failed to generate images:', error)
    return { data: null, error }
    throw new Error('Image generation failed')
  }
  axios.defaults.withCredentials = false
}

function toFormData(prompt, contentType) {
  const formData = new FormData()
  formData.append('prompt', prompt)
  formData.append('output_format', contentType)
  // formData.append('model', aiModel)
  return formData
}

function generateFileName(dishId, type, extension) {
  const date = new Date()
  const formattedDate = date.toISOString().replace(/[-:.]/g, '').slice(0, 15) // Format date as 'YYYYMMDDHHMMSS'
  const fileName = `gstr-img-${type}-${dishId}-${formattedDate}.${extension}`

  return fileName
}
