<script setup lang="ts">
import CreationFilter from '@/components/Menus/Filters/CreationFilter.vue'
import { useI18n } from 'vue-i18n'
import { type MessageSchema } from '@/i18n'
import SelectWithoutIcon from '@/components/BaseComponents/SelectWithoutIcon.vue'
import { ref, watchEffect, type Ref } from 'vue'
import { useDishesStore } from '@/stores/dishesStore'

const { t } = useI18n<{ message: MessageSchema }>({ useScope: 'global' })
const dishesStore = ref(useDishesStore());

const productPreferencesList = ref(['local', 'fresh', 'season', 'bio'])
</script>

<template>
  <CreationFilter>
    <template #title>{{ t('creationFilters.productPreferences') }}</template>
    <template #content>
      <div class="flex flex-wrap justify-center w-full gap-2">
        <SelectWithoutIcon
          v-for="productPreference in productPreferencesList"
          :key="productPreference"
          :selected="dishesStore.generationParams.dish_product_preferences.includes(productPreference)"
          @handle-select="
            (selected) =>
              selected
                ? dishesStore.generationParams.dish_product_preferences.push(productPreference)
                : (dishesStore.generationParams.dish_product_preferences = dishesStore.generationParams.dish_product_preferences.filter(
                    (i) => i !== productPreference
                  ))
          "
        >
          <template #label>
            {{ t(`creationFilters.productPreferencesList.${productPreference}`) }}
          </template>
        </SelectWithoutIcon>
      </div>
    </template>
  </CreationFilter>
</template>
