<template>
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 5.228L18 10.628V20H15.6V12.8H8.4V20H6V10.628L12 5.228ZM12 2L0 12.8H3.6V22.4H10.8V15.2H13.2V22.4H20.4V12.8H24L12 2Z"
      fill="currentColor"
    />
  </svg>
</template>
