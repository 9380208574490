<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import type { MessageSchema } from '@/i18n'
import Button from 'primevue/button'
import ButtonShare from '@/assets/icons/Buttons/ButtonShare.vue'
import html2canvas from 'html2canvas-pro'
import ButtonWithIconWrapper from '@/components/BaseComponents/ButtonWithIconWrapper.vue'

const { t, locale } = useI18n<{ message: MessageSchema }>({ useScope: 'global' })


const shareMenu = () => {
  const element = document.getElementById('share-item');
  if (!element) {
    console.error('Element not found');
    return;
  }

  html2canvas(element, {
    scale: 1,  // Adjusts the resolution of the captured image
    logging: true, // Enables logging for debugging purposes
    useCORS: true // Helps manage external images if they're not being rendered
  }).then((canvas) => {
    const link = document.createElement('a');
    link.download = 'menu_image.png';
    link.href = canvas.toDataURL('image/png');
    link.click();
  }).catch(error => {
    console.error('Error capturing the canvas:', error);
  });
};
</script>

<template>
  <main>
    <div>
      <h1 class="text-2xl mb-4 text-center">{{ t('socialNetworks.share') }}</h1>
    </div>
    <div class="shadow-md rounded-md">
      <div id="share-item" class="w-full overflow-hidden">
        <slot></slot>
      </div>
    </div>
    <div class="mt-8 w-full">
      <Button class="col-span-1 w-full" @click="shareMenu()">
        <ButtonWithIconWrapper>
          <template #icon>
            <ButtonShare />
          </template>
          <template #label>
            {{ t('socialNetworks.share') }}
          </template>
        </ButtonWithIconWrapper>
      </Button>
    </div>
  </main>
</template>
