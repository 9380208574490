<!-- MenuView.vue -->
<script setup lang="ts">
import { usePlanningStore } from '@/stores/planningStore';
import type { PlannedMenu } from '@/types/planning';
import { computed, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';

// Define props for the component
const props = defineProps<{
  date: Date
  plannedMenu: PlannedMenu
}>();

const route = useRoute()
const router = useRouter()
const planningStore = ref(usePlanningStore());

// Define a list of dishes for the current day
const dishes = ref<string[]>([]);

// Add a new dish to the list
const menuCourses = ref(props.plannedMenu.menu_courses);

function goToMenuDetails() {
  if (planningStore.value.clipboardDishToPlan) {
    // You're in the modal, so display the menu details in the sidebar
    planningStore.value.selectedPlannedMenu = props.plannedMenu;
    // Trigger any sidebar opening logic if needed
  } else {
    // Not in modal mode, proceed with the usual navigation
    router.push(`/planning/planned-menu/${props.plannedMenu.id}`);
  }
}

const sortedAndFilledCourses = computed(() => {
  const courseTypes = [1, 2, 3]; // Represents Entrée, Plat principal, Dessert
  const courses = props.plannedMenu.menu_courses.slice().sort((a, b) => a.order - b.order);

  // Create a new array that includes all three types, filling in missing types with placeholders
  return courseTypes.map(type => {
    const foundCourse = courses.find(course => course.order === type);
    return foundCourse ? foundCourse : { id: `missing-${type}`, name: '', dish: { name: '***' }, order: type };
  });
});
</script>

<template>
  <!-- Right side for the card -->
  <div @click="goToMenuDetails"
    class="cursor-pointer flex items-start flex-grow relative bg-primary-200 py-8 px-4 rounded-xl shadow-lg my-4 mx-2 w-52 h-56">
    <div
      class="absolute -top-4 left-1/2 -translate-x-1/2 bg-primary-600 text-white py-2 px-4 font-bold rounded-md whitespace-nowrap overflow-hidden text-ellipsis max-w-36 text-center text-xs truncate">
      {{props.plannedMenu.name}}
    </div>
    <div class="flex flex-col gap-3 justify-around text-sm">
      <ul class="list-none flex flex-col">
        <li v-for="menuCourse in sortedAndFilledCourses" :key="menuCourse.id" class="relative w-44">
          <div :class="{'justify-center': menuCourse.dish?.name === '***' || menuCourse?.name === '***'}"
            class="h-12 text-ellipsis flex items-center">
            <p class="line-clamp-3">{{ menuCourse.dish?.name ?? menuCourse.name }}</p>
          </div>
          <div v-if=" menuCourse.order !==3" class="h-0.5 w-6 bg-primary-500 rounded-full mx-auto my-2">
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<style scoped>
</style>