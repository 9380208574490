<template>
  <div class="flex flex-row items-center justify-center h-full gap-3">
    <span>
      <slot name="label"></slot>
    </span>
    <span class="h-2/3">
      <slot name="icon"></slot>
    </span>
  </div>
</template>
