<script setup lang="ts">
import { computed } from 'vue'

const props = defineProps<{
  selected: boolean
}>()
defineEmits<{
  (e: 'handleSelect', selected: boolean): void
}>()

const isSelected = computed(() => props.selected)
</script>

<template>
  <div
    class="px-4 py-2 border-2 rounded-full cursor-pointer border-primary-300"
    :class="{ 'border-primary-800 bg-primary-100': isSelected }"
    @click="$emit('handleSelect', !isSelected)"
  >
    <h4>
      <slot name="label"></slot>
    </h4>
  </div>
</template>
