<template>
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.5176 14.0534C9.98734 14.3685 9.07595 15.3587 9.07595 16.7651C9.07595 18.2729 10.3249 19.488 11.8776 19.488C14.1842 19.488 16.052 17.6203 16.052 15.3136C16.052 14.1097 15.8833 12.9283 15.5345 11.8031C14.6456 13.007 13.0591 13.7384 11.5176 14.0534ZM13.6892 0C13.6892 0 14.5218 2.98172 14.5218 5.40084C14.5218 7.71871 13.0028 9.59775 10.685 9.59775C8.35584 9.59775 6.60056 7.71871 6.60056 5.40084L6.63432 4.99578C4.36146 7.6962 3 11.1955 3 14.9986C3 19.9719 7.02813 24 12.0014 24C16.9747 24 21.0028 19.9719 21.0028 14.9986C21.0028 8.9339 18.0886 3.5218 13.6892 0ZM12.0014 21.7496C8.27707 21.7496 5.25035 18.7229 5.25035 14.9986C5.25035 13.2771 5.5879 11.5781 6.218 10.0141C7.35443 11.1505 8.92968 11.8481 10.685 11.8481C13.6779 11.8481 16.0295 9.78903 16.6259 6.86357C18.0098 9.33896 18.7525 12.1181 18.7525 14.9986C18.7525 18.7229 15.7257 21.7496 12.0014 21.7496Z"
      fill="currentColor"
    />
  </svg>
</template>
