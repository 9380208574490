<script setup lang="ts">
import AvatarIngredient from '@/assets/icons/Avatars/AvatarIngredient.vue';
import CustomTitleDishIcon from '@/assets/icons/Home/CustomTitleDishIcon.vue';
import FastDishCreationIcon from '@/assets/icons/Home/FastDishCreationIcon.vue';
import MainProductDishIcon from '@/assets/icons/Home/MainProductDishIcon.vue';
import MyFavouriteIcon from '@/assets/icons/Home/MyFavouriteIcon.vue';
import MyHistoryIcon from '@/assets/icons/Home/MyHistoryIcon.vue';
import ScanDishIcon from '@/assets/icons/Home/ScanDishIcon.vue';
import ShortcutButtonHomePage from '@/components/Home/ShortcutButtonHomePage.vue'
import { useAuth } from '@/composables/useAuth';
import type { MessageSchema } from '@/i18n';
import { useEnvStore } from '@/stores/envStore';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n<{ message: MessageSchema }>({ useScope: 'global' })

interface shortCutOptions {
  text: string;
  navigate: string;
  icon?: any
}

const dishCreationOptions: shortCutOptions[] = [
  { text: t('homeView.create.quickCreation'), navigate: '/menu/create?type=quick', icon: FastDishCreationIcon },
  { text: t('homeView.create.mainProductCreation'), navigate: '/menu/create?type=with_main_product', icon: MainProductDishIcon },
  { text: t('homeView.create.customTitleCreation'), navigate: '/menu/create?type=from_title', icon: CustomTitleDishIcon },
];

const myDishesOptions: shortCutOptions[] = [
  { text: t('homeView.myRecipes.favourites'), navigate: '/profile/favorites', icon: MyFavouriteIcon },
  { text: t('homeView.myRecipes.history'), navigate: '/profile/generation-history', icon: MyHistoryIcon },
  { text: t('homeView.myRecipes.scan'), navigate: '/profile/my-scans', icon: ScanDishIcon },
];

const user = ref(useAuth().user);

</script>

<template>
  <main>
    <div class="flex flex-col justify-between">
      <div>
        <h1 class="text-center my-2" v-if="user">Bonjour, {{ user.first_name }} ! </h1>
        <h1 class="text-center my-2" v-else>Bonjour ! </h1>
        <div class="text-center mt-8">
          <img src="@/assets/images/avatar-rounded.webp" alt="Welcome image" class="w-1/3 max-w-32 min-w-24 mx-auto">
        </div>
      </div>
      <div>
        <div class="my-8">
          <h3 class="my-4">Créer des recettes</h3>
          <div class="flex flex-row items-start gap-3 content-between justify-center ">
            <ShortcutButtonHomePage v-for="dishCreation in dishCreationOptions" :key="dishCreation.text"
              :icon="dishCreation.icon" :text="dishCreation.text" :navigate="dishCreation.navigate" />
  
          </div>
          <!-- <div class="flex flex-row gap-4 justify-center">
          <ShortcutButtonHomePage :icon="dishCreation.icon" text="rapide" direction="test" />
          <ShortcutButtonHomePage :icon="dishCreation.icon" text="rapide" direction="test" />
        </div> -->
        </div>
        <div class="my-8">
          <h3 class="my-4">Mes recettes</h3>
          <div class="flex flex-row items-start gap-3 content-between justify-center">
            <ShortcutButtonHomePage v-for="myDishes in myDishesOptions" :key="myDishes.text" :icon="myDishes.icon"
              :text="myDishes.text" :navigate="myDishes.navigate" />
          </div>
        </div>
      </div>
    </div>
  </main>
</template>
