<template>
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.6364 2.18182H19.5455V0H17.3636V2.18182H6.45455V0H4.27273V2.18182H3.18182C1.98182 2.18182 1 3.16364 1 4.36364V21.8182C1 23.0182 1.98182 24 3.18182 24H20.6364C21.8364 24 22.8182 23.0182 22.8182 21.8182V4.36364C22.8182 3.16364 21.8364 2.18182 20.6364 2.18182ZM20.6364 21.8182H3.18182V9.81818H20.6364V21.8182ZM20.6364 7.63636H3.18182V4.36364H20.6364V7.63636Z"
      fill="currentColor"
    />
  </svg>
</template>
