<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import type { MessageSchema } from '@/i18n/index.js'
import InputText from 'primevue/inputtext'
import { ref } from 'vue'
import { useToast } from 'primevue/usetoast'
import type { User } from '@/types/api.js'
import Button from 'primevue/button'
import Dropdown from 'primevue/dropdown'
import { useProfileProfile } from '@/composables/useProfileProfile.js'

const props = defineProps<{
  user: User
}>()
const emit = defineEmits<{
  (e: 'updateUser', user: User): void
}>()

const { t } = useI18n<{ message: MessageSchema }>({ useScope: 'global' })

const toast = useToast()

const { updateUserProperty } = useProfileProfile(toast)

const languagesList = ref(['french', 'english', 'german', 'italian'])

const firstName = ref(props.user.first_name)
const lastName = ref(props.user.last_name)
const email = ref(props.user.email)
const selectedLanguage = ref(languagesList.value[0])

async function updateProperty(propertyName: string, value: string | number) {
  const updateResponse = await updateUserProperty(propertyName, value)
  if (updateResponse !== null) emit('updateUser', updateResponse)
}
</script>

<template>
  <div class="flex flex-col gap-6">
    <div>
      <h3>{{ t('profile.profile.profileTab.personnalData.title') }}</h3>
      <h4 class="mt-2">{{ t('profile.profile.profileTab.personnalData.firstName') }}</h4>
      <InputText
        class="w-full"
        v-model="firstName"
        @blur="updateProperty('first_name', firstName)"
      />
      <h4 class="mt-2">{{ t('profile.profile.profileTab.personnalData.lastName') }}</h4>
      <InputText class="w-full" v-model="lastName" @blur="updateProperty('last_name', lastName)" />
    </div>

    <div>
      <h3>{{ t('profile.profile.profileTab.language.title') }}</h3>
      <Dropdown class="w-1/2" :options="languagesList" v-model="selectedLanguage" disabled>
        <template #value="slotProps">
          {{ t('profile.profile.profileTab.language.' + slotProps.value) }}
        </template>
        <template #option="slotProps">
          {{ t('profile.profile.profileTab.language.' + slotProps.option) }}
        </template>
      </Dropdown>
    </div>

    <div>
      <h3>{{ t('profile.profile.profileTab.passwordAndEmail.title') }}</h3>
      <h4 class="mt-2">{{ t('profile.profile.profileTab.passwordAndEmail.email') }}</h4>
      <p>{{ email }}</p>
      <!-- <InputText class="w-full" v-model="email" @blur="updateProperty('email', email)" disabled /> -->
      <h4 class="mt-2">{{ t('profile.profile.profileTab.passwordAndEmail.password') }}</h4>
      <Button class="mt-1" disabled>
        {{ t('profile.profile.profileTab.passwordAndEmail.changePassword') }}
      </Button>
    </div>
  </div>
</template>
