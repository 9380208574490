<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { type MessageSchema } from '@/i18n'
import Button from 'primevue/button'
import type { UserLoginRequest } from '@/types/api'
import InputText from 'primevue/inputtext'
import Password from 'primevue/password'
import { ref } from 'vue'
import Checkbox from 'primevue/checkbox'
import Divider from 'primevue/divider'
import InlineLink from '@/components/InlineLink.vue'
import InlineMessage from '@/components/InlineMessage.vue'
import { useAuth } from '@/composables/useAuth.js'

const password = ref('')
const rememberMe = ref(false)

const { login, errors, isFetching } = useAuth()
errors.value = {}

const { t } = useI18n<{ message: MessageSchema }>({ useScope: 'global' })

const userForm: UserLoginRequest = {
  email: '',
  password: '',
  remember: false,
}

function hasError(fieldName: string) {
  return !!errors.value[fieldName] && errors.value[fieldName].length > 0
}

</script>

<template>
  <main>
    <form @submit.prevent="login(userForm, rememberMe, password)">
      <div class="flex flex-col gap-4">
        <div class="flex flex-col gap-2">
          <label for="email">{{ t('auth.user.form.label.mail') }}</label>
          <div>
            <InputText type="email" id="email" class="w-full" v-model="userForm.email" :invalid="hasError('email')"
              :placeholder="t('auth.user.form.placeholder.mail')" />
          </div>
          <InlineMessage severity="error" v-if="hasError('email')">
            {{ errors.email[0] }}
          </InlineMessage>
        </div>

        <div class="flex flex-col gap-2">
          <label for="password">{{ t('auth.user.form.label.password') }}</label>
          <div>
            <Password id="password" v-model="password" toggleMask :feedback="false" :invalid="hasError('password')"
              class="w-full" />
          </div>
          <InlineMessage severity="error" v-if="hasError('password')">
            {{ errors.password[0] }}
          </InlineMessage>
          <InlineLink destination="/forgot-password" hierarchy="secondary">
            {{ t('auth.login.forgotPassword') }}
          </InlineLink>
        </div>

        <div class="flex flex-row items-center gap-2">
          <Checkbox inputId="rememberMe" v-model="rememberMe" :binary="true" />
          <label for="rememberMe">{{ t('auth.user.form.label.remember_me') }}</label>
        </div>

        <Button :loading="isFetching" :disabled="isFetching" :label="t('auth.login.login')" class="w-full mt-8"
          size="large" type="submit" />
      </div>
    </form>
    <Divider />
    <p>
      Vous n'avez pas encore de compte ? <InlineLink destination="/register">S'inscrire</InlineLink>
    </p>
  </main>
</template>
