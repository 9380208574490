import { createI18n } from 'vue-i18n'
import frCH from '@/i18n/locales/frCH.json'

export type MessageSchema = typeof frCH

export default createI18n<[MessageSchema], 'fr-CH'>({
  locale: import.meta.env.VITE_DEFAULT_LOCALE,
  fallbackLocale: import.meta.env.VITE_FALLBACK_LOCALE,
  legacy: false,
  globalInjection: true,
  messages: {
    'fr-CH': frCH,
  },
})
