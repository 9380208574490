<script setup lang="ts">
// import SupplierIcon from '@/assets/icons/Profile/SupplierIcon.vue'
// import FiltersIcon from '@/assets/icons/Profile/FiltersIcon.vue'
// import ShoppingCartIcon from '@/assets/icons/Profile/ShoppingCartIcon.vue'
// import StatisticsIcon from '@/assets/icons/Profile/StatisticsIcon.vue'
import HistoryIcon from '@/assets/icons/Profile/HistoryIcon.vue'
// import CreditCardIcon from '@/assets/icons/Profile/CreditCardIcon.vue'
// import TeamIcon from '@/assets/icons/Profile/TeamIcon.vue'
import { computed, markRaw } from 'vue'
import ProfileMenuItem from '@/components/Profile/ProfileMenuItem.vue'
import HeartEmpty from '@/assets/icons/2States/HeartEmpty.vue'
import Button from 'primevue/button'
import { useAuth } from '@/composables/useAuth'
import ScanIcon from '@/assets/icons/Profile/ScanIcon.vue'
import { useI18n } from 'vue-i18n'
import type { MessageSchema } from '@/i18n/index.js'
import { useRouter } from 'vue-router'

const { t } = useI18n<{ message: MessageSchema }>({ useScope: 'global' })

const { logout, isFetching, user } = useAuth()
const router = useRouter()

const userInfos = computed(() => {
  if (user.value) {
    return {
      fullName: `${user.value.first_name} ${user.value.last_name}`,
      restaurantName: user.value.restaurants[0].name,
      restaurationType: user.value.restaurants[0].restauration_type,
    }
  }
  return {
    fullName: '',
    restaurantName: '',
    restaurationType: '',
  }
})

const links = [
  {
    name: 'Historique',
    icon: markRaw(HistoryIcon),
    url: '/profile/generation-history',
  },
  // {
  //   name: 'Abonnement',
  //   icon: markRaw(CreditCardIcon),
  //   url: '/profile/subscription',
  // },
  // {
  //   name: 'Mes fournisseurs',
  //   icon: markRaw(SupplierIcon),
  //   url: '/profile/suppliers',
  // },
  // {
  //   name: 'Mes commandes',
  //   icon: markRaw(ShoppingCartIcon),
  //   url: '/profile/orders',
  // },
  {
    name: 'Menus favoris',
    icon: markRaw(HeartEmpty),
    url: '/profile/favorites',
  },
  {
    name: 'Mes scans',
    icon: markRaw(ScanIcon),
    url: '/profile/my-scans',
  },
  // {
  //   name: 'Mes filtres',
  //   icon: markRaw(FiltersIcon),
  //   url: '/profile/filters',
  // },
  // {
  //   name: 'Mes statistiques',
  //   icon: markRaw(StatisticsIcon),
  //   url: '/profile/statistics',
  // },
  // {
  //   name: 'Mon équipe',
  //   icon: markRaw(TeamIcon),
  //   url: '/profile/team',
  // },
]
</script>

<template>
  <main>
    <!-- ProfileCard -->
    <div
      class="flex flex-row items-center justify-between mb-8"
      @click="router.push({ name: 'my-profile' })"
    >
      <div>
        <h1 class="mb-2">{{ userInfos.fullName }}</h1>
        <p class="font-titles">{{ userInfos.restaurantName }}</p>
        <p class="text-sm font-titles">{{ t(`restaurationType.${userInfos.restaurationType}`) }}</p>
      </div>
      <Button rounded link icon="pi pi-chevron-right" class="text-xl" />
    </div>
    <!-- ListItem Abonnement -->
    <!-- Statistics -->
    <ProfileMenuItem
      v-for="(link, index) in links"
      :key="link.name"
      :name="link.name"
      :icon="link.icon"
      :url="link.url"
      :index="index"
    />
    <Button :label="t('auth.logout')" class="w-full mt-8" @click="logout" :loading="isFetching" />
  </main>
</template>
