import { defineStore } from 'pinia'
import { ref, type Ref } from 'vue'
import type { RouteMeta } from 'vue-router'

export const useLayoutStore = defineStore('layout', () => {
  const pageMeta: Ref<RouteMeta> = ref({
    headerConfig: {
      headerLabel: 'home',
      hide: true,
      showBack: false,
      showProfile: false,
    },
    footerConfig: {
      hide: true,
    },
    securityConfig: {
      requireAuthentication: false,
      rolesAllowed: ['common', 'admin'],
    },
    containerMargins: true,
  })

  return { pageMeta }
})
