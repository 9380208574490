<script setup lang="ts">
import type { MessageSchema } from '@/i18n'
import type { Ingredient } from '@/types/dish'
import { useI18n } from 'vue-i18n'
import { computed } from 'vue'

const { t, te } = useI18n<{ message: MessageSchema }>({ useScope: 'global' })

const props = withDefaults(
  defineProps<{
    ingredients: Ingredient[]
    numberOfPeople?: number
    gradientFrom: string
    listHeight: string
  }>(),
  {
    numberOfPeople: 1,
  }
)

function calculatePricePerPerson(
  ingredient: Ingredient,
  numberOfPeople: number
): string {
  if (!ingredient.price) return ''

  const pricePerServing = ingredient.price
  const totalPrice = pricePerServing * numberOfPeople

  return totalPrice.toFixed(2)
}

const convertUnits = (
  quantity: number | undefined,
  unit: string
): { quantity: number | undefined; unit: string } => {
  if (!quantity) return { quantity: quantity, unit: unit }

  const quantityAdjusted = quantity * props.numberOfPeople
  if (unit === 'g' && quantityAdjusted >= 1000) {
    return { quantity: quantity / 1000, unit: 'kg' }
  } else if (unit === 'kg' && quantityAdjusted < 1) {
    return { quantity: quantity * 1000, unit: 'g' }
  }
  if (unit === 'ml' && quantityAdjusted >= 1000) {
    return { quantity: quantity / 1000, unit: 'l' }
  } else if (unit === 'ml' && quantityAdjusted >= 100) {
    return { quantity: quantity / 100, unit: 'dl' }
  } else if (unit === 'l' && quantityAdjusted < 0.1) {
    return { quantity: quantity * 1000, unit: 'ml' }
  } else if (unit === 'l' && quantityAdjusted < 1) {
    return { quantity: quantity * 10, unit: 'dl' }
  }
  return { quantity, unit }
}

const formattedIngredients = computed(() =>
  props.ingredients.map((ingredient): Ingredient => {
    const { quantity, unit } = convertUnits(ingredient.quantity, ingredient.unit)
    const translationKey = `ingredients.unit.symbol.${unit}`
    const translatedUnit = te(translationKey) ? t(translationKey) : unit // Utilisez te pour vérifier la clé

    return {
      ...ingredient,
      quantity: quantity ? quantity * props.numberOfPeople : undefined,
      unit: translatedUnit,
    }
  })
)
</script>

<template>
  <div class="relative">
    <div class="overflow-y-scroll" :class="listHeight">
      <div
        class="grid grid-cols-6 gap-4 text-sm text-primary-950 last:mb-4"
        v-for="ingredient in formattedIngredients"
        :key="ingredient.name"
      >
        <div class="col-span-2">
          <div class="grid grid-cols-[3fr,1fr] sm:grid-cols-2 gap-3">
            <span class="col-span-1">
              {{ ingredient.quantity ? ingredient.quantity.toFixed(2) : '' }}
            </span>
            <span class="col-span-1">{{ ingredient.unit }}</span>
          </div>
        </div>
        <div class="col-span-3">
          <span>{{ ingredient.name }}</span>
        </div>
        <div class="col-span-1">
          <span>
            {{ calculatePricePerPerson(ingredient, props.numberOfPeople) }}
          </span>
        </div>
      </div>
    </div>
    <div class="absolute w-full h-8 -bottom-1 bg-gradient-to-t" :class="gradientFrom"></div>
  </div>
</template>
