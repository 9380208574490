ata
<script setup lang="ts">
import { onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import type { MessageSchema } from '@/i18n'
import { useFetch } from '@/composables/useFetch'
import { useEnvStore } from '@/stores/envStore'
import { useToast } from 'primevue/usetoast'
import Skeleton from 'primevue/skeleton'
import { watch } from 'vue'

const { t } = useI18n<{ message: MessageSchema }>({ useScope: 'global' })

const toast = useToast()
const envStore = useEnvStore()
const isLoading = ref(false)
const isError = ref(false)
const { fetchData } = useFetch()
const imgSrc = ref('')

const props = withDefaults(
  defineProps<{
    dishId: number
    type: 'user' | 'gastro' | 'bistro' | undefined
    alt: string
    selected?: boolean
    size?: 'full' | 'small'
  }>(),
  {
    selected: false,
    size: 'small',
  }
)

defineEmits<{
  (e: 'handleSelect'): void
}>()

defineExpose({
  getImage,
})

onMounted(() => {
  getImage()
})

async function getImage() {
  if (!props.type) return

  isLoading.value = true
  isError.value = false

  const { data, error } = await fetchData(
    `${envStore.apiUrl}/dishes/${props.dishId}/images/${props.type}`
  )

  isLoading.value = false

  if (error) {
    console.error('Error fetching image:', error)
    toast.add({
      severity: 'error',
      summary: t('common.error'),
      detail: t('menuImage.gettingImageError'),
      life: 3000,
    })
    isError.value = true
  } else if (data && data.image) {
    imgSrc.value = data.image
  }
}

watch(
  () => props.type,
  (newUrl) => {
    if (newUrl !== undefined) {
      getImage()
    }
  }
)
</script>

<template>
  <div
    class="relative flex justify-center m-1"
    :class="size === 'small' ? 'w-60 h-60' : 'w-auto h-auto'"
  >
    <div v-if="imgSrc" class="relative">
      <div
        class="absolute z-50 flex items-center justify-center rounded-full w-7 h-7 bg-accent top-4 right-4"
        v-if="selected"
      >
        <i class="text-white z-60 pi pi-check"></i>
      </div>
      <img
        :src="imgSrc"
        :alt
        class="object-cover w-full h-full rounded-3xl"
        :class="[
          selected ? 'outline outline-4 outline-accent' : '',
          size === 'full' ? 'max-h-[80vh] w-auto' : '',
        ]"
        @click="!selected ? $emit('handleSelect') : {}"
      />
    </div>
    <div class="min-h-60 min-w-60 max-h-full max-w-full" v-else-if="isLoading" >
      <Skeleton size="100%" borderRadius="2rem"/>
    </div>
    <!-- <p v-else-if="isLoading">{{ t('menuImage.loadingImage') }}</p> -->
    <!-- <p v-else-if="isError">{{ t('menuImage.imageLoadError') }}</p> -->
  </div>
</template>

<style scoped>
.skeleton-container {
  width: 100%;
  /* largeur totale du conteneur */
  height: 0;
  /* éviter un contenu initial haut */
  padding-top: 100%;
  /* hauteur égale à la largeur */
  position: relative;
  /* position relative pour le positionnement interne absolue */
}

.p-skeleton {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>
