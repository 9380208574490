<template>
  <div class="icon-container">
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.7133 4.55463L6.42743 12.6834C5.72952 13.1922 5.97399 14.2943 6.82065 14.4651L14.7823 16.0988L9.24258 22.3063C8.99094 22.5912 8.97516 23.0219 9.21499 23.319C9.48148 23.6492 9.94777 23.709 10.2868 23.4535L21.5727 15.3248C22.2706 14.8159 22.0261 13.7138 21.1795 13.543L13.2178 11.9093L18.7575 5.70186C19.0092 5.41695 19.025 4.98619 18.7851 4.68907C18.5186 4.35894 18.0523 4.29915 17.7133 4.55463Z"
        fill="#173447" />
    </svg>

  </div>
</template>
<style scoped>
.icon-container {
  width: 45px;
  height: 45px;
  display: flex;
  width: 44px;
  height: 44px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 44px;
  border: 2px solid var(--couleurs-bleus-noir-bleu-prussian-blue, #173447);
  box-shadow: 1px 1px 0px 0px #ED8D4C;
}
</style>