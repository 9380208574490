<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { type MessageSchema } from '@/i18n'
import RecipeStep from '@/components/Menus/RecipeStep.vue'
import {
  useRecipeStore,
  useDishesStore,
  useDishCreationEditionFormStore,
} from '@/stores/dishesStore'
import GenerationInfos from '@/components/Admin/GenerationInfos.vue'
import ButtonEdit from '@/assets/icons/Buttons/ButtonEdit.vue'
import Button from 'primevue/button'
import { useRoute, useRouter } from 'vue-router'
import { computed, onMounted, ref, type Ref } from 'vue'
import { useFetch } from '@/composables/useFetch.js'
import { useEnvStore } from '@/stores/envStore.js'
import type { Dish, Ingredient, Recipe } from '@/types/dish.js'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import BaseLoader from '@/components/BaseComponents/BaseLoader.vue'
import { adjustUnitsForQuantity, calculateIngredientPrice } from '@/utils/prices'

const { t } = useI18n<{ message: MessageSchema }>({ useScope: 'global' })

const router = useRouter()
const route = useRoute()
const dishId = route.params.id
const mustReload = route.query.mustReload === 'true' ? true : false

const { fetchData } = useFetch()

const recipeStore = useRecipeStore()
const recipe: Ref<Recipe | null> = ref(null)
const infoTotal = recipeStore.infoTotal

const dishesStore = useDishesStore()
const dish: Ref<Dish | null> = ref(null)

function editRecipe() {
  console.log("edit recipe")
  console.log("dish.value ", dish.value)
  if (dish.value && recipe.value) {

    const adjustedIngredients = listIngredients.value

    useDishCreationEditionFormStore().creationEditionDish = {
      dish_data: {
        id: dish.value.id,
        name: dish.value.name,
        chill_time_min: dish.value.chill_time_min,
        cook_time_min: dish.value.cook_time_min,
        prep_time_min: dish.value.prep_time_min,
        difficulty: dish.value.difficulty,
        servings: dish.value.servings,
        ingredients: adjustedIngredients,
        steps: recipe.value.steps,
      },
    }
    router.push({ name: 'dish-creation-edition-form', query: { edit: 'true' } })
  }
}

onMounted(async () => {
  if (!dishesStore.selectedDish || mustReload) {
    const { data, error } = await fetchData(useEnvStore().apiUrl + `/dishes/${dishId}`)
    if (error) {
      console.error(error)
    } else {
      console.log(data)
      dish.value = data as Dish
      console.log(dish.value)
      dish.value.ingredients = dish.value.ingredients.map((ingredient) => {
        return {
          ...ingredient,
          quantity: ingredient.quantity === 0 ? undefined : ingredient.quantity,
        }
      })
      dishesStore.selectedDish = dish.value
    }
  } else {
    dishesStore.selectedDish.ingredients = dishesStore.selectedDish.ingredients.map(
      (ingredient) => {
        return {
          ...ingredient,
          quantity: ingredient.quantity === 0 ? undefined : ingredient.quantity,
        }
      }
    )
    dish.value = dishesStore.selectedDish
  }

  if (!recipeStore.recipe || mustReload) {
    const { data, error } = await fetchData(useEnvStore().apiUrl + `/dishes/${dishId}/recipe`)
    if (error) {
      console.error(error)
    } else {
      console.log(data)
      recipe.value = data as Recipe
      recipeStore.recipe = recipe.value
    }
  } else {
    recipe.value = recipeStore.recipe
  }
})

const listIngredients = computed(() => {
  if (!dish.value) return [];

  const servings = dish.value.servings

  let ingredients = dish.value.ingredients.map((ingredient: Ingredient) => {
    let converted = { unit: ingredient.unit || '', quantity: ingredient.quantity || 0 }
    converted = adjustUnitsForQuantity(converted.quantity, converted.unit, servings);
    return {
      ...ingredient,
      quantity: converted.quantity,
      unit: converted.unit,
      price: calculateIngredientPrice(ingredient.price, servings)
    };
  });

  ingredients.sort((a, b) => a.id - b.id);

  return ingredients;
})
</script>

<template>
  <main>
    <div v-if="recipe && dish" class="flex flex-col gap-6">
      <div class="flex justify-between">
        <h1>{{ dish.name }}</h1>
        <Button rounded link :pt="{ root: { class: '!p-1.5 !w-10 !h-10' } }" :pt-options="{ mergeProps: true }"
          @click="editRecipe">
          <template #icon>
            <ButtonEdit />
          </template>
        </Button>
      </div>
      <div>
        <h2>{{ t('profileFavorites.creationForm.informations') }}</h2>
        <p class="grid grid-cols-4">
          <span class="col-span-3"> {{ t('profileFavorites.creationForm.prepTime') }} : </span>
          <span> {{ dish.prep_time_min }} {{ t('common.minuteShort') }} </span>
        </p>
        <p class="grid grid-cols-4">
          <span class="col-span-3"> {{ t('profileFavorites.creationForm.chillTime') }} : </span>
          <span>{{ dish.chill_time_min }} {{ t('common.minuteShort') }} </span>
        </p>
        <p class="grid grid-cols-4">
          <span class="col-span-3">{{ t('profileFavorites.creationForm.cookTime') }} : </span>
          <span>{{ dish.cook_time_min }} {{ t('common.minuteShort') }}</span>
        </p>
        <p class="grid grid-cols-4">
          <span class="col-span-3">{{ t('profileFavorites.creationForm.numberOfPeople') }} : </span>
          <span>{{ dish.servings }}</span>
        </p>
      </div>
      <div>
        <h2>{{ t('profileFavorites.creationForm.ingredients') }}</h2>
        <DataTable :value="listIngredients" size="small">
          <Column field="quantity" :header="t('profileFavorites.creationForm.quantityShort')">
            <template #body="slotProps">
              {{ slotProps.data.quantity > 0 ? slotProps.data.quantity : '' }}
            </template>
          </Column>
          <Column field="unit" :header="t('profileFavorites.creationForm.unit')" />
          <Column field="name" :header="t('profileFavorites.creationForm.name')" />
          <Column field="price" :header="t('profileFavorites.creationForm.price')" />
        </DataTable>
      </div>
      <div v-if="recipe">
        <h2>{{ t('profileFavorites.creationForm.instructions') }}</h2>
        <div class="flex flex-col gap-6">
          <RecipeStep v-for="step in recipe.steps" :key="step.name" :step-name="step.name"
            :instructions="step.instructions" />
        </div>
      </div>
      <GenerationInfos :info-total="infoTotal" v-if="infoTotal" />
    </div>
    <div class="absolute -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2" v-else>
      <BaseLoader />
    </div>
  </main>
</template>
