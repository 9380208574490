<template>
  <div class="icon-container">
    <svg width="20" height="26" viewBox="0 0 20 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.1429 0.789062C18.4753 0.789106 18.7961 0.911146 19.0445 1.13203C19.2929 1.35292 19.4516 1.65729 19.4905 1.98742L19.5 2.14621V23.8605C19.4996 24.2064 19.3672 24.5391 19.1297 24.7906C18.8922 25.0422 18.5677 25.1935 18.2224 25.2138C17.8771 25.2341 17.5371 25.1217 17.2718 24.8997C17.0065 24.6777 16.8361 24.3628 16.7952 24.0193L16.7857 23.8605V17.0748H15.4286C15.0962 17.0747 14.7753 16.9527 14.5269 16.7318C14.2785 16.5109 14.1198 16.2065 14.0809 15.8764L14.0714 15.7176V7.57478C14.0714 4.57549 16.1071 0.789062 18.1429 0.789062ZM10 0.789062C10.3324 0.789106 10.6532 0.911146 10.9016 1.13203C11.1501 1.35292 11.3088 1.65729 11.3476 1.98742L11.3571 2.14621V8.93192C11.357 10.1355 10.957 11.305 10.2201 12.2566C9.48315 13.2083 8.45099 13.8881 7.28571 14.1895V23.8605C7.28533 24.2064 7.15288 24.5391 6.91542 24.7906C6.67796 25.0422 6.35342 25.1935 6.00811 25.2138C5.66279 25.2341 5.32277 25.1217 5.05751 24.8997C4.79226 24.6777 4.62178 24.3628 4.58093 24.0193L4.57143 23.8605V14.1895C3.4524 13.9002 2.45484 13.2615 1.72382 12.3662C0.992807 11.4709 0.56646 10.3658 0.506786 9.21149L0.5 8.93192V2.14621C0.500384 1.8003 0.632836 1.46759 0.870294 1.21606C1.10775 0.964535 1.43229 0.813172 1.77761 0.7929C2.12292 0.772629 2.46294 0.884978 2.7282 1.10699C2.99346 1.32901 3.16393 1.64393 3.20479 1.98742L3.21429 2.14621V8.93192C3.2143 9.40836 3.33972 9.87641 3.57795 10.289C3.81618 10.7016 4.15881 11.0443 4.57143 11.2825V2.14621C4.57181 1.8003 4.70426 1.46759 4.94172 1.21606C5.17918 0.964535 5.50372 0.813172 5.84904 0.7929C6.19435 0.772629 6.53437 0.884978 6.79963 1.10699C7.06489 1.32901 7.23536 1.64393 7.27621 1.98742L7.28571 2.14621L7.28707 11.2825C7.6658 11.0636 7.986 10.7564 8.22041 10.387C8.45482 10.0177 8.59649 9.59717 8.63336 9.16128L8.64286 8.93192V2.14621C8.64286 1.78627 8.78584 1.44107 9.04036 1.18656C9.29487 0.932047 9.64006 0.789063 10 0.789062Z"
        fill="#173447" />
    </svg>
  </div>
</template>
<style scoped>
.icon-container {
  width: 45px;
  height: 45px;
  display: inline-flex;
  padding: 10px;
  align-items: center;
  gap: 10px;
  border-radius: 44px;
  border: 2px solid var(--couleurs-bleus-noir-bleu-prussian-blue, #173447);
  box-shadow: 1px 1px 0px 0px #ED8D4C;
}
</style>