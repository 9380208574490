<template>
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.229 16.9639C18.3134 16.9639 17.4941 17.3253 16.8676 17.8916L8.27722 12.8916C8.33746 12.6145 8.38565 12.3373 8.38565 12.0482C8.38565 11.759 8.33746 11.4819 8.27722 11.2048L16.7712 6.25301C17.4218 6.85542 18.2772 7.22892 19.229 7.22892C21.229 7.22892 22.8435 5.61446 22.8435 3.61446C22.8435 1.61446 21.229 0 19.229 0C17.229 0 15.6146 1.61446 15.6146 3.61446C15.6146 3.90361 15.6628 4.18072 15.723 4.45783L7.22903 9.40964C6.57842 8.80723 5.723 8.43374 4.7712 8.43374C2.7712 8.43374 1.15674 10.0482 1.15674 12.0482C1.15674 14.0482 2.7712 15.6627 4.7712 15.6627C5.723 15.6627 6.57842 15.2892 7.22903 14.6867L15.8073 19.6988C15.7471 19.9518 15.711 20.2169 15.711 20.4819C15.711 22.4217 17.2893 24 19.229 24C21.1688 24 22.7471 22.4217 22.7471 20.4819C22.7471 18.5422 21.1688 16.9639 19.229 16.9639ZM19.229 2.40964C19.8917 2.40964 20.4338 2.95181 20.4338 3.61446C20.4338 4.27711 19.8917 4.81928 19.229 4.81928C18.5664 4.81928 18.0242 4.27711 18.0242 3.61446C18.0242 2.95181 18.5664 2.40964 19.229 2.40964ZM4.7712 13.253C4.10855 13.253 3.56638 12.7108 3.56638 12.0482C3.56638 11.3855 4.10855 10.8434 4.7712 10.8434C5.43385 10.8434 5.97602 11.3855 5.97602 12.0482C5.97602 12.7108 5.43385 13.253 4.7712 13.253ZM19.229 21.7108C18.5664 21.7108 18.0242 21.1687 18.0242 20.506C18.0242 19.8434 18.5664 19.3012 19.229 19.3012C19.8917 19.3012 20.4338 19.8434 20.4338 20.506C20.4338 21.1687 19.8917 21.7108 19.229 21.7108Z"
      fill="currentColor"
    />
  </svg>
</template>
