<script setup lang="ts">
import CreationFilter from '@/components/Menus/Filters/CreationFilter.vue'
import { useI18n } from 'vue-i18n'
import { type MessageSchema } from '@/i18n'
import { ref } from 'vue'
import InputNumber from 'primevue/inputnumber'
import Slider from 'primevue/slider'
import { useDishesStore } from '@/stores/dishesStore'

const { t } = useI18n<{ message: MessageSchema }>({ useScope: 'global' })

const dishesStore = ref(useDishesStore());

</script>

<template>
  <CreationFilter>
    <template #title>{{ t('creationFilters.updateMaxPrice') }}</template>
    <template #content>
      <!-- <InputNumber v-model="maxPrice" class="w-24" :step="1" /> -->
      <!-- <InputNumber v-model="maxPrice" inputId="horizontal-buttons" showButtons buttonLayout="horizontal" :step="1"
        mode="currency" currency="CHF">
        <template #incrementbuttonicon>
          <span class="pi pi-plus" />
        </template>
<template #decrementbuttonicon>
          <span class="pi pi-minus" />
        </template>
</InputNumber> -->

      <div class="w-10rem">
        <InputNumber v-model.number="dishesStore.generationParams.max_price" class="w-full" disabled mode="currency"
          currency="CHF" locale="fr-CH" />

        <Slider v-model="dishesStore.generationParams.max_price" :step="0.5" :min="1" :max="12" class="w-full" />
      </div>
      <!-- <input type="number" v-model="maxPrice" class="w-24" step="1" /> -->
    </template>
  </CreationFilter>
</template>
