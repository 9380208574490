<script setup lang="ts">
import type { MessageSchema } from '@/i18n'
import type { Ingredient } from '@/types/dish'
import { useI18n } from 'vue-i18n'
import { type ModelRef } from 'vue'
import Checkbox from 'primevue/checkbox';
import InputNumber from 'primevue/inputnumber';
import { useFetch } from '@/composables/useFetch';
import { calculateIngredientPrice } from '@/utils/prices';

const { t } = useI18n<{ message: MessageSchema }>({ useScope: 'global' })
// const listSelectedIngredients = ref<Ingredient[]>([]);
const ingredients: ModelRef<Ingredient[] | []> = defineModel('ingredients', { required: true })


const props = withDefaults(
  defineProps<{
    // ingredients: Ingredient[]
    numberOfPeople?: number
    dishId: string | string[]
    // shoppingList: Ingredient[] | []
    listSelectedIngredients: Ingredient[] | []
  }>(),
  {
    numberOfPeople: 1,
  }
)

const emit = defineEmits(['update-list']);


// const formattedIngredients = computed(() => {
//   return ingredients.value.map((ingredient) => {
//     const { quantity, unit } = convertUnits(ingredient.quantity, ingredient.unit, props.servings);
//     const is_selected = listSelectedIngredients.value.some(item => item.id === ingredient.id);
//     return {
//       ...ingredient,
//       quantity,
//       unit,
//       totalPrice: calculatePricePerPerson(ingredient, props.numberOfPeople, props.servings),
//       is_selected
//     };
//   });
// });


// watch(listSelectedIngredients, (currentIngredients) => {
//   // Emit the updated list to the parent component
//   emit('update-list', currentIngredients);
// }, { deep: true });

// const matchIngredients = () => {
//   listSelectedIngredients.value = ingredients.value.filter(ingredient => {
//     return props.shoppingList.some(listItem =>
//       normalizeName(listItem.name) === normalizeName(ingredient.name)
//     );
//     // return { ...ingredient, is_selected: isOnShoppingList };
//   });
// };

// const normalizeName = (name) => {
//   return name.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
// };

// watchEffect(() => {
//   console.log("shopping list update", props.shoppingList)
//   if (props.shoppingList && !listSelectedIngredients.value.length)
//     matchIngredients();
// })

const toggleIngredientSelection = (ingredient) => {
  // Find the ingredient in the ingredients array and toggle its selection state
  const foundIngredient = ingredients.value.find(item => item.id === ingredient.id);
  if (foundIngredient) {
    foundIngredient.is_selected = !foundIngredient.is_selected;
  }

  console.log("ingredient", ingredient)
  console.log("list", ingredients.value)
}

// Helper function to check if an ingredient is selected
// const isIngredientSelected = (ingredient) => {
//   console.log("ingredient", ingredient)
//   console.log("listSelectedIngredients", listSelectedIngredients.value)
//   console.log("test", listSelectedIngredients.value.some(item => item.id === ingredient.id))
//   return listSelectedIngredients.value.some(item => item.id === ingredient.id);
// };


</script>

<template>
  <div class="relative">
    <div>
      <div class="flex gap-3 min-w-72 text-sm text-primary-950 mb-4 border-b border-primary-500 pb-1"
        v-for="ingredient in ingredients" :key="ingredient.id">
        <div class="flex-none w-3 flex items-center">
          <!-- <Checkbox v-model="listSelectedIngredients" :inputId="ingredient.name" name="ingredient"
            :value="ingredient" /> -->
          <Checkbox v-model="ingredient.is_selected" @click.prevent="() => toggleIngredientSelection(ingredient)"
            :inputId="`checkbox-${ingredient.id}`" :binary="true" />
          <!-- <Checkbox v-model="ingredient.is_selected" :inputId="`checkbox-${ingredient.id}`" /> -->
        </div>
        <label :for="`checkbox-${ingredient.id}`" class="flex flex-1 gap-1">
          <div class="flex-none w-28">
            <div class="text-left flex items-center gap-1">
              <!-- <span class="col-span-1">
                {{ ingredient.quantity ? ingredient.quantity.toFixed(2) : '' }}
              </span> -->
              <InputNumber v-model="ingredient.quantity" class="flex-none w-20" :inputStyle="{ 'text-align': 'right' }"
                :placeholder="t('profileFavorites.creationForm.quantityPlaceholder')" :min-fraction-digits="2" />
              <span class="flex-1">{{ ingredient.unit }}</span>
            </div>
          </div>
          <div class="flex-1 flex items-center h-full">
            <span class="flex-1">{{ ingredient.name }}</span>
          </div>
          <div class="flex-none w-10 flex items-center">
            <span>
              {{ ingredient.price }}
            </span>
          </div>
        </label>
      </div>
    </div>
    <!-- <div class="absolute w-full h-8 -bottom-1 bg-gradient-to-t" :class="gradientFrom"></div> -->
  </div>
</template>
