<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import type { MessageSchema } from '@/i18n/index.js'
import InputText from 'primevue/inputtext'
import Textarea from 'primevue/textarea'
import InputNumber from 'primevue/inputnumber'
import RadioButton from 'primevue/radiobutton'
import { ref, watchEffect } from 'vue'
import { useToast } from 'primevue/usetoast'
import type { AttributesList, Restaurant } from '@/types/restaurants.js'
import { useProfileRestaurant } from '@/composables/useProfileRestaurant.js'
import { watch } from 'vue'
import { useClipboard } from '@vueuse/core'
import Button from 'primevue/button'
import TheRestaurantCountries from './TheRestaurantCountries.vue'

const props = defineProps<{
  restaurant: Restaurant
  attributes: AttributesList
}>()
const emit = defineEmits<{
  (e: 'updateRestaurant', restaurant: Restaurant): void
  (e: 'goToTooling'): void
}>()

const { t } = useI18n<{ message: MessageSchema }>({ useScope: 'global' })
const { copy } = useClipboard()
const toast = useToast()

const restaurantName = ref(props.restaurant.name)
const restaurantDescription = ref(props.restaurant.description)
const restaurationType = ref(props.restaurant.attributes.restaurationType[0].id)
const restaurantCoverCount = ref(props.restaurant.cover_count)
const restaurantCooks = ref(props.restaurant.cooks_per_service)

const { updateRestaurantProperty, updateRestaurantAttributes } = useProfileRestaurant(
  toast,
  props.restaurant.id
)

async function updateProperty(propertyName: string, value: string | number) {
  const updateResponse = await updateRestaurantProperty(propertyName, value)
  if (updateResponse !== null) emit('updateRestaurant', updateResponse)
}

watch(restaurationType, async () => {
  const updateResponse = await updateRestaurantAttributes({
    attributeIds: [restaurationType.value],
    isRestaurationType: true,
  })
  if (updateResponse !== null) emit('updateRestaurant', updateResponse)
})

watchEffect(() => {
  if (restaurantCoverCount.value === null) restaurantCoverCount.value = 1
  if (restaurantCooks.value === null) restaurantCooks.value = 1
})
</script>

<template>
  <div class="flex flex-col gap-6">
    <div>
      <h3>{{ t('profile.profile.restaurantTab.historyAndData.title') }}</h3>
      <p class="text-sm">{{ t('profile.profile.restaurantTab.historyAndData.description') }}</p>
      <h4 class="mt-2">{{ t('profile.profile.restaurantTab.historyAndData.restaurantName') }}</h4>
      <InputText
        class="w-full"
        v-model="restaurantName"
        @blur="updateProperty('name', restaurantName)"
      />
      <!-- <h4 class="mt-2">{{ t('profile.profile.restaurantTab.historyAndData.restaurantAdress') }}</h4>
      <InputText class="w-full" /> -->
      <h4 class="mt-2">
        {{ t('profile.profile.restaurantTab.historyAndData.restaurantDescription') }}
      </h4>
      <Textarea
        class="w-full"
        autoResize
        :rows="3"
        v-model="restaurantDescription"
        @blur="updateProperty('description', restaurantDescription)"
      />
      <p class="mt-2">
        {{ t('profile.profile.profileTab.personnalData.gastroId') }} :
        <span>
          <b>{{ restaurant.gastro_id }}</b>
        </span>
        <i class="ml-1 text-xl pi pi-copy" @click="copy(restaurant.gastro_id)" />
      </p>
    </div>

    <div class="flex flex-row items-end justify-between">
      <div>
        <h3>{{ t('profile.profile.restaurantTab.servings.title') }}</h3>
        <p class="mr-2 text-sm">{{ t('profile.profile.restaurantTab.servings.description') }}</p>
      </div>
      <InputNumber
        class="w-24 h-fit shrink-0"
        v-model="restaurantCoverCount"
        :format="false"
        :min="1"
        @blur="updateProperty('cover_count', parseInt($event.value.replace(' ', '')) || 1)"
      />
    </div>

    <div class="flex flex-row items-end justify-between">
      <div>
        <h3 class="mr-2">{{ t('profile.profile.restaurantTab.cooks.title') }}</h3>
        <p class="mr-2 text-sm">{{ t('profile.profile.restaurantTab.cooks.description') }}</p>
      </div>
      <InputNumber
        class="w-24 h-fit shrink-0"
        v-model="restaurantCooks"
        :format="false"
        :min="1"
        @blur="updateProperty('cooks_per_service', parseInt($event.value.replace(' ', '')) || 1)"
      />
    </div>

    <div>
      <h3>{{ t('profile.profile.restaurantTab.restaurantionType.title') }}</h3>
      <p class="text-sm">{{ t('profile.profile.restaurantTab.restaurantionType.description') }}</p>
      <div class="flex flex-col gap-2 mt-2">
        <div
          class="flex flex-row items-center"
          v-for="attribute in attributes.restaurationType"
          :key="attribute.id"
        >
          <RadioButton
            v-model="restaurationType"
            :inputId="'radio-' + attribute.name"
            :name="attribute.name"
            :value="attribute.id"
          />
          <label :for="'radio-' + attribute.name" class="ml-2">
            {{ t(`profile.profile.restaurantTab.restaurantionType.${attribute.name}`) }}
          </label>
        </div>
      </div>
    </div>

    <div>
      <h3>Pays</h3>
      <TheRestaurantCountries
        @updateCountries="updateRestaurantAttributes({attributeIds: [$event]})"
        :attributes="props.attributes.inspirations"
        :restaurant-attributes="props.restaurant.attributes.inspirations"
      />
    </div>

    <div>
      <h3>{{ t('profile.profile.restaurantTab.tools.title') }}</h3>
      <Button
        :label="t('profile.profile.restaurantTab.tools.tooling')"
        icon="pi pi-arrow-right"
        icon-pos="right"
        class="mt-2"
        @click="emit('goToTooling')"
      />
    </div>
  </div>
</template>
