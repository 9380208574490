<script setup lang="ts">
import Accordion from 'primevue/accordion'
import AccordionTab from 'primevue/accordiontab'
import { useI18n } from 'vue-i18n'
import { type MessageSchema } from '@/i18n'
import { ref, onMounted, computed, type Ref, reactive, watch } from 'vue'
import { useOrderList } from '@/stores/orderStore'
import { useEnvStore } from '@/stores/envStore'
import { useFetch } from '@/composables/useFetch'
import { useToast } from 'primevue/usetoast'
import InlineIngredient from '@/components/Orders/InlineIngredient.vue'
import type { Ingredient } from '@/types/dish'
import LoadingView from '../LoadingView.vue'
import EditIngredient from '@/components/Orders/EditIngredient.vue'
import { useAuth } from '@/composables/useAuth'
import { useDishesStore } from '@/stores/dishesStore'
import Button from 'primevue/button'
import { useConfirm } from 'primevue/useconfirm'
import { aggregateGroupInfo, groupIngredientsInCategory } from '@/utils/orders'
import Checkbox from 'primevue/checkbox'



const orderListStore = useOrderList();
const { t, te } = useI18n<{ message: MessageSchema }>({ useScope: 'global' })
const toast = useToast()
const dishesStore = useDishesStore();
const ingredientUnits = ref(dishesStore.units);
// const orderListStore = useOrderList();
const confirm = useConfirm()

const restaurantId = ref();
const user = ref(useAuth().user);
const envStore = useEnvStore();
const { isFetching, fetchData } = useFetch()
const ingredientToEdit: Ref<Ingredient> = ref({
  id: -1,
  category: 'other',
  name: '',
  quantity: 0,
  unit: '',
  price: 0
});
const ingredientToEditIndex = ref(-1)
const showSideBar: Ref<boolean> = ref(false);
const selectedIngredients = ref<Ingredient[]>([]);


onMounted(async () => {
  //TODO : use real restaurantId
  if (!user.value)
    return;
  if (user.value.restaurants.length == 0) {
    toast.add({
      severity: 'error',
      summary: t('common.error'),
      detail: t('auth.user.noRestaurantConnected'),
      life: 3000,
    })
    return;
  }

  if (!ingredientUnits.value.length) {
    const { data, error } = await fetchData(
      useEnvStore().apiUrl + `/units/default`,
      'GET'
    )
    if (error) {
      console.log('error', error)
    } else {
      ingredientUnits.value = data;
    }
  }

  // TODO: handle if user is in many restaurants
  restaurantId.value = user.value.restaurants[0].id;
  console.log("Test, orderliststore: ", orderListStore.ingredientsByCategory)
  if (!Object.keys(orderListStore.ingredientsByCategory).length) {
    await orderListStore.fetchIngredients(restaurantId.value);
    console.log(orderListStore.ingredientsByCategory)
  }
  // fetchIngredients(restaurantId.value);
});

const handleUpdateSelection = ({ ingredient, isChecked }) => {
  const index = selectedIngredients.value.findIndex(i => i.id === ingredient.id);
  if (isChecked && index === -1) {
    selectedIngredients.value.push(ingredient);
  } else if (!isChecked && index !== -1) {
    selectedIngredients.value.splice(index, 1);
  }
};

const categoriesWithIngredients = computed(() => {
  return groupIngredientsInCategory(orderListStore.ingredientsByCategory, orderListStore);
});


function addOrUpdateIngredient({ ingredient = null, index = null, category = null }: { ingredient?: Ingredient | null, index?: number | null, category: string | null }) {
  if (ingredient && index !== null) {
    console.log("open sidebar - edit ingredient", ingredient, index)
    ingredientToEdit.value = { ...ingredient };
    ingredientToEditIndex.value = index;
  } else {
    category = category ? category : 'other';
    ingredientToEdit.value = { id: -1, category: category, name: '', quantity: 0, unit: '', price: 0 };
    ingredientToEditIndex.value = -1;
    console.log("open sidebar -   update ingredient", ingredient, index)
  }
  showSideBar.value = true;
};

const deleteIngredient = async () => {
  orderListStore.removeIngredients([ingredientToEdit.value.id], restaurantId.value).then(
    () => {
      ingredientToEdit.value = { id: -1, category: 'other', name: '', quantity: 0, unit: 'g', price: 0 };
    });
};

const updateIngredient = async (category, newIngredient) => {
  console.log("Update ingredient")
  console.log(ingredientToEdit.value)
  orderListStore.updateIngredient(ingredientToEdit.value, restaurantId.value).then(
    () => {
      ingredientToEdit.value = { id: -1, category: 'other', name: '', quantity: 0, unit: 'g', price: 0 };
    }
  );;
};

const addIngredient = async () => {
  console.log("add ingredient")
  orderListStore.addIngredients([ingredientToEdit.value], restaurantId.value).then(
    () => {
      ingredientToEdit.value = { id: -1, category: 'other', name: '', quantity: 0, unit: '', price: 0 };
    }
  );
}


const expandedGroups = reactive(new Set());

function toggleGroup(groupName: string) {
  if (expandedGroups.has(groupName)) {
    expandedGroups.delete(groupName);
  } else {
    expandedGroups.add(groupName);
  }
}


const confirmDeleteSelectedIngredients = (event) => {
  confirm.require({
    target: event.currentTarget as HTMLElement,
    message: t('ingredients.deleteSelectedIngredients.popupLabel'),
    icon: 'pi pi-exclamation-triangle',
    acceptLabel: t('ingredients.deleteSelectedIngredients.confirm'),
    rejectLabel: t('ingredients.deleteSelectedIngredients.cancel'),
    accept: () => {
      if (!user.value || user.value.restaurants.length === 0) {
        toast.add({
          severity: 'error',
          summary: t('common.error'),
          detail: t('auth.user.noRestaurantConnected'),
          life: 3000,
        })
        return;
      }
      const restaurantId = user.value.restaurants[0].id
      deleteSelectedIngredients(selectedIngredients.value)
      // removeFromShoppingList()
    },
    reject: () => {
      console.log('reject')
    },
  })
}

function deleteSelectedIngredients(listIngredientsToDelete) {
  console.log("Delete ingredients", listIngredientsToDelete)

  const ingredientIds = listIngredientsToDelete.map(ingredient => ingredient.id);

  if (user.value && user.value.restaurants.length > 0) {
    const restaurantId = user.value.restaurants[0].id;
    orderListStore.removeIngredients(ingredientIds, restaurantId);
    selectedIngredients.value = [];
  } else {
    toast.add({
      severity: 'error',
      summary: t('common.error'),
      detail: t('auth.user.noRestaurantConnected'),
      life: 3000,
    });
  }
}

function compareIngredients() {
  console.log("comparer ingrédients")
}

const categorySelections = reactive({});

categoriesWithIngredients.value.forEach(category => {
  categorySelections[category.name] = false;
});

function toggleCategory(categoryName) {
  console.log("toggle category ", categoryName)
  const isSelected = categorySelections[categoryName];
  const category = categoriesWithIngredients.value.find(c => c.name === categoryName)
  console.log("category ", category)
  category?.ingredientGroups.forEach(group => {
    group.ingredients?.forEach(ingredient => {
      const ingredientIndex = selectedIngredients.value.findIndex(i => i.id === ingredient.id);
      if (isSelected && ingredientIndex === -1) {
        selectedIngredients.value.push(ingredient);
      } else if (!isSelected && ingredientIndex !== -1) {
        selectedIngredients.value.splice(ingredientIndex, 1);
      }
    });
  });
}

watch(selectedIngredients, () => {
  console.log("watch selectedIngredients", selectedIngredients.value)
  Object.keys(categorySelections).forEach(categoryName => {
    const category = categoriesWithIngredients.value.find(c => c.name === categoryName);
    categorySelections[categoryName] = category?.ingredientGroups.every(group =>
      group.ingredients?.every(ingredient => selectedIngredients.value.some(i => i.id === ingredient.id))
    );
  });

  categoriesWithIngredients.value.forEach(category => {
    category.ingredientGroups.forEach(group => {
      groupSelections[group.group] = group.ingredients?.every(ingredient => isSelected(ingredient));
    });
  });
}, { deep: true });

function isSelected(ingredient) {
  return selectedIngredients.value.some(i => i.id === ingredient.id);
}

const groupSelections = reactive({});

categoriesWithIngredients.value.forEach(category => {
  category.ingredientGroups.forEach(group => {
    groupSelections[group.group] = group.ingredients?.every(ingredient => isSelected(ingredient));
  });
});

function toggleGroupSelection(groupName) {
  const group = categoriesWithIngredients.value.flatMap(category => category.ingredientGroups)
    .find(g => g.group === groupName);
  if (!group) return;
  if (groupSelections[groupName]) {
    group.ingredients?.forEach(ingredient => {
      if (!isSelected(ingredient)) {
        selectedIngredients.value.push(ingredient);
      }
    });
  } else {
    group.ingredients?.forEach(ingredient => {
      const index = selectedIngredients.value.findIndex(i => i.id === ingredient.id);
      if (index !== -1) {
        selectedIngredients.value.splice(index, 1);
      }
    });
  }
}


</script>

<template>
  <main>
    <div v-if="isFetching && !categoriesWithIngredients.length">
      <LoadingView :message="t('orders.loadingLabel')" :generation="false" />
    </div>
    <div v-else>

      <Accordion :activeIndex="0" :multiple="true">
        <AccordionTab v-for="category in categoriesWithIngredients" :key="category.name">
          <template #header>
            <div class="flex items-center gap-3">
              <component :is="category.icon" class="w-5 h-5" />
              <span class="leading-5 text-sm">
                {{ t(`orders.ingredientsCategories.${category.name}`) }} <span class="font-normal">({{
                  category.ingredientGroups.reduce((sum, group) => sum + (group.ingredients?.length ?? 0), 0) }})</span>
              </span>
            </div>
          </template>
          <div class="flex justify-end gap-3 items-center italic my-2" v-if="category.ingredientGroups.length > 1">
            <label :for="`select-all-${category.name}`" class="ml-2 hover:cursor-pointer text-sm text-primary-950">{{
              t('common.selectAll')
              }}</label>
            <div class="flex-none w-6 flex justify-center items-center py-2">
              <Checkbox v-model="categorySelections[category.name]" @change="toggleCategory(category.name)"
                :binary="true" :inputId="`select-all-${category.name}`" />
            </div>
          </div>
          <!-- <TreeTable :value="category.children" selectionMode="checkbox" v-model:selectionKeys="selectedIngredients">
            <Column field="name" header="Name" expander></Column>
            <Column field="quantity" header="Quantity"></Column>
            <Column field="unit" header="Unit"></Column>
            <Column field="price" header="Price"></Column>
          </TreeTable> -->
          <div v-for="group in category.ingredientGroups" :key="group.group">
            <div v-if="group.ingredients && group.ingredients.length > 1"
              class="hover:cursor-pointer border-b border-gray-100 py-2 text-sm text-primary-950 relative min-w-52 flex items-center">
              <div class="flex-1 flex items-center min-w-0" @click="toggleGroup(group.group)">
                <i
                  :class="{ 'pi pi-chevron-down': expandedGroups.has(group.group), 'pi pi-chevron-right': !expandedGroups.has(group.group), 'w-5': true }"></i>
                <div class="flex-1 min-w-0 flex">
                  <div class="flex-1 min-w-0">
                    <p class="text-sm text-primary-950 truncate min-w-0">
                      {{ group.group }}
                    </p>
                  </div>
                  <div class="flex-none w-28 flex gap-1">
                    <!-- <span class="text-sm text-primary-950 px-2">
                      {{ aggregateGroupInfo(group).totalQuantity }}{{ aggregateGroupInfo(group).unit ? ' ' +
                      aggregateGroupInfo(group).unit : '' }} - {{ group.group }}
                    </span> -->
                    <p class="flex-none w-16 text-right">
                      {{ aggregateGroupInfo(group).totalQuantity }}
                    </p>
                    <span class="w-7 truncate text-right">{{
                      te(`ingredients.unit.symbol.${aggregateGroupInfo(group).unit}`)
                      ? t(`ingredients.unit.symbol.${aggregateGroupInfo(group).unit}`) : aggregateGroupInfo(group).unit
                      }}</span>
                  </div>
                </div>
              </div>
              <div class="flex-none w-6 flex justify-center items-center py-2">
                <Checkbox v-model="groupSelections[group.group]" @change="toggleGroupSelection(group.group)"
                  :binary="true" />
              </div>
            </div>
            <div v-if="expandedGroups.has(group.group) || group.ingredients?.length === 1">
              <div v-for="(ingredient, index) in group.ingredients" :key="ingredient.id">
                <InlineIngredient :ingredient="ingredient" :index="index" @update-selection="handleUpdateSelection"
                  @edit-ingredient="addOrUpdateIngredient({ ingredient, index, category: category.name })"
                  :class="{ 'ml-5': !expandedGroups.has(group.group), 'ml-10': expandedGroups.has(group.group) }"
                  v-model:selectedIngredients="selectedIngredients" />
              </div>
            </div>
          </div>
          <div v-if="isFetching && !categoriesWithIngredients.length">
            <i class="pi pi-spin pi-spinner" style="font-size: 1rem"></i> {{ t('common.loading') }}
          </div>
          <div v-if="!category.ingredientGroups.length">
            <p class="italic text-sm"> {{ t('orders.noProduct') }} </p>
          </div>
          <div class="hover:cursor-pointer"
            @click="addOrUpdateIngredient({ ingredient: null, index: null, category: category.name })">
            <p>+ {{ t('orders.addProduct') }}</p>
          </div>
        </AccordionTab>
      </Accordion>
      <EditIngredient v-model:ingredient="ingredientToEdit" :index="ingredientToEditIndex" v-model:visible="showSideBar"
        @add-ingredient="addIngredient" @edit-ingredient="updateIngredient" @delete-ingredient="deleteIngredient" />

      <div class="sticky bottom-16 bg-white px-4 py-2 flex gap-3 z-50"
        v-if="!isFetching || categoriesWithIngredients.length">
        <Button icon="pi pi-trash" aria-label="Delete" class="text-center" size="small"
          :disabled="!selectedIngredients.length"
          @click="!selectedIngredients.length ? null : confirmDeleteSelectedIngredients($event)" severity="danger" />
        <Button class="text-center flex-1" size="small" :disabled="!selectedIngredients.length || true"
          @click="compareIngredients">
          {{ t('orders.createPurchaseOrder')}}
        </Button>
      </div>
    </div>
  </main>
</template>
