<template>
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.4 1C15.312 1 13.308 1.972 12 3.508C10.692 1.972 8.688 1 6.6 1C2.904 1 0 3.904 0 7.6C0 12.136 4.08 15.832 10.26 21.448L12 23.02L13.74 21.436C19.92 15.832 24 12.136 24 7.6C24 3.904 21.096 1 17.4 1ZM12.12 19.66L12 19.78L11.88 19.66C6.168 14.488 2.4 11.068 2.4 7.6C2.4 5.2 4.2 3.4 6.6 3.4C8.448 3.4 10.248 4.588 10.884 6.232H13.128C13.752 4.588 15.552 3.4 17.4 3.4C19.8 3.4 21.6 5.2 21.6 7.6C21.6 11.068 17.832 14.488 12.12 19.66Z"
      fill="currentColor"
    />
  </svg>
</template>
