export function debounce<T extends (...args: any[]) => void>(func: T, wait: number): T {
  let timeout: ReturnType<typeof setTimeout>;
  return function (...args: Parameters<T>) {
    clearTimeout(timeout);
    timeout = setTimeout(() => func(...args), wait);
  } as T;
}

export function levenshteinDistance(s1, s2) {
  const arr = Array.from({ length: s2.length + 1 }, (_, i) => i);

  for (let i = 1; i <= s1.length; i++) {
    let prev = i;
    for (let j = 1; j <= s2.length; j++) {
      const val = (s1[i - 1] === s2[j - 1]) ? arr[j - 1] : Math.min(arr[j - 1] + 1, arr[j] + 1, prev + 1);
      arr[j - 1] = prev;
      prev = val;
    }
    arr[s2.length] = prev;
  }

  return arr[s2.length];
}