<script setup lang="ts">
import { computed } from 'vue'

const props = defineProps<{
  selected: boolean
  labelH?: '1' | '2' | '3'
}>()
defineEmits<{
  (e: 'handleSelect', selected: boolean): void
}>()

const isSelected = computed(() => props.selected)
</script>

<template>
  <div
    class="w-full p-3 border-2 cursor-pointer rounded-xl border-primary-300"
    :class="{ 'border-primary-800 bg-primary-100': isSelected }"
    @click="$emit('handleSelect', !isSelected)"
  >
    <div class="w-6">
      <slot name="icon"></slot>
    </div>
    <div :class="{'h-4' : labelH === '1', 'h-10' : labelH === '2', 'h-15' : labelH === '3' }" class="mt-2 flex items-center">
      <h4>
        <slot name="label"></slot>
      </h4>
    </div>
  </div>
</template>
