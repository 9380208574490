<template>
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.5855 13.1784C12.564 13.1784 12.5318 13.1784 12.5103 13.1784C12.4782 13.1784 12.4352 13.1784 12.403 13.1784C9.96695 13.1033 8.14258 11.2038 8.14258 8.86432C8.14258 6.4819 10.085 4.53947 12.4674 4.53947C14.8498 4.53947 16.7923 6.4819 16.7923 8.86432C16.7815 11.2145 14.9464 13.1033 12.6177 13.1784C12.5962 13.1784 12.5962 13.1784 12.5855 13.1784ZM12.4567 6.13849C10.9543 6.13849 9.74159 7.36189 9.74159 8.85359C9.74159 10.3238 10.8899 11.515 12.3494 11.5687C12.3816 11.558 12.4889 11.558 12.5962 11.5687C14.0342 11.4936 15.1611 10.3131 15.1718 8.85359C15.1718 7.36189 13.9591 6.13849 12.4567 6.13849Z"
      fill="currentColor"
    />
    <path
      d="M12.4572 23.073C9.57042 23.073 6.81239 21.9998 4.67679 20.0467C4.48362 19.875 4.39777 19.6174 4.41924 19.3706C4.55875 18.0935 5.35289 16.9023 6.67288 16.0223C9.8709 13.8974 15.0543 13.8974 18.2416 16.0223C19.5616 16.913 20.3557 18.0935 20.4952 19.3706C20.5274 19.6281 20.4308 19.875 20.2377 20.0467C18.1021 21.9998 15.344 23.073 12.4572 23.073ZM6.1041 19.1559C7.88555 20.6476 10.1285 21.4632 12.4572 21.4632C14.786 21.4632 17.0289 20.6476 18.8103 19.1559C18.6172 18.5013 18.1021 17.8682 17.3401 17.353C14.7001 15.593 10.225 15.593 7.5636 17.353C6.80166 17.8682 6.29727 18.5013 6.1041 19.1559Z"
      fill="currentColor"
    />
    <path
      d="M12.4574 23.073C6.09354 23.073 0.920898 17.9004 0.920898 11.5365C0.920898 5.17264 6.09354 0 12.4574 0C18.8213 0 23.9939 5.17264 23.9939 11.5365C23.9939 17.9004 18.8213 23.073 12.4574 23.073ZM12.4574 1.60974C6.98427 1.60974 2.53064 6.06337 2.53064 11.5365C2.53064 17.0096 6.98427 21.4633 12.4574 21.4633C17.9305 21.4633 22.3842 17.0096 22.3842 11.5365C22.3842 6.06337 17.9305 1.60974 12.4574 1.60974Z"
      fill="currentColor"
    />
  </svg>
</template>
