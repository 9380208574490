<script setup lang="ts">
import Checkbox from 'primevue/checkbox'
import { ref } from 'vue'

const props = defineProps<{
  stepName: string
  instructions: string[]
}>()

const checked = ref(props.instructions.map(() => false))
</script>

<template>
  <div>
    <h3 class="mb-2">{{ stepName }}</h3>
    <ol class="flex flex-col gap-2">
      <li v-for="(instruction, index) in instructions" :key="instruction" class="flex gap-2">
        <Checkbox
          :input-id="stepName.replace(' ', '-') + index"
          :binary="true"
          v-model="checked[index]"
          class="m-1"
        />
        <label class="flex" :for="stepName.replace(' ', '-') + index">
          <span class="min-w-8"> {{ index + 1 }}. </span>
          <span class="grow">
            {{ instruction }}
          </span>
        </label>
      </li>
    </ol>
  </div>
</template>
