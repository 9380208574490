<script setup lang="ts">
import { computed, ref, watchEffect, type Ref } from 'vue'
import { useAuth } from '@/composables/useAuth'
import { useI18n } from 'vue-i18n'
import type { MessageSchema } from '@/i18n/index.js'
import TabPanel from 'primevue/tabpanel'
import TabView from 'primevue/tabview'
import RestaurantTab from '@/components/Profile/MyProfile/RestaurantTab.vue'
import ProfileTab from '@/components/Profile/MyProfile/ProfileTab.vue'
import { useFetch } from '@/composables/useFetch.js'
import { useEnvStore } from '@/stores/envStore.js'
import type { AttributesList, Restaurant } from '@/types/restaurants.js'
import ToolingView from '@/components/Profile/MyProfile/ToolingView.vue'
import { useRoute, useRouter } from 'vue-router'
import LoadingView from '../LoadingView.vue'

const { t } = useI18n<{ message: MessageSchema }>({ useScope: 'global' })

const { fetchData } = useFetch()
const router = useRouter()
const route = useRoute()

const { user } = useAuth()
const restaurant: Ref<Restaurant | null> = ref(null)
const attributes: Ref<AttributesList | null> = ref(null)
const toolingViewDisplayed = computed(() => route.query.showTooling === 'true')

watchEffect(async () => {
  if (user.value?.restaurants[0].id && !restaurant.value) {
    const { data: dbRestaurant, error: dbRestaurantError } = await fetchData(
      useEnvStore().apiUrl + '/restaurants/' + user.value?.restaurants[0].id,
      'GET'
    )
    if (dbRestaurantError) console.log(dbRestaurantError)
    else restaurant.value = dbRestaurant

    const { data: dbAttributes, error: dbAttributesError } = await fetchData(
      useEnvStore().apiUrl + '/restaurants/attributes',
      'GET'
    )
    if (dbAttributesError) console.log(dbAttributesError)
    else attributes.value = dbAttributes
  }
})

const userInfos = computed(() => {
  if (user.value) {
    return {
      fullName: `${user.value.first_name} ${user.value.last_name}`,
      restaurantName: user.value.restaurants[0].name,
      restaurationType: user.value.restaurants[0].restauration_type,
    }
  }
  return {
    fullName: '',
    restaurantName: '',
    restaurationType: '',
  }
})
</script>

<template>
  <main>
    <div class="mb-6 text-center">
      <h1 class="mb-2">{{ userInfos.fullName }}</h1>
      <p class="font-titles">{{ userInfos.restaurantName }}</p>
      <p class="text-sm font-titles">{{ t(`restaurationType.${userInfos.restaurationType}`) }}</p>
    </div>
    <div>
      <LoadingView v-if="!user || !attributes || !restaurant" />
      <TabView
        :pt="{ inkbar: { class: 'hidden' } }"
        :pt-options="{ mergeProps: true }"
        v-else-if="!toolingViewDisplayed"
      >
        <TabPanel
          header="Restaurant"
          :pt="{
            header: { class: '!w-1/2' },
            headerAction: { class: '!justify-center' },
            content: { class: '!px-0' },
          }"
          :pt-options="{ mergeProps: true }"
        >
          <RestaurantTab
            v-if="restaurant && attributes && user"
            :restaurant
            :attributes
            @update-restaurant="
              (updatedRestaurant) => {
                if (user) {
                  restaurant = updatedRestaurant
                  user.restaurants[0].name = restaurant.name
                  user.restaurants[0].restauration_type =
                    restaurant.attributes.restaurationType[0].name
                }
              }
            "
            @go-to-tooling="
              () => {
                router.push({ name: 'my-profile', query: { showTooling: 'true' } })
                toolingViewDisplayed = true
              }
            "
          />
        </TabPanel>
        <TabPanel
          header="Profil"
          :pt="{
            header: { class: '!w-1/2' },
            headerAction: { class: '!justify-center' },
            content: { class: '!px-0' },
          }"
          :pt-options="{ mergeProps: true }"
        >
          <ProfileTab v-if="user" :user @update-user="user = $event" />
        </TabPanel>
      </TabView>
      <ToolingView
        v-else-if="toolingViewDisplayed && attributes && restaurant"
        :attributes
        :restaurant
        @update-restaurant="
          (updatedRestaurant) => {
            restaurant = updatedRestaurant
          }
        "
      />
    </div>
  </main>
</template>
