<script setup lang="ts">
import CreationFilter from '@/components/Menus/Filters/CreationFilter.vue'
import { useI18n } from 'vue-i18n'
import { type MessageSchema } from '@/i18n'
import SelectButton from 'primevue/selectbutton'
import { ref, watchEffect, type Ref } from 'vue'
import { useDishesStore } from '@/stores/dishesStore'
import SweetIcon from '@/assets/icons/MenuFilters/SweetIcon.vue'
import SaltIcon from '@/assets/icons/MenuFilters/SaltIcon.vue'
import { watch } from 'vue'
import SelectWithIcon from '@/components/BaseComponents/SelectWithIcon.vue'

const { t } = useI18n<{ message: MessageSchema }>({ useScope: 'global' })
interface SweetSaltOption {
  name: string;
  value: 'salt' | 'sweet' | '';
  icon?: any
}

const dishesStore = ref(useDishesStore());

const getDefaultSweetSalt = (): SweetSaltOption | null => {
  const value = dishesStore.value.generationParams.sweet_salt || '';
  // Ensure the value is one of the allowed types by casting it
  return value ? { name: t(`creationFilters.${value}`), value: value as 'salt' | 'sweet' | '' } : null;
}


const selectedSweetSalt: Ref<SweetSaltOption | null> = ref(getDefaultSweetSalt());
  
const options: SweetSaltOption[] = [
  { name: t('creationFilters.sweet'), value: 'sweet', icon: SweetIcon },
  { name: t('creationFilters.salt'), value: 'salt', icon: SaltIcon},
];


const toggleSelection = (option: SweetSaltOption) => {
  if (selectedSweetSalt.value?.value === option.value) {
    selectedSweetSalt.value = null;
    dishesStore.value.generationParams.sweet_salt = '';
  } else {
    selectedSweetSalt.value = option;
    dishesStore.value.generationParams.sweet_salt = option.value;
  }
};

watch(() => dishesStore.value.generationParams.sweet_salt, (newSweetSalt) => {
  if (newSweetSalt !== selectedSweetSalt.value?.value) {
    selectedSweetSalt.value = newSweetSalt ? {
      name: t(`creationFilters.${newSweetSalt}`),
      value: newSweetSalt as 'salt' | 'sweet' | ''
    } : null;
  }
}, { immediate: true });

// Keep the store updated with changes from the component
// watch(selectedSweetSalt, (newValue) => {
//   if (newValue && newValue.value !== dishesStore.value.generationParams.sweet_salt) {
//     dishesStore.value.generationParams.sweet_salt = newValue.value;
//   } else if (!newValue) {
//     dishesStore.value.generationParams.sweet_salt = '';
//   }
// });

</script>

<template>

  <CreationFilter>
    <template #title>{{ t('creationFilters.sweetSalt') }}</template>
    <template #content>
      <div class="grid grid-cols-2 gap-4">
        <div v-for="option in options" :key="option.value">
          <SelectWithIcon :selected="selectedSweetSalt?.value === option.value"
            @handle-select="() => toggleSelection(option)">
            <template #icon>
              <component :is="option.icon" />
            </template>
            <template #label>{{ option.name }}</template>
          </SelectWithIcon>
        </div>
      </div>
    </template>
  </CreationFilter>
</template>
