import { defineStore } from "pinia";
import { ref, computed, type Ref } from "vue";
import type { Ingredient } from "@/types/dish";
import { useEnvStore } from "./envStore";
import { useFetch } from "@/composables/useFetch";
import { useI18n } from "vue-i18n";
import type { MessageSchema } from "@/i18n";
import { useToast } from "primevue/usetoast";
import router from "@/router";
import { useCategories } from "@/composables/useCategories";
import { adjustUnitsForQuantity, convertUnit } from "@/utils/prices";



export const useOrderList = defineStore('orders', () => {
  const { t } = useI18n<{ message: MessageSchema }>({ useScope: 'global' })
  const toast = useToast()
  const { categories } = useCategories();
  const envStore = useEnvStore(); // Make sure this is reactive
  const { isFetching, fetchData } = useFetch()
  const isLoading = ref(false);
  const error: Ref<any> = ref(null);
  const ingredientsByCategory = ref<{ [key: string]: Ingredient[] }>({});

  function initializeCategories() {
    categories.value.forEach(cat => {
      ingredientsByCategory.value[cat.value] = [];
      // if (!ingredientsByCategory.value[cat.value]) {
      // }
    });
  }

  async function fetchIngredients(restaurantId) {
    console.log("Fetching ingredients")
    
    isLoading.value = true;
    error.value = null;
    
    try {
      const { data, error: fetchError } = await fetchData(
        `${useEnvStore().apiUrl}/restaurants/${restaurantId}/ingredients`, 'GET'
      );
      if (fetchError) {
        error.value = fetchError;
        toast.add({
          severity: 'error',
          summary: t('common.error'),
          detail: t('creationFilters.generationError'),
          life: 3000,
        });
      } else {
        initializeCategories();
        setIngredients(data);
        console.log("ingredients fetched, data : ", data)
      }
    } catch (err) {
      error.value = err;
      toast.add({
        severity: 'error',
        summary: t('common.error'),
        detail: t('creationFilters.generationError'),
        life: 3000,
      });
    } finally {
      isLoading.value = false;
    }
  }

  function transformIngredientData(ingredients: Ingredient[]): Ingredient[] {
    return ingredients.map(ingredient => {
      // Apply necessary transformations, e.g., unit conversion
      const quantity = ingredient.quantity ?? 0;
      const convertedQuantity = adjustUnitsForQuantity(quantity, ingredient.unit);

      ingredient.quantity = convertedQuantity.quantity;
      ingredient.unit = convertedQuantity.unit;
      return ingredient;
    });
  }
  // This function transforms and sets the ingredients into a categorized object
  function setIngredients(data) {
    console.log("setting ingredient : ", data)

    Object.keys(data).forEach(category => {
      ingredientsByCategory.value[category] = transformIngredientData(data[category]);
    });
    
    console.log("Ingredients by category", ingredientsByCategory.value)
  }

  // Adds new ingredients to the store and makes an API call to save them
  async function addIngredients(newIngredients, restaurantId, pathToRedirect = "") {
    if (!Object.keys(ingredientsByCategory.value).length) {
      initializeCategories();
    }
    isLoading.value = true;
    // Group new ingredients by category for local state management
    newIngredients.forEach(ingredient => {
      const category = ingredient.category;
      if (!ingredientsByCategory.value[category]) {
        ingredientsByCategory.value[category] = [];
      }
      const convertedUnitAndQuantity = adjustUnitsForQuantity(ingredient.quantity, ingredient.unit);
      ingredientsByCategory.value[category].push({...ingredient, quantity: convertedUnitAndQuantity.quantity, unit: convertedUnitAndQuantity.unit});
    });

    const payload = { ingredients: newIngredients };

    // Perform a single API call for all ingredients
    const { data, error } = await fetchData(
      `${envStore.apiUrl}/restaurants/${restaurantId}/ingredients`,
      'POST',
      payload
    );

    if (error) {
      // Rollback the local state if there's an error
      newIngredients.forEach(ingredient => {
        const category = ingredient.category;
        const index = ingredientsByCategory.value[category].lastIndexOf(ingredient);
        if (index !== -1) {
          ingredientsByCategory.value[category].splice(index, 1);
        }
      });
      toast.add({
        severity: 'error',
        summary: t('common.error'),
        detail: t('orders.addingIngredientsError'),
        life: 3000,
      });
    } else {
      // Optionally update local state with any new data from the server (like IDs)
      toast.add({
        severity: 'success',
        summary: t('common.success'),
        detail: t('orders.addingIngredientsSuccess'),
        life: 3000,
      });
      // initializeCategories()
      fetchIngredients(restaurantId)
      if (pathToRedirect) {
        console.log("Redirect user to the path")
        router.push({ path: pathToRedirect })
      }
    }
    isLoading.value = false;
  }
  async function updateIngredient(ingredient, restaurantId) {
    console.log("ingredient that will be updated ", ingredient);
    let originalCategory: string | null = null;
    let index = -1;

    // Find the ingredient and its original category
    for (const [category, ingredients] of Object.entries(ingredientsByCategory.value)) {
      index = ingredients.findIndex(ing => ing.id === ingredient.id);
      if (index !== -1) {
        originalCategory = category;
        break;
      }
    }


    if (index !== -1 && originalCategory !== null) {
      const originalIngredients = ingredientsByCategory.value[originalCategory];
      const originalIngredient = originalIngredients[index];
      const convertedUnitAndQuantity = adjustUnitsForQuantity(ingredient.quantity, ingredient.unit);
      ingredient.quantity = convertedUnitAndQuantity.quantity 
      ingredient.unit =  convertedUnitAndQuantity.unit ;
      console.log("ingredient.quantity and unit ", ingredient)
      originalIngredients[index] = { ...originalIngredient, ...ingredient };

      console.log("sending the ingredient to be updated", ingredient);
      const { data, error } = await fetchData(
        `${envStore.apiUrl}/restaurants/${restaurantId}/ingredients/${ingredient.id}`,
        'PUT',
        ingredient
      );

      if (error) {
        originalIngredients[index] = originalIngredient;
        toast.add({
          severity: 'error',
          summary: t('common.error'),
          detail: t('orders.updatingIngredientError'),
          life: 3000,
        });
      } else {
        if (ingredient.category !== originalCategory) {
          originalIngredients.splice(index, 1);
          if (!ingredientsByCategory.value[ingredient.category]) {
            ingredientsByCategory.value[ingredient.category] = [];
          }
          ingredientsByCategory.value[ingredient.category].push({ ...originalIngredient, ...ingredient });
        }
        toast.add({
          severity: 'success',
          summary: t('common.success'),
          detail: t('orders.updatingIngredientSuccess'),
          life: 3000,
        });
      }
    } else {
      toast.add({
        severity: 'error',
        summary: t('common.error'),
        detail: t('orders.ingredientNotFound'),
        life: 3000,
      });
    }
  }

  // Gets ingredients by category, useful for rendering specific sections
  function getIngredientsByCategory(category) {
    return computed(() => ingredientsByCategory.value[category] || []);
  }

  async function removeIngredient(category, ingredientId, restaurantId) {
    console.log("Attempting to delete ingredient", { category, ingredientId, restaurantId });

    // Find the ingredient and remove it optimistically
    const categoryIngredients = ingredientsByCategory.value[category] || [];
    const originalIngredientIndex = categoryIngredients.findIndex(ing => ing.id === ingredientId);
    let originalIngredient = <Ingredient>{};

    if (originalIngredientIndex !== -1) {
      // Store the ingredient in case we need to rollback
      originalIngredient = categoryIngredients[originalIngredientIndex];

      // Remove the ingredient optimistically
      ingredientsByCategory.value[category].splice(originalIngredientIndex, 1);

      // Send the deletion request to the backend
      try {
        const response = await fetchData(
          `${envStore.apiUrl}/restaurants/${restaurantId}/ingredients/${ingredientId}`,
          'DELETE'
        );

        if (response.error) {
          throw response.error;
        }

        // Show success toast
        toast.add({
          severity: 'success',
          summary: t('common.success'),
          detail: t('orders.deletingIngredientSuccess'),
          life: 3000,
        });
      } catch (error) {
        console.error("Failed to delete ingredient:", error);

        // Rollback: restore the original ingredient in the local state
        ingredientsByCategory.value[category].splice(originalIngredientIndex, 0, originalIngredient);

        // Show error toast
        toast.add({
          severity: 'error',
          summary: t('common.error'),
          detail: t('orders.deletingIngredientError'),
          life: 3000,
        });
      }
    } else {
      toast.add({
        severity: 'error',
        summary: t('common.error'),
        detail: t('orders.ingredientNotFound'),
        life: 3000,
      });
    }
  }

  async function removeIngredients(ingredientIds, restaurantId) {
    console.log("Attempting to delete ingredients", { ingredientIds, restaurantId });

    // Temporarily remove ingredients from local state
    const removedIngredients = {};
    for (const category in ingredientsByCategory.value) {
      removedIngredients[category] = ingredientsByCategory.value[category].filter(ingredient => {
        if (ingredientIds.includes(ingredient.id)) {
          return true; // Collect ingredient to potentially restore later
        }
        return false;
      });
      ingredientsByCategory.value[category] = ingredientsByCategory.value[category].filter(ingredient => !ingredientIds.includes(ingredient.id));
    }

    isLoading.value = true;
    try {
      const payload = { ingredientIds };
      const { data, error } = await fetchData(
        `${useEnvStore().apiUrl}/restaurants/${restaurantId}/ingredients`,
        'DELETE',
        payload
      );

      if (error) {
        // Rollback - Restore the removed ingredients if the API call fails
        console.error('Error deleting ingredients:', error);
        toast.add({
          severity: 'error',
          summary: t('common.error'),
          detail: t('orders.deletingIngredientsError'),
          life: 3000,
        });

        for (const category in removedIngredients) {
          ingredientsByCategory.value[category].push(...removedIngredients[category]);
        }
      } else {
        toast.add({
          severity: 'success',
          summary: t('common.success'),
          detail: t('orders.deletingIngredientsSuccess'),
          life: 3000,
        });
        // Optionally, refresh the local ingredients list to reflect changes
        fetchIngredients(restaurantId);
      }
    } catch (error) {
      console.error('Failed to delete ingredients:', error);
      toast.add({
        severity: 'error',
        summary: t('common.error'),
        detail: t('orders.deletingIngredientsError'),
        life: 3000,
      });
      // Rollback - Restore the removed ingredients
      for (const category in removedIngredients) {
        ingredientsByCategory.value[category].push(...removedIngredients[category]);
      }
    } finally {
      isLoading.value = false;
    }
  }

  async function removeDishIngredients(dishId, restaurantId) {
    isLoading.value = true;

    // Remove ingredients from local state
    for (const category in ingredientsByCategory.value) {
      ingredientsByCategory.value[category] = ingredientsByCategory.value[category].filter(ingredient => ingredient.dish_id !== dishId);
    }

    // Call API to remove ingredients for the specific dish from the server
    try {
      const { error } = await fetchData(
        `${useEnvStore().apiUrl}/dishes/${dishId}/shopping-list`,
        'DELETE'
      );

      if (error) {
        toast.add({
          severity: 'error',
          summary: t('common.error'),
          detail: t('orders.deletingIngredientsError'),
          life: 3000,
        });
        return { success: false };
        // Optionally, you could re-fetch ingredients or handle errors more specifically here
      } else {
        toast.add({
          severity: 'success',
          summary: t('common.success'),
          detail: t('orders.deletingIngredientsSuccess'),
          life: 3000,
        });
        // If needed, re-fetch ingredients to update the store with current server state
        fetchIngredients(restaurantId);
        return { success: true };
      }
    } catch (error) {
      toast.add({
        severity: 'error',
        summary: t('common.error'),
        detail: t('orders.deletingIngredientsError'),
        life: 3000,
      });
      console.error('Failed to delete ingredients by dishId:', error);
      return { success: false };
    } finally {
      isLoading.value = false;
    }
  }



  return { ingredientsByCategory, setIngredients, addIngredients, updateIngredient, getIngredientsByCategory, removeIngredient, removeIngredients, removeDishIngredients, fetchIngredients, isLoading, error };
});