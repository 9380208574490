import OrdersShoppingListView from '@/views/Orders/OrdersShoppingListView.vue'

const ordersRoutes = [
  {
    path: '/orders',
    name: 'orders',
    redirect: '/orders/shopping-list',
    children: [
      {
        path: 'shopping-list',
        meta: {
          headerConfig: {
            headerLabel: 'orders',
            hide: false,
            showBack: false,
            showProfile: true,
          },
          footerConfig: {
            hide: false,
          },
          securityConfig: {
            requireAuthentication: true,
            rolesAllowed: ['common', 'admin'],
          },
          containerMargins: true,
        },
        component: OrdersShoppingListView,
      },
    ],
  },
]

export default ordersRoutes
