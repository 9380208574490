<script setup lang="ts">
import type { MessageSchema } from '@/i18n'
import type { Ingredient } from '@/types/dish'
import { useI18n } from 'vue-i18n'
import { computed, ref, watch, watchEffect, type ModelRef } from 'vue'
import Checkbox from 'primevue/checkbox';
import { useOrderList } from '@/stores/orderStore';
import { useRouter } from 'vue-router';

const { t, te } = useI18n<{ message: MessageSchema }>({ useScope: 'global' })
const router = useRouter()
const selectedIngredients: ModelRef<Ingredient[]> = defineModel('selectedIngredients', { required: true })

const props = defineProps({
  ingredient: {
    type: Object as () => Ingredient,
    required: true
  },
  index: Number
});

const emit = defineEmits(['edit-ingredient', 'update-selection']);

// const ingredient = computed(() => {
//   // console.log(props.index)
//   return orderListStore.getIngredientsByCategory(props.category).value[props.index];
// });

function editIngredient() {
  console.log("edit Ingredient");
  emit("edit-ingredient", { category: props.ingredient.category, index: props.index });
}

function redirectToDishOrder(id) {
  console.log("redirect")
  router.push({ path: `/menu/dishes/${id}/add-to-orders` });
}


const selected = computed(() => selectedIngredients.value.some(i => i.id === props.ingredient.id));
</script>

<template>
  <div class="relative" v-if="ingredient">
    <div class="min-w-52">
      <div class="flex text-sm text-primary-950 border-b border-gray-100 items-center py-2">
        <div class="flex-1 min-w-0">
          <label :for="ingredient.name" class="flex min-w-0">
            <div class="flex-1 min-w-0"
              @click="!ingredient.dish_id ? editIngredient() : redirectToDishOrder(ingredient.dish_id)">
              <p class="truncate"> {{ ingredient.name }}</p>
              <p class="text-xs italic truncate text-slate-400" v-if="ingredient.dish_name || ingredient.comment"><i
                  class="pi pi-arrow-up-right"></i> {{
                    ingredient.dish_name || ingredient.comment || '' }}
              </p>
            </div>
            <div class="flex-none w-28 flex gap-1 items-center">
              <!-- <div class="grid grid-cols-[1fr,1fr] sm:grid-cols-2"> -->
              <span class="flex-none w-16 text-right" v-if="ingredient.quantity">
                {{ ingredient?.quantity > 0 ? ingredient.quantity : '' }}
              </span>
              <span class="w-7 truncate text-right">{{ te(`ingredients.unit.symbol.${ingredient.unit}`)
                ? t(`ingredients.unit.symbol.${ingredient.unit}`) : ingredient.unit }}</span>
              <!-- </div> -->
            </div>
          </label>
        </div>
        <!-- <div class="col-span-3 text-center">
          <InputNumber @click.stop v-model="productQuantity" inputId="horizontal-buttons" showButtons
            buttonLayout="vertical" :step="1" decrementButtonClass="p-button-danger"
            incrementButtonClass="p-button-success" incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus"
            :min="0" :max="100" style="width: 1rem" class="input-number-override w-10" />
        </div> -->
        <div class="flex-none w-6 flex justify-center items-center py-2">
          <!-- <Checkbox :binary="true" class="m-1" /> -->
          <div class="flex items-center">
            <Checkbox v-model="selected" :inputId="props.ingredient.id.toFixed()" name="ingredient"
              @click="emit('update-selection', { ingredient: props.ingredient, isChecked: !selected })" :binary="true"/>
          </div>
          <!-- <Checkbox :model-value="isIngredientSelected(ingredient)"
            @update:model-value="updatelistSelectedIngredients(ingredient, $event)" :binary="true" class="m-1" /> -->
        </div>
      </div>
    </div>
    <!-- <div class="absolute w-full h-8 -bottom-1 bg-gradient-to-t" :class="gradientFrom"></div> -->
  </div>
</template>

<style scoped>
.input-number-override.w-10 {
  width: 2.5rem !important;
  /* Tailwind's w-10 equivalent */
}
</style>